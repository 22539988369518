import React, { Suspense } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

import NotFound from '@screens/_404'
import AuthGuard from '@wrappers/auth-guard'
import Loader from '@shared/loader'

/**
 * Files placed in src/screens will automatically be added as routes
 * Files/Folders that begin with an underscore (_) will be ignored
 * By defualt, the path would be `/${filename}` or `/${subfolder}/${filename}`
 * To set a different pathname, export ROUTE_PATHS from the file.
 * To enable multiple paths pointing to the same component,
 * ROUTE_PATHS is always an array
 * e.g:
 *    export const ROUTE_PATHS = ['/foobar', '/hello']
 *
 *    export default class Component extends React.Component {
 *      ....
 *    }
 *
 * By default, route components will be wrapped by AuthGuard and use exact matching
 * To not wrap a component, export ROUTE_OPEN as a truthy value
 * To not use exact matching, export ROUTE_EXACT as a falsey value
 * e.g:
 *    export const ROUTE_OPEN = true
 *    export const ROUTE_EXACT = false
 *
 *    export default class Component extends React.Component {
 *      ....
 *    }
 *
 */

class AppRouter extends React.Component {
  state = {
    toggled: false,
  }

  shouldComponentUpdate = nextProps => {
    const curr = this.props.location.pathname
    const next = nextProps.location.pathname

    if (curr == '/login' && ['/dashboard', '/invoices'].includes(next)) {
      return false
    }

    return curr != next
  }

  togglePanel = () => this.setState({ toggled: !this.state.toggled })

  render = () => (
    <Suspense fallback={<Loader loading={true} />}>
      <Switch>
        <Route
          exact
          path='/'
          authenticated={!!global.RG.currentUser}
          render={() => {
            const roles = global.RG.currentUser?.roles

            if (roles) {
              return roles.find(r => ['revenue', 'treasury'].includes(r.name))
                ? <Redirect to='/invoices' />
                : <Redirect to='/dashboard' />
            }

            return <Redirect to='/login' />
          }}
        />

        {getScreenRoutes(true)}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  )
}

function getScreenRoutes () {
  const context = require.context('@screens', true, /\.js$/)
  
  return context.keys().reduce((routes, key) => {
    // Ignore files files/folders that start with an underscore
    if (/\/_/.test(key)) return routes

    const { default:def, ROUTE_PATHS, ROUTE_OPEN, ROUTE_EXACT } = context(key)
    const component = ROUTE_OPEN ? def : AuthGuard(def)

    if (ROUTE_PATHS) {
      const adding = ROUTE_PATHS.map(path => React.createElement(Route, {
        key: path,
        path,
        component,
        exact: ROUTE_EXACT ? ROUTE_EXACT : true
      }))
      
      return [...routes, ...adding]
    }
    
    const path = key
      .replace(/\.js$/, '')
      .replace(/^\.\//, '/')
      .replace('/index', '')
    
    routes.push(React.createElement(Route, {
      key: path,
      path,
      component,
      exact: ROUTE_EXACT ? ROUTE_EXACT : true
    }))

    return routes
  }, [])
}

export default withRouter(AppRouter)

import * as actionTypes from '@action-types';

const initial = {
  authenticated: false,
  loading: true,
  success: false,
  success_message: null,
  search_target: "", //Transaction or Invoice
  error: null,
  data: [],
};

export default (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_SEARCH_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        search_target: payload.search_target,
        data: payload.data,
      };
    case actionTypes.GET_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
        search_target: "",
        data: [],
      };
    default:
      return {
        ...state,
        loading: false,
        success_message: null,
        success: false,
      };
  }
};

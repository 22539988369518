import React, { Fragment } from 'react'
import { PaymentInputsWrapper } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'

import Field from '@components/fields/all'
import Heading from '@components/heading'

const Card = props => (
  <Fragment>
    <Heading>Credit Card Details</Heading>

    <Field
      name='card-name'
      label='Card Holder Name'
      type='text'
      onChange={ev => props.update(['card-name', ev.target.value])}
      value={props['card-name']}
    />

    <div className='form-group cc-input'>
      <label className='placeholder'>Card Info <span className='required-label'>*</span></label>
      <PaymentInputsWrapper {...props.wrapperProps}>
        <svg {...props.getCardImageProps({ images })} />
        <input {...props.getCardNumberProps({
          onChange: ev => props.update(['card-number', ev.target.value]),
          value: props['card-number']
        })} />
        <input {...props.getExpiryDateProps({
          onChange: ev => props.update(['card-exp', ev.target.value]),
          value: props['card-exp']
        })} />
        <input {...props.getCVCProps({
          onChange: ev => props.update(['card-cvv', ev.target.value]),
          value: props['card-cvv']
        })} />
      </PaymentInputsWrapper>
      <div className='accepted-cards'>
        <span className='text-xs text-gray-600 italic'>We Accept:</span>
        <svg width={'1.5em'} height={'1em'} viewBox={'0 0 24 16'}>
          {images.visa}
        </svg>
        <svg width={'1.5em'} height={'1em'} viewBox={'0 0 24 16'}>
          {images.mastercard}
        </svg>
      </div>
    </div>
  </Fragment>
)

export default Card

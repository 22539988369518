import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ValidatorForm } from 'react-form-validator-core'

import { saveInvoice, updateInvoice, getInvoice, getLocations } from '@actions/invoices'
import { getRevenueLineItems } from '@actions/lineitems'
import formatPhoneNumber from '@helpers/normalize-phone'
import Field from '@shared/form-fields'

import islands from '@constants/islands'

export const ROUTE_PATHS = ['/invoices/new', '/invoices/edit']

class InvoiceForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {},
      invoice: false,
      user: JSON.parse(sessionStorage.user),
      list_id: props.location.search.replace('?id=', '')
    }

    ValidatorForm.addValidationRule('matchLineItem', val => {
      return val == this.state.form.revenue_line_item_id
    })
  }

  componentDidMount () {
    const { state, props } = this

    props.getRevenueLineItems()
    props.getLocations()
    state.list_id && props.getInvoice(state.list_id)
  }

  componentWillReceiveProps ({ invoice }) {
    invoice && this.setState(state => {
      state.invoice = true
      Object.assign(state.form, invoice)

      return state
    })
  }

  on = {
    change: ({ target }) => this.setState(state => {
      state.form[target.name] = target.value
      return state
    }),

    file: ({ target }) => this.setState(state => {
      state.form[target.name] = target.files?.[0]
      return state
    }),

    select: name => ([selected]) => selected && this.setState(state => {
      state.form[name] = selected.value
      return state
    }),

    submit: ev => {
      ev.preventDefault()
      const { state, props } = this

      if (state.invoice) {
        props.updateInvoice(this, state.list_id, {
          ...state.form,
          mobile_number: formatPhoneNumber(state.form.mobile_number),
        })
      } else {
        props.saveInvoice(this, {
          ...state.form,
          mobile_number: formatPhoneNumber(state.form.mobile_number),
          transaction_mode: 'agency'
        })
      }
    }
  }

  useDefaultEmail = () => this.setState(state => {
    state.form.email = 'digipayusers@bahamas.gov.bs'
    return state
  })

  render () {
    const { state, props, on, useDefaultEmail } = this

    const { lineitems, places } = props
    const options = lineitems.map((item) => ({ value: item.id, label: item.long_desc }))
    const countryData = places.map((item) => ({ value: item.id, label: item.name }))

    return (
      <React.Fragment>
        <div className='content'>
          <div className='page-inner'>
            <div className='page-header'>
              <h4 className='page-title'>Form Invoice</h4>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <div className='card-title'>Invoice</div>
                  </div>
                  <ValidatorForm onSubmit={on.submit}>
                    <div className='card-body'>
                      <Field
                        type='text'
                        name='email'
                        onChange={on.change}
                        value={state.form.email}
                      >
                        <a role='button' onClick={useDefaultEmail}>Default Email</a>
                      </Field>

                      <Field
                        type='text'
                        name='first_name'
                        onChange={on.change}
                        value={state.form.first_name}
                      />

                      <Field
                        type='text'
                        name='last_name'
                        onChange={on.change}
                        value={state.form.last_name}
                      />

                      <Field
                        type='text'
                        name='mobile_number'
                        onChange={on.change}
                        value={state.form.mobile_number}
                        required={false}
                      >
                        <p>All phone numbers must start with 1242</p>
                      </Field>

                      <Field
                        type='text'
                        name='reference_code'
                        label='Invoice Number'
                        onChange={on.change}
                        value={state.form.reference_code}
                      />

                      <Field
                        name='revenue_line_item_id'
                        onChange={on.select('revenue_line_item_id')}
                        options={options}
                        value={state.form.revenue_line_item_id}
                      />

                      <Field
                        name='revenue_line_item_id_confirmation'
                        label='Confirm Line Item ID'
                        onChange={on.select('revenue_line_item_id_confirmation')}
                        options={options}
                        value={state.form.revenue_line_item_id_confirmation}
                        validators={['required', 'matchLineItem']}
                        errorMessages={[
                          'This field is required',
                          'Please make sure both selected line items are the same.'
                        ]}
                      />

                      <Field
                        name='location'
                        label='Island'
                        options={islands}
                        onChange={on.select('location')}
                        value={state.form.location}
                      />

                      <Field
                        name='place_id'
                        label='Location'
                        onChange={on.select('place_id')}
                        options={countryData}
                        value={state.form.place_id}
                        required={false}
                      />
                      <Field
                        type='text'
                        name='amount'
                        inputMode='decimal'
                        onChange={on.change}
                        value={state.form.amount}
                        validators={['required', 'isFloat']}
                        errorMessages={['This field is required', 'This field should be numeric']}
                      />

                      <Field name='notes' label='Additional Notes' required={false}>
                        <textarea
                          name='notes'
                          onChange={on.change}
                          maxLength='250'
                          value={state.form.notes}
                          className='form-control'
                          placeholder='Additional Notes'
                        />
                      </Field>

                    </div>
                    <div className='card-action'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <input className='btn btn-success mr-1' type='submit' value='Submit' />
                          <button className='btn btn-danger' onClick={() => this.props.history.push('/invoices')}>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>

    )
  }
}


function mapStateToProps(state) {
  return {
    invoice: state.invoices.invoice,
    lineitems: state.lineitems.revenue_line_items || [],
    places: state.invoices.places || []
  }
}


export default withRouter(connect(mapStateToProps, {
  saveInvoice, updateInvoice, getInvoice, getRevenueLineItems, getLocations
})(InvoiceForm))

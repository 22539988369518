import * as T from '../types'

const initial = {
  data: [],
  error: null,
  loading: false,
  success: false,
  success_message: null,
}

const lineItems = (state = initial, { type, payload }) => {
  const next = {
    ...state,
    loading: false,
    error: null
  }

  switch (type) {
    case T.GET_REQUEST_SUCCESS:
      return { ...next, loading: true }
    case T.GET_RESPONSE_ERROR:
      return { ...next, error: payload.response.data.response }
    case T.GET_LINE_ITEMS:
      return { ...next, line_items: payload }
    case T.GET_REVENUE_LINE_ITEMS:
      return { ...next, revenue_line_items: payload }
    case T.CREATE_LINE_ITEM:
      return {
        ...next,
        success: true,
        head: payload,
        success_message: "successfully payment",
      }
    case T.CREATE_REVENUE_LINE_ITEM:
      return {
        ...next,
        success: true,
        head: payload,
        success_message: "successfully payment",
      }
    default:
      return { ...next }
  }
}

export default lineItems


import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { ValidatorForm } from 'react-form-validator-core'
import { withRouter } from 'react-router-dom'
import CCInput from 'react-credit-card-input'

import { saveTransaction, createError } from '@actions/transactions'
import appConstants from '@constants/constants'

import formatPhoneNumber from '@helpers/normalize-phone'
import Loader from '@shared/loader'
import InputField from '@shared/form-fields/input'

const Field = ({ name, label, required, children }) => (
  <div className='form-group form-show-validation row'>
    <label
      htmlFor={name}
      className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'
    >
      {label}
      {required !== false ? <span className="required-label">*</span> : null}
    </label>
    <div className='col-lg-4 col-md-9 col-sm-8'>
      {children}
    </div>
  </div>
)

const emptyForm = () => ({
  form: {
    'invoice_number': '',
    'first_name': '',
    'last_name': '',
    'email': '',
    'card-name': '',
  },

  card: {
    values: {
      'card-amount': '',
      'card-number': '',
      'card-cvv': '',
      'card-exp': '',
    },

    valid: {
      'card-number': false,
      'card-cvv': false,
      'card-exp': false,
    },

    error: null,
  },

  invoice: {
    long_desc: '',
  }
})

class CreditCardPayment extends React.Component {
  state = {
    ...emptyForm(),
    nonce: 0
  }

  user = sessionStorage.user && JSON.parse(sessionStorage.user)
  BASE_URL = process.env.REACT_APP_API_HOST + '/' + appConstants.API_V

  componentWillReceiveProps ({ success_message }) {
    success_message && this.resetForm()
  }

  resetForm = () => {
    this.setState({
      ...emptyForm(),
      nonce: this.state.nonce + 1
    })
  }

  getPropName = name => {
    switch (name) {
      case 'cardNumber':
        return 'card-number'
      case 'cardCVC':
        return 'card-cvv'
      case 'cardExpiry':
        return 'card-exp'
      default:
    }
  }

  fetch = {
    token: async () => {
      try {
        const { data } = await axios.get(this.BASE_URL + '/fetch_guest_token')
        return data.token
      } catch (err) {
        console.error(err)
      }
    },

    invoice: async ({ target }) => {
      const Authorization = await this.fetch.token()

      try {
        const { data } = await axios.get(
          `${this.BASE_URL}/revenue_line_items/by_invoice_number/${target.value}`,
          { headers: { Authorization } }
        )

        const invoice = data.revenue_line_item

        invoice && this.setState(state => {
          state.card.values['card-amount'] = data.invoice_amount.toFixed(2)
          return Object.assign(state, { invoice })
        })
      } catch (err) {
        console.error(err)
      }
    },
  }

  on = {
    change: ({ target }) => {
      this.setState(state => {
        state.form[target.name] = target.value
        return state
      })
    },

    cc: name => ev => {
      const val = ev.target.value

      this.setState(state => {
        state.card.values[name] = val
        state.card.valid[name] = !!val

        return state
      })
    },

    error: (err) => {
      this.setState(state => {
        state.card.valid[this.getPropName(err.inputName)] = false
        state.card.error = err

        return state
      })
    },

    submit: async (ev) => {

      const { state, props } = this
      const { form, card, invoice } = state
      ev.preventDefault()
      if (form.mobile_number)
        form.mobile_number = formatPhoneNumber(JSON.stringify(form.mobile_number))
      if (props.loading) return

      if (Object.values(card.values).every(v => !v)) {
        props.createError({
          response: {
            data: {
              response: 'Please Enter Card Information'
            }
          }
        })
        return
      }

      if (Object.values(card.valid).some(v => !v)) return
      const data = {
        ...form,
        ...card.values,
        'transaction_mode': "agency",
        'third_party_type': "credit",
        'amount': parseFloat(card.values['card-amount']),
        'revenue_line_item_id': invoice.id,
      }

      props.saveTransaction(this, data, false)
    },
  }

  render () {
    const { state, props, on, fetch } = this
    const { form, invoice, card, nonce } = state

    const useDefaultEmail = () => this.setState(state => {
      state.form.email = 'digipayusers@bahamas.gov.bs'
      return state
    })

    return (
      <React.Fragment >
        <Loader loading={props.loading} />
        <div className="content" key={nonce}>
          <div className="page-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Debit/Credit Card</div>
                  </div>
                  <ValidatorForm method="post" onSubmit={on.submit} >
                    <div className="card-body">
                      <div>Invoice Details</div>
                      <hr />

                      <Field name='invoice_number' label='Invoice Number'>
                        <InputField
                          onChange={on.change}
                          onBlur={fetch.invoice}
                          name="invoice_number"
                          value={form.invoice_number}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          className="form-control"
                          placeholder="Invoice Number"
                        />
                      </Field>

                      <Field name='revenue_line_item' label='Revenue Line Item'>
                        <InputField readOnly value={invoice.long_desc} className="form-control" />
                      </Field>

                      <Field name='card_amount' label='Amount'>
                        <InputField readOnly value={card.values['card-amount']} className="form-control" />
                      </Field>

                      <div>Customer Info</div>
                      <hr />

                      <Field name='first_name' label='First Name'>
                        <InputField
                          onChange={on.change}
                          name="first_name"
                          value={form.first_name}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          className="form-control"
                          placeholder="First Name"
                        />
                      </Field>

                      <Field name='last_name' label='Last Name'>
                        <InputField
                          onChange={on.change}
                          name="last_name"
                          value={form.last_name}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          className="form-control"
                          placeholder="Last Name"
                        />
                      </Field>

                      <Field name='email' label='Email'>
                        <InputField
                          onChange={on.change}
                          name="email"
                          value={form.email}
                          validators={['required', 'isEmail']}
                          errorMessages={['This field is required', 'Must be a valid email address']}
                          className="form-control"
                          placeholder="Email"
                        />
                        <a role="button" onClick={useDefaultEmail}>Default Email</a>
                      </Field>

                      <Field name='sms' required={false} label='Mobile Number'>
                        <InputField
                          onChange={on.change}
                          name="mobile_number"
                          value={form.mobile_number}
                          className="form-control"
                          placeholder="Mobile Number"
                          type="number"
                        />
                        <p>All phone numbers must start with 1242</p>
                      </Field>

                      <Field name='sms' required={false} label='Additional Notes'>
                        <textarea
                          onChange={on.change}
                          name="notes"
                          maxLength="250"
                          value={form.notes}
                          className="form-control"
                          placeholder="Additional Notes"
                          type="text"
                        />
                      </Field>

                      <div>Card Info</div>
                      <hr />

                      <Field name='card-name' label='Cardholder Name'>
                        <InputField
                          onChange={on.change}
                          name="card-name"
                          value={form['card-name']}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          className="form-control"
                          placeholder="Cardholder Name"
                          autoComplete='off'
                        />
                      </Field>

                      <Field name='cc_info' label='Debit/Credit Card'>
                        <CCInput
                          fieldClassName='input'
                          cardNumberInputProps={{ value: card.values['card-number'], onChange: on.cc('card-number'), autoComplete: 'off' }}
                          cardExpiryInputProps={{ value: card.values['card-exp'], onChange: on.cc('card-exp'), autoComplete: 'off' }}
                          cardCVCInputProps={{ value: card.values['card-cvv'], onChange: on.cc('card-cvv'), autoComplete: 'off' }}
                          onError={on.error}
                        />
                      </Field>
                    </div>

                    <div className="card-action">
                      <div className="row">
                        <div className="col-md-12">
                          <input className="btn btn-success mr-1" type="submit" value="Submit" disabled={props.loading} />
                          {/*<a className="btn btn-danger" onClick={()=> this.props.history.push('/inventory')} href="javascript:void(0)">Cancel</a>*/}
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(
  connect(({ transactions }) => ({
    loading: transactions.loading,
    transaction: transactions.transaction,
    success_message: transactions.success_message,
    error: transactions.error
  }), {
    saveTransaction,
    createError,
  })(CreditCardPayment)
)

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from 'lodash'
import { saveCompany, updateCompany, getCompany } from "@actions/companies";
import { preValue } from "@helpers/tools";
import InputField from "@shared/form-fields/input";
import { ValidatorForm } from "react-form-validator-core";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";

export const ROUTE_PATHS = ['/ministries/new', '/ministries/edit']

let invobj = {};
class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      company: null,
      user: JSON.parse(sessionStorage.user),
      error: null,
      list_id: this.props.location.search.replace("?id=", ""),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.selectHandle = this.selectHandle.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    if (this.state.list_id) this.props.getCompany(this.state.list_id);
    //
  }
  handleChange (event) {
    invobj[event.target.name] = event.target.value;
    this.setState({ formData: invobj });
  }

  selectHandle (name, data) {
    if (!_.isEmpty(data)) {
      invobj[name] = data[0].value;
      this.setState({ formData: invobj });
    }
  }

  componentWillReceiveProps ({ company }) {
    if (!_.isEmpty(company)) {
      this.setState({ company })
    }
  }

  handleFileChange (event) {
    let type = event.target.files[0].type;
    if (event.target.files[0] && type) {
      if (type.includes("image/png") || type.includes("image/jpeg") || type.includes("image/jpg")) {
        invobj[event.target.name] = event.target.files[0];
        this.setState({ formData: invobj });
      } else {
        ToastsStore.error("Following File Type Is Not Allowed !");
      }
    }
  }

  handleSubmit = ev => {
    ev.preventDefault()

    const { state, props } = this

    const form = new FormData()
    form.append('user_id', state.user.id)

    for (let [key, val] of Object.entries(state.formData)) {
      const prop = key == 'logo' ? 'append' : 'set'
      form[prop](key, val)
    }

    if (state.company) {
      props.updateCompany(this, state.list_id, form)
    } else {
      props.saveCompany(this, form)
    }
  }

  validateForm () {}

  render () {
    const {
      name_of_company,
      street,
      country,
      address,
      po_box,
      city,
      island,
      phone_number,
      email_address,
      first_name,
      last_name,
      website,
    } = this.state.company;

    return (
      <React.Fragment>
        <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore} />
        <div className="content">
          <div className="page-inner">
            <div className="page-header">
              <h4 className="page-title">Form Ministries</h4>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Ministries</div>
                  </div>
                  <ValidatorForm ref="form" method="post" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="card-body">
                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          Name of company <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="name_of_company"
                            value={preValue(this.state.formData.name_of_company, name_of_company)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="name_of_company"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          Address <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="address"
                            value={preValue(this.state.formData.address, address)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="address"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          Street <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="street"
                            value={preValue(this.state.formData.street, street)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="street"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          City <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="city"
                            value={preValue(this.state.formData.city, city)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="city"
                          />
                        </div>
                      </div>
                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          Po box <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="po_box"
                            value={preValue(this.state.formData.po_box, po_box)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="po_box"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          Country <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="country"
                            value={preValue(this.state.formData.country, country)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="country"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          Island <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="island"
                            value={preValue(this.state.formData.island, island)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="island"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          Phone Number <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="phone_number"
                            value={preValue(this.state.formData.phone_number, phone_number)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="phone_number"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          Email Address <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="email_address"
                            value={preValue(this.state.formData.email_address, email_address)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="email_address"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          First Name <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="first_name"
                            value={preValue(this.state.formData.first_name, first_name)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="first_name"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          Last Name <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="last_name"
                            value={preValue(this.state.formData.last_name, last_name)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="last_name"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          Logo
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            type="file"
                            onChange={this.handleFileChange}
                            name="avatar"
                            className="form-control"
                            placeholder="avatar"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                          Website <span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="website"
                            value={preValue(this.state.formData.website, website)}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                            placeholder="website"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-action">
                      <div className="row">
                        <div className="col-md-12">
                          <input className="btn btn-success mr-1" type="submit" value="Submit" />
                          <button className="btn btn-danger" onClick={() => this.props.history.push("/companies")}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.transactions.error,
    company: state.companies.company,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    saveCompany,
    updateCompany,
    getCompany,
  })(CompanyForm)
);

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Loader from '@shared/loader';
import appConstants from '@constants/constants'
import { saveTransaction } from '@actions/transactions'
import CreditCardInput from 'react-credit-card-input';
import extractKeys from '@helpers/extract-keys';
var creditCardType = require('credit-card-type');

let obj = {}

class Payment extends React.Component {
  state = {
    isValid: {
      cardNumber: false,
      cardExpiry: false,
      cardCVC: false
    },
    formData: {},
  }

  handleCardNumberChange = (event) => {
    let type = creditCardType(event.target.value)
    obj['card-number'] = event.target.value;
    obj['card-name'] = type[0].type;
    let isValid = Object.assign({}, this.state.isValid)
    isValid["cardNumber"] = true
    this.setState({ formData: obj, isValid: isValid });

  }
  handleCardExpiryChange = (event) => {
    obj['card-expiry'] = event.target.value;
    let isValid = Object.assign({}, this.state.isValid)
    isValid["cardExpiry"] = true
    this.setState({ formData: obj, isValid: isValid });
  }
  handleCardCVCChange = (event) => {
    obj['card-cvv'] = event.target.value;
    let isValid = Object.assign({}, this.state.isValid)
    isValid["cardCVC"] = true
    this.setState({ formData: obj, isValid: isValid });

  }
  handleError = (e) => {
    let isValid = Object.assign({}, this.state.isValid)
    isValid[e.inputName] = false
    this.setState({ error: e.error, isValid: isValid })
  }
  inputChangeHandler = (event) => {
    obj[event.target.name] = event.target.value;
    this.setState({ formData: obj });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (!Object.values(this.state.isValid).includes(false)) {
      this.props.saveTransaction(this, {
        ...this.state.formData,
        // 'company_id': 1,
        'publisher-name': 'pnpdemo',
        'api_key': appConstants.api_key,
      })
    }
  }

  render () {
    if (this.state.loading) return (
      <Loader loading={true} />
    )

    return (
      <div className="login">
        <div className="wrapper wrapper-login ">
          <div className="container container-login animated fadeIn">
            <h3 className="text-center">Payment</h3>
            <div className="login-form">
              <form onSubmit={(event) => this.handleSubmit(event)} method="post">
                <div className="form-group form-floating-label">
                  <input id="card-amount" name="card-amount" onChange={(event) => this.inputChangeHandler(event)} type="number" className="form-control input-border-bottom" required />
                  <label htmlFor="card-amount" className="placeholder">Amount</label>
                </div>
                <CreditCardInput
                  cardNumberInputProps={{ value: null, onChange: this.handleCardNumberChange.bind(this) }}
                  cardExpiryInputProps={{ value: null, onChange: this.handleCardExpiryChange.bind(this) }}
                  cardCVCInputProps={{ value: null, onChange: this.handleCardCVCChange.bind(this) }}
                  onError={(e) => this.handleError(e)}
                  fieldClassName="input"
                />
                <div className="form-action mb-3">
                  <input type="submit" className="btn btn-primary btn-rounded btn-login" value="Pay" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(
  state => extractKeys(state.transactions, 'loading', 'transaction'),
  { saveTransaction }
)(Payment))

import * as T from '@action-types'

const initial = {
  data: [],
  loading: false,
  success: false,
  error: null,
  authenticated: false,
  success_message: null,
}

const companies = (state = initial, { type, payload }) => {
  const next = { ...state, error: null, loading: false }

  switch (type) {
    case T.GET_COMPANIES:
      return {
        ...next,
        companies: payload
      }
    case T.CREATE_COMPANY:
      return {
        ...next,
        success: true,
        company: payload,
        success_message: "successfully create company",
      }
    case T.GET_PARTICULAR_COMPANY:
      return {
        ...next,
        company: payload.company
      }
    case T.UPDATE_COMPANY:
      return {
        ...next,
        success: true,
        company: payload,
        success_message: "successfully update company",
      }
    case T.REMOVE_COMPANY:
      return {
        ...next,
        success: true,
        success_message: "successfully removed",
        companies: payload.component.state.data
          .filter((item) => item.id != payload.company.id)
      }
    default:
      return {
        ...next,
        success: false,
        success_message: null,
      }
  }
}

export default companies

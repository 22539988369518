import * as actionTypes from '@action-types'

const initialState = {
  authenticated: false,
  loading: false,
  error: undefined,
  data: [],
  
}

const dashboard = (state=initialState,action) => {

  switch(action.type){
    case actionTypes.GET_REQUEST_SUCCESS: 
      return {...state,loading: true, error: undefined};
    case actionTypes.GET_DB_DATA:
      return{...state,loading: false,data: action.payload.response.dbData, dTransactions: action.payload.response.transactions }
    case actionTypes.GET_RESPONSE_ERROR:
      return{...state, loading: false, error: action.payload.error} 
    default: 
      return {...state, loading: false} 
  }  
}

export default dashboard


import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import EmptyTable from '@shared/empty-table'
import Loader from '@shared/loader'
import { getUsers } from '@actions/users'
import appConstants from '@constants/constants'
import UsersTable from './_table'

class Users extends React.Component {
  constructor (props) {
    super(props)

    const today = new Date()

    this.state = {
      isOpen: false,
      current_list: {},
      current_transaction: {},
      date_start: today,
      date_end: today,
      nonce: 0,
    }
  }

  componentDidMount () {
    this.props.getUsers()
  }

  componentWillReceiveProps ({ success }) {
    success && this.setState({ isOpen: false })
  }

  on = {
    date: name => d => {
      this.setState({ [name]: this.formatDate(d) }, () => {
        this.props.getUsers(this.state.date_start, this.state.date_end)
      })
    },
  }

  formatDate = date => typeof date == 'string'
    ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, "$1-$2-$3"))
    : date

  handleAction = (id, action) => {
    if (action == 'edit') {
      const current_list = this.state.lists.find(l => l.id === id)
      this.setState({ isOpen: true, current_list: current_list })
    }
  }

  reqcloseModal = () => {
    this.setState({ isOpen: !this.state.isOpen, current_list: {} })
  }


  showTransaction = current_transaction => {
    this.setState({ current_transaction })
  }

  render () {
    const { state, props, showTransaction } = this
    const { loading, data } = props

    console.log("here is the users data", data)

    const table = !loading && data && data.length ? (
      <UsersTable
        {...state}
        data={data}
        showTransaction={showTransaction}
      />
    ) : <EmptyTable columns={appConstants.USERS_COLUMNS} />

    return (
      <React.Fragment>
        <Loader loading={this.props.loading} />

        <div className="content">
          <div className="page-inner">
            <div className="page-header"></div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="card-title">Users</h4>
                      <a className="btn btn-primary btn-round" href="/users/new">
                        <i className="fa fa-plus"></i> Create User
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      {table}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ users }) => ({
  data: users.userData,
  error: users.error,
  success: users.success,
  loading: users.loading,
  success_message: users.success_message,
})

export default withRouter(
  connect(
    mapStateToProps,
    { getUsers }
  )(Users)
)

import React from 'react'
import { connect } from 'react-redux'
import { history } from '@router'

export default function AuthGuard (Component) {
  class Authentication extends React.Component {
    // if authenticated == false redirect to '/signin'
    componentWillMount() {
      if (!sessionStorage.getItem('user')) {
        history.push('/login')
      } else {
        this.unauthorizedView(this.props)
      }
    }

    unauthorizedView = props => {
      if (props.location.pathname === '/login') {
        props.history.goBack()
      }
    }

    render = () => <Component {...this.props} />
  }

  return connect(state => ({
    authenticated: state.dashboard.authenticated
  }))(Authentication)
}

import React from "react";
import Swal from 'sweetalert2'
import $ from "jquery";
import useCSVDownloader from "use-csv-downloader";
import appConstants from "@constants/constants";
import { dateTimeOnly } from "@helpers/tools";

import "react-confirm-alert/src/react-confirm-alert.css";
import "../../assets/css/pagination.css";

$.DataTable = require("datatables.net");
const downloadCSV = useCSVDownloader({});

export default class TransactionTable extends React.Component {
  constructor(props) {
    super(props);
    this.table = React.createRef();
    this.search = null;

    this.userRoles = JSON.parse(sessionStorage.getItem("user")).roles.map(
      (r) => r.name
    );
  }

  componentDidMount() {
    this.datatable.init();
  }

  componentWillUnmount() {
    this.datatable.destroy();
  }

  datatable = {
    init: () => {
      try {
        this.__datatable = $(this.table.current).DataTable({
          order: [[7, "desc"]],
          language: {
            paginate: {
              next: '<span className="p-next">next</span>',
              previous: '<span className="p-previous">previous</span>',
            },
          },
        });

        setTimeout(() => {
          this.search = document.querySelector(
            'input[aria-controls="basic-datatables"]'
          );
        }, 500);
      } catch (err) {
        console.error(err);
      }
    },

    destroy: () => {
      this.__datatable && this.__datatable.destroy();
    },

    reset: () => {
      this.datatable.destroy();
      this.datatable.init();
    },
  };

  downloadData(url) {
    this.props.downloadTransactions(url);
  }

  confirmVoid(e, item) {
    e.preventDefault();
    Swal.fire({
      title: "Confirm",
      text: "Are you sure you'd like to void this transaction?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Void",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        this.props.voidTransaction(item.id);
      }
    });
  }

  actionItems = item => (
    <div>
      <a
        onClick={() => this.props.showTransaction(item)}
        data-action
        data-toggle="modal"
        data-target="#TransactionModal"
      >
        <i className="fa fa-eye"></i>
      </a>
      {
        !item.void && ['revenue', 'agency_accountant'].some(r => this.userRoles.includes(r)) && !this.userRoles.includes("read") ? (
          <React.Fragment>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a data-action onClick={(e) => this.confirmVoid(e, item)}>
              <i className="fa fa-ban"></i>
            </a>
          </React.Fragment>
        ) : null
      }
    </div>
  )

  convertDate = date => new Date(date).toLocaleString()

  generateCSV = () => {
    const { data } = this.props;
    const { search } = this;
    let { TRANSACTIONS_HEADERS } = appConstants;

    let rows = null;
    if (search.value) {
      rows = this.__datatable.context[0].aiDisplay.map((i) => data[i]);
      // const master = this.__datatable.data().map(d => data.find(r => r.reference_id == d[0]))
      // rows = this.__datatable.context[0].aiDisplay.map(i => master[i])
    }

    const csv = (rows || data.reverse())
      .map((row) => ({
        ...row,
        created_at: dateTimeOnly(row.created_at),
      }))
      .map((row) =>
        TRANSACTIONS_HEADERS.reduce(
          (o, { label, key }) => ({
            ...o,
            [label]: key == "void" ? (row[key] ? "YES" : "NO") : row[key],
          }),
          {}
        )
      );
    // return console.log(csv);

    downloadCSV(csv, `transactions-${Date.now()}.csv`);
  };

  checkmark = val => {
    const classes = !!val
      ? 'fas fa-check text-green-600'
      : 'fas fa-times text-red-600'

    return <div className='text-center'><i className={classes} /></div>
  }

  tableRows = data => data.map((t, i) => (
    <tr key={i}>
      <td>{t.invoice_reference}</td>
      <td>{t.invoice_refernce}</td>
      <td>{t.m_status}</td>
      <td>$ {t.amount_charged}</td>
      <td>{t.line_item_description}</td>
      <td>{t.invoice_email}</td>
      <td>{t.transaction_first_name}</td>
      <td>{t.transaction_last_name}</td>
      <td>{t.cashier}</td>
      <td>{t.invoice_location}</td>
      <td>{this.convertDate(t.created_at)}</td>
      <td>{t.transaction_mode}</td>
      <td>{t.third_party_type}</td>
      <td>{t.third_party_reference}</td>
      <td>{t.void && this.checkmark(t.void)}</td>
      <td>{t.mobile_number}</td>
      <td>{t.notes}</td>
      <td>
        <button
          type="button"
          onClick={(e) => {
            this.downloadData(t.receipt);
          }}
          className="btn btn-link"
          data-original-title="Remove"
          data-action
        >
          <i className="fa fa-download"></i>
        </button>
      </td>
      <td>{this.actionItems(t)}</td>
    </tr>
  ))

  render () {
    const TRANSACTIONS_COLUMNS = [...appConstants.TRANSACTIONS_COLUMNS];
    const headings = TRANSACTIONS_COLUMNS.map((h, i) => (
      <th key={i}>{h.title}</th>
    ));

    return (
      <React.Fragment>
        <button
          className="btn btn-sm btn-primary"
          onClick={this.generateCSV}
          style={{ marginBottom: "1.5rem" }}
        >
          <i className='fas fa-download' />
          &nbsp;&nbsp;&nbsp;&nbsp;Download CSV
        </button>

        <table
          id="basic-datatables"
          className="display table table-striped table-hover"
          ref={this.table}
        >
          <thead>
            <tr>{headings}</tr>
          </thead>
          <tfoot>
            <tr>{headings}</tr>
          </tfoot>
          <tbody>{this.tableRows(this.props.data)}</tbody>
        </table>
      </React.Fragment>
    );
  }
}

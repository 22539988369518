import axios from 'axios'

import * as T from '@action-types'

const getAuthHeader = () => {
  const user = JSON.parse(sessionStorage.getItem("user") || '{}')

  return {
    'Content-Type'  : 'application/json',
    'Authorization' : user.auth_token
  }
}

const createError = (dispatch, payload) => dispatch({
  type: T.GET_RESPONSE_ERROR,
  payload
})

const getRequest = {
  type: T.GET_REQUEST_SUCCESS,
  payload: {}
}

export const getLineItems = () => async dispatch => {
  const headers = getAuthHeader()
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/line_items`

  try {
    const { data } = await axios.get(url, { headers })

    dispatch({
      type: T.GET_LINE_ITEMS,
      payload: data.line_items
    })
  } catch (err) {
    createError(dispatch, err)
  }
}

export const getRevenueLineItems = () => async dispatch => {
  const headers = getAuthHeader()
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/revenue_line_items`

  try {
    const { data } = await axios.get(url, { headers })

    dispatch({
      type: T.GET_REVENUE_LINE_ITEMS,
      payload: data
    })
  } catch (err) {
    createError(dispatch, err)
  }
}

export const getLineItem = id => async dispatch => {
  const headers = getAuthHeader()
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/line_items/${id}`

  try {
    const { data } = await axios.get(url, { headers })

    dispatch({
      type: T.GET_PARTICULAR_LINE_ITEM,
      payload: data
    })
  } catch (err) {
    createError(dispatch, err)
  }
}

export const saveLineItem = (component, data) => async dispatch => {
  const headers = getAuthHeader()
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/line_items`

  try {
    const { data: lineItem } = await axios.post(url, data, { headers })
    component.props.history.push('/line-items')

    dispatch({
      type: T.CREATE_TRANSATION,
      payload: lineItem
    })
  } catch (err) {
    createError(dispatch, err)
  }
}

export const saveRevenueLineItem = (history, data) => async dispatch => {
  const headers = getAuthHeader()
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/revenue_line_items`

  try {
    const { data: item } = await axios.post(url, data, { headers })
    history.push('/revenue-line-items')

    dispatch({
      type: T.CREATE_REVENUE_LINE_ITEM,
      payload: item
    })
  } catch (err) {
    createError(dispatch, err)
  }
}

export const updateLineItem = (component, id, data) => async dispatch => {
  const headers = getAuthHeader()
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/line_items/${id}`

  try {
    const { data: lineItem } = await axios.patch(url, data, { headers })
    component.props.history.push('/line-items')

    dispatch({
      type: T.UPDATE_TRANSATION,
      payload: lineItem
    })
  } catch (err) {
    createError(dispatch, err)
  }
}

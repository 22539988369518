import React from 'react';
import {Link} from 'react-router-dom'
import appConstants from '@constants/constants'
import $ from 'jquery'
import moment from 'moment'
import '../../assets/css/pagination.css'
$.DataTable = require('datatables.net')

class ReportTable extends React.Component{
  constructor(props){
    super(props);
    this.state={
    	data: []
    };
    this.addActionData = this.addActionData.bind(this);
    this.viewData = this.viewData.bind(this)
    this.removeData = this.removeData.bind(this)
  }
  componentDidMount(){
    this.$el = $(this.el)
		this.$el.DataTable({language: {
        paginate: {
            next: '<span className="p-next">next</span>',
            previous: '<span className="p-previous">previous</span>'
        }
	}});
  }

  removeData(id){
  	this.props.removeFarmerList(this, id)
  }

  	downloadData(url){
		this.props.downloadReports(url)
	}

  addActionData = (item) => (
		<div>
			<Link to={`inventory/edit?id=${item.id}`} className="btn btn-link btn-primary btn-lg" >
				<i className="fa fa-edit"></i>
			</Link>
			<button type="button" onClick={(e) => {this.removeData(e.target.dataset.id)}} data-toggle="tooltip" title="" className="btn btn-link btn-danger" data-original-title="Remove">
				<i className="fa fa-times" data-id={item.id}></i>
			</button>
		</div>
	)

  viewData = data => data.map((l, i) => (
  	<tr key={i}>
			<td>{l.report_type.toUpperCase()}</td>
			<td>{moment(l.created_at).format("YYYY/MM/DD")}</td>
			<td>
			<button type="button" onClick={(e) => {this.downloadData(l.file)}} className="btn btn-link" data-original-title="Remove"><i className="fa fa-download"></i></button>
			</td>
		</tr>
	))

  render(){

	  let {data}  = this.props
  	return(
	  <table id="basic-datatables" className="display table table-striped table-hover" ref={el => this.el = el} >
			<thead>
				<tr>
				 {appConstants.REPORT_COLUMNS.map((item) => <th>{item.title}</th>)}
				</tr>
			</thead>
			<tfoot>
				<tr>
				 {appConstants.REPORT_COLUMNS.map((item) => <th>{item.title}</th>)}
				</tr>
			</tfoot>
			<tbody>
				{this.viewData(data)}

			</tbody>
		</table>
		)
  }

}
export default (ReportTable)
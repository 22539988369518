import axios from "axios";
import moment from "moment";

import * as actionTypes from "@action-types";

function headers() {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    Authorization: user && user.auth_token,
  };
}

export function getRequest() {
  return {
    type: actionTypes.GET_REQUEST_SUCCESS,
    payload: {},
  };
}

export function getInvoicesSuccess(response) {
  return {
    type: actionTypes.GET_INVOICES,
    payload: {
      response,
    },
  };
}

function format(d) {
  return moment(d).format("YYYY-MM-DD");
}

export function getInvoices(start, end) {
  const url = [process.env.REACT_APP_API_HOST + "/api/v1/invoices"];
  start && url.push("start_date=" + format(start));
  end && url.push("end_date=" + format(end));

  return function (dispatch) {
    dispatch(getRequest());
    axios
      .get(url.join("&").replace("&", "?"), { headers: headers() })
      .then((response) => {
        dispatch(getInvoicesSuccess(response.data.invoices));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function getInvoiceSuccess(response) {
  return {
    type: actionTypes.GET_PARTICULAR_INVOICE,
    payload: {
      response,
    },
  };
}

export function getLocationSuccess(response) {
  return {
    type: actionTypes.GET_PARTICULAR_LOCATION,
    payload: {
      response,
    },
  };
}

export function getInvoice(id) {
  return function (dispatch) {
    dispatch(getRequest());
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/v1/invoices/${id}`, {
        headers: headers(),
      })
      .then((response) => {
        dispatch(getInvoiceSuccess(response.data.places));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function downloadInvoices(fileurl) {
  return function () {
    fetch(process.env.REACT_APP_API_HOST + fileurl, {
      method: "GET",
      headers: headers(),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileurl.split("?").slice(-1)[0]}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
}

export function getLocations() {
  return function (dispatch) {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/v1/locations`, {
        headers: headers(),
      })
      .then((response) => {
        dispatch(getLocationSuccess(response.data.places));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function saveInvoiceSuccess(response) {
  return {
    type: actionTypes.CREATE_INVOICE,
    payload: {
      response,
    },
  };
}

export function saveInvoice(component, invoice) {
  return dispatch => {
    dispatch(getRequest())

    // Trim First & Last Name
    invoice.first_name = invoice.first_name?.trim()
    invoice.last_name = invoice.last_name?.trim()

    axios.post(
        `${process.env.REACT_APP_API_HOST}/api/v1/invoices`,
        { invoice },
        { headers: headers() }
      )
      .then((response) => {
        dispatch(saveInvoiceSuccess(response.data));
        component.props.history.push("/invoices");
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function updateInvoiceSuccess(response) {
  return {
    type: actionTypes.UPDATE_INVOICE,
    payload: {
      response,
    },
  };
}

export function updateInvoice(_this, id, data) {
  return function (dispatch) {
    const _that = _this;
    dispatch(getRequest());
    axios
      .patch(
        `${process.env.REACT_APP_API_HOST}/api/v1/invoices/${id}`,
        { invoice: data },
        { headers: headers() }
      )
      .then((response) => {
        _that.props.history.push("/invoices");
        dispatch(updateInvoiceSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function removeInvoiceSuccess(_that, response) {
  return {
    type: actionTypes.REMOVE_INVOICE,
    payload: {
      response: response,
      obj: _that,
    },
  };
}

export function removeInvoice(_this, id) {
  return function (dispatch) {
    const _that = _this;
    dispatch(getRequest());
    axios
      .delete(`${process.env.REACT_APP_API_HOST}/api/v1/invoices/${id}`, {
        headers: headers(),
      })
      .then((response) => {
        dispatch(removeInvoiceSuccess(_that, response.data));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function createError(error) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.GET_RESPONSE_ERROR,
      payload: error,
    });
  };
}

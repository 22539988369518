import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import EmptyTable from "@shared/empty-table";
import Loader from "@shared/loader";
import { downloadInvoices } from "@actions/invoices";
import { downloadTransactions, voidTransaction } from "@actions/transactions";

import { getData } from "@actions/search";
import appConstants from "@constants/constants";
import TransactionTable from "./_transaction.table";

class Search extends React.Component {
  state = {
    current_transaction: {}
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const query = {
      query_type: params.get("query_type"),
      query: params.get("query"),
      search_target: "transaction",
    };

    if (!query.query_type || !query.query) {
      this.props.history.push("/search");
    }

    this.props.getData(query);
  }

  viewTransaction = () => {
    const { current_transaction } = this.state

    const rows = Object.keys(current_transaction).map((item) => (
      <tr>
        <td>{item.replace('_', ' ').spaceBeforeCap()}</td>
        <td>{current_transaction[item]}</td>
      </tr>
    ))

    return (
      <div
        className='modal fade'
        id='TransactionModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                View Transaction
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <table className='table'>{rows}</table>
            </div>
            <div className='modal-footer'></div>
          </div>
        </div>
      </div>
    )
  }

  showTransaction = (current_transaction) => {
    this.setState({ current_transaction })
  }

  render() {
    const { loading, data } = this.props;

    const table =
      !loading && data && data.length ? (
        <TransactionTable
          {...this.state}
          data={data}
          showTransaction={this.showTransaction}
          voidTransaction={this.props.voidTransaction}
          downloadTransactions={this.props.downloadTransactions}
        />
      ) : (
        <EmptyTable columns={appConstants.TRANSACTIONS_COLUMNS} />
      );

    return (
      <React.Fragment>
        <Loader loading={loading} />

        <div className="content">
          <div className="page-inner">
            <div className="page-header"></div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4 className="card-title">Results</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">{table}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.viewTransaction()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ search }) => ({
  data: search.data,
  error: search.error,
  success_message: search.success_message,
  success: search.success,
  search_target: search.search_target,
  loading: search.loading,
});

export default withRouter(
  connect(mapStateToProps, {
    getData,
    downloadTransactions,
    downloadInvoices,
    voidTransaction,
  })(Search)
);

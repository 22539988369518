import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import axios from 'axios'

import Loader from '@components/loader'
import Form from '@components/form'
// import TermsAndPrivacy from '@components/terms-and-privacy'
import { saveTransaction } from '@actions/transactions'

import Template from './template'
import { withRouter } from 'react-router'
import ENV from '@constants/constants'
import extractKeys from '@helpers/extract-keys'

export const ROUTE_OPEN = true
const BASE_URL = process.env.REACT_APP_API_HOST + '/' + ENV.API_V

const emptyForm = () => ({
  form: {
    'invoice_number': '',
    'first_name': '',
    'last_name': '',
    'email': '',
  },

  amount: '',
  invoice: {
    long_desc: '',
  }
})

const PaymentsScreen = withRouter(props => {
  const [state, setState] = useState({
    ...emptyForm(),
    nonce: 0
  })

  const store = useSelector(state => state.payments)

  useEffect(() => {
    if (window.location.search.includes('redirected=true')) return

    // Swal.fire({
    //   icon: 'question',
    //   html: `<h3>Will you be making a Credit/Debit Card or Sand Dollar payment today?</h3>`,
    //   confirmButtonText: 'Credit/Debit Card',
    //   cancelButtonText: 'Sand Dollar',
    //   showCancelButton: true,
    //   allowEnterKey: false,
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    // }).then(res => {
    //   if (res.isConfirmed) {
    //     props.history.push(
    //       window.location.pathname + '?redirected=true',
    //       window.location.pathname,
    //       { shallow: true }
    //     )
    //   } else {
    //     window.location = process.env.NEXT_APP_SANDDOLLAR_PYMT
    //   }
    // })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = {
    token: async () => {
      try {
        const { data } = await axios.get(this.BASE_URL + '/fetch_guest_token')
        return data.token
      } catch (err) {
        console.error(err)
      }
    },

    invoice: async (id = '') => {
      const Authorization = await fetch.token()

      try {
        const { data } = await axios.get(
          `${BASE_URL}/revenue_line_items/by_invoice_number/${id}`,
          { headers: { Authorization } }
        )

        const invoice = data.revenue_line_item
        invoice && setState({
          ...state,
          invoice,
          amount: data.invoice_amount.toFixed(2)
        })
      } catch (err) {
        console.error(err)
      }
    },
  }

  const fields = [
    '::Invoice Details',
    {
      name: 'invoice_number',
      onBlur: ({ target }) => fetch.invoice(target.value)
    },
    {
      name: 'revenue_line_item',
      readOnly: true,
      value: state.invoice?.long_desc
    },
    {
      name: 'card-amount',
      label: 'Amount (BSD)',
      readOnly: true,
      value: state.amount
    },
    '::Client Details',
    ['first_name', 'last_name'],
    'email',
    'CARD_INFO'
  ]

  const submit = async values => {
    const { invoice, amount } = state

    if (!invoice?.long_desc) {
      Swal.fire(
        'Invoice Not Found',
        'Please check the invoice number. We do not have a record to match that on file.',
        'error'
      )
      return
    }

    const { isConfirmed } = await Swal.fire({
      icon: 'question',
      html: `<h3>Are you sure that you'd like to make this request?</h3>`,
      showCancelButton: true,
      confirmButtonText: 'Yes',
    })

    if (!isConfirmed) return

    const transaction = await props.saveTransaction(null, {
      ...values,
      first_name: values.first_name?.trim(),
      last_name: values.last_name?.trim(),
      // 'publisher-name': 'pnpdemo',
      'transaction_mode': 'card',
      'card-amount': amount.toFixed(2),
      'revenue_line_item_id': state.invoice.id,
    }, false)

    if (transaction.error) {
      Swal.fire(
        'Error',
        transaction.error,
        'error'
      )
    } else props.history.push('/success')
  }

  return (
    <Template background='/assets/img/payment-screen-card.jpg'>
      <Loader loading={props.transaction.loading} />
      <h3 className='text-center my-6'>Make A Payment</h3>
      {Form({ fields, onSubmit: submit })}
      {/* <TermsAndPrivacy /> */}
    </Template>
  )
})

export default connect(
  state => ({
    transaction: extractKeys(state.transactions, 'success', 'loading', 'transaction')
  }),
  { saveTransaction }
)(PaymentsScreen)

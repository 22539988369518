import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";

import InputField from "@shared/form-fields/input";
import SelectField from "@shared/form-fields/select";
import { transformObjectToQuery } from "../../utils";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: {
        query_type: "",
        query: "",
        search_target: "",
      },
    };
  }

  componentDidMount() {
    // this.props.getInvoices(this.state.date_start, this.state.date_end);
    // this.props.startRequest();
  }

  componentWillReceiveProps({ success }) {
    // success && this.setState({ isOpen: false });
  }

  // componentDidCatch (error, info) {
  // 	window.location.reload()
  // }

  handleFormState = (event) => {
    this.setState({
      formState: {
        ...this.state.formState,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSelectFields = (field, value) => {
    let val = "";
    if (value) {
      val = value.value;
    }
    this.setState({
      formState: {
        ...this.state.formState,
        [field]: val,
      },
    });
  };

  submitHandler = () => {
    let path = "/search/transactions";
    console.log(this.state.formState);
    if (this.state.formState.search_target == "invoice") {
      path = "/search/invoices";
    }
    const query = transformObjectToQuery(this.state.formState);

    this.props.history.push(`${path}?${query}`);
    // this.props.getData({ ...this.state.formState });
  };

  render() {
    const styles = {
      form: {
        display: "block",
        alignItems: "center",

        width: "100%",
        marginRight: "1.5rem",
      },
      label: {
        margin: 0,
      },
      select: {
        height: "calc(1.5em + .75rem + 2px)",
      },
    };

    const search_types = [
      {
        value: "invoice_reference",
        label: "Invoice Reference",
      },
      {
        value: "last_name",
        label: "Last Name",
      },
    ];

    const target_types = [
      {
        value: "invoice",
        label: "Invoice",
      },
      {
        value: "transaction",
        label: "Receipt",
      },
    ];

    return (
      <React.Fragment>
        <div className="content">
          <div className="page-inner">
            <div className="page-header"></div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4 className="card-title">Search</h4>
                    </div>
                  </div>

                  <ValidatorForm
                    style={styles.form}
                    onSubmit={this.submitHandler}
                  >
                    <div className="card-body">
                      <div className="form-group form-show-validation row">
                        <label
                          htmlFor="name"
                          className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right"
                        >
                          Search Type<span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <SelectField
                            onChange={(values) =>
                              this.handleSelectFields("query_type", values[0])
                            }
                            name="query_type"
                            options={search_types}
                            value={this.state.formState.query_type}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label
                          htmlFor="name"
                          className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right"
                        >
                          Search<span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            name="query"
                            onChange={(event) => this.handleFormState(event)}
                            style={{ height: `calc(1.5em + .75rem + 2px)` }}
                            value={this.state.formState.query}
                            className={"form-control"}
                            placeholder={"Invoice Reference or Last Name"}
                            validators={["required"]}
                            errorMessages={["Required"]}
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label
                          htmlFor="name"
                          className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right"
                        >
                          Type<span className="required-label">*</span>
                        </label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <SelectField
                            onChange={(values) =>
                              this.handleSelectFields(
                                "search_target",
                                values[0]
                              )
                            }
                            name="search_taget"
                            options={target_types}
                            value={this.state.formState.search_target}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-action">
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            className="btn btn-success mr-1"
                            type="submit"
                            value="Submit"
                          />
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ search }) => ({});

export default withRouter(connect(mapStateToProps, {})(Search));

import * as actionTypes from '@action-types'

const initial = {
  authenticated: false,
  loading: false,
  success: false,
  success_message: null,
  error: null,
  data: [],
  line_items: [],
}

export default (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actionTypes.GET_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.response.data.response
      }
    case actionTypes.CREATE_CASH_PAYMENT:
      return {
        ...state,
        loading: false,
        error: null,
        success_message: "Payment Successful",
        success: true,
        head: payload.response
      }
      case actionTypes.CREATE_REPLAY_CASH_PAYMENT:
        return {
          ...state,
          loading: false,
          error: null,
          success_message: "Your confirmation has been sent.",
          success: true,
          head: payload.response
        }
    case actionTypes.GET_ALL_REVENUE:
      return {
        ...state,
        loading: false,
        line_items: payload.response || []
      }
    case actionTypes.CREATE_PAYEE:
      return {
        ...state,
        loading: false,
        success_message: "Payee Creation Successful",
        success: true,
        payee: payload.response
      }
    case actionTypes.CUSTOM_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.response
      }
    default:
      return {
        ...state,
        loading: false
      }
  }
}



import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ValidatorForm } from 'react-form-validator-core'

import { saveCashPayment } from '@actions/cash'
import Loader from '@shared/loader'
import extractKeys from '@helpers/extract-keys'
import formatPhoneNumber from '@helpers/normalize-phone'
import InputField from '@shared/form-fields/input'
import Field from '@shared/form-fields'

import appConstants from '@constants/constants'

const emptyForm = () => ({
  form: {
    'invoice_number': '',
    'first_name': '',
    'last_name': '',
    'email': '',
  },

  amount: '',
  invoice: {
    long_desc: '',
  }
})

class Cash extends React.Component {
  state = {
    ...emptyForm(),
    nonce: 0,
  }

  user = sessionStorage.user && JSON.parse(sessionStorage.user)
  BASE_URL = process.env.REACT_APP_API_HOST + '/' + appConstants.API_V

  fetch = {
    token: async () => {
      try {
        const { data } = await axios.get(this.BASE_URL + '/fetch_guest_token')
        return data.token
      } catch (err) {
        console.error(err)
      }
    },

    invoice: async ({ target }) => {
      const Authorization = await this.fetch.token()

      try {
        const { data } = await axios.get(
          `${this.BASE_URL}/revenue_line_items/by_invoice_number/${target.value}`,
          { headers: { Authorization } }
        )

        const invoice = data.revenue_line_item
        invoice && this.setState({
          invoice,
          amount: data.invoice_amount.toFixed(2)
        })
      } catch (err) {
        console.error(err)
      }
    },
  }

  on = {
    change: ({ target }) => this.setState(state => {
      state.form[target.name] = target.value
      return state
    }),

    submit: ev => {
      ev.preventDefault()
      this.props.saveCashPayment(this, {
        ...this.state.form,
        mobile_number: formatPhoneNumber(this.state.form.mobile_number),
        transaction_mode: 'agency',
        third_party_type: 'cash'
      })
    }
  }

  render () {
    const { state, props, on, fetch } = this
    const { form, invoice, amount, nonce } = state

    const useDefaultEmail = () => this.setState(state => {
      state.form.email = 'digipayusers@bahamas.gov.bs'
      return state
    })

    return (
      <React.Fragment >
        <Loader loading={props.loading} />
        <div className="content" key={nonce}>
          <div className="page-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Cash</div>
                  </div>
                  <ValidatorForm method="post" onSubmit={on.submit} >
                    <div className="card-body">
                      <div>Invoice Details</div>
                      <hr />

                      <Field
                        type='text'
                        name="invoice_number"
                        label='Invoice Number'
                        onChange={on.change}
                        onBlur={fetch.invoice}
                        value={form.invoice_number}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        className="form-control"
                        placeholder="Invoice Number"
                      />

                      <Field name='revenue_line_item' label='Revenue Line Item'>
                        <InputField readOnly value={invoice.long_desc} className="form-control" />
                      </Field>

                      <Field name='card_amount' label='Amount'>
                        <InputField readOnly value={amount} className="form-control" />
                      </Field>

                      <div>Customer Info</div>
                      <hr />

                      <Field name='first_name' label='First Name'>
                        <InputField
                          onChange={on.change}
                          name="first_name"
                          value={form.first_name}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          className="form-control"
                          placeholder="First Name"
                        />
                      </Field>

                      <Field name='last_name' label='Last Name'>
                        <InputField
                          onChange={on.change}
                          name="last_name"
                          value={form.last_name}
                          validators={['required']}
                          errorMessages={['This field is required']}
                          className="form-control"
                          placeholder="Last Name"
                        />
                      </Field>

                      <Field name='email' label='Email'>
                        <InputField
                          onChange={on.change}
                          name="email"
                          value={form.email}
                          validators={['required', 'isEmail']}
                          errorMessages={['This field is required', 'Must be a valid email address']}
                          className="form-control"
                          placeholder="Email"
                        />
                        <a role="button" onClick={useDefaultEmail}>Default Email</a>
                      </Field>

                      <Field name='sms' required={false} label='Mobile Number'>
                        <InputField
                          onChange={on.change}
                          name="mobile_number"
                          value={form.mobile_number}
                          className="form-control"
                          placeholder="Mobile Number"
                          type="number"
                        />
                        <p>All phone numbers must start with 1242</p>
                      </Field>

                      <Field name='sms' required={false} label='Additional Notes'>
                        <textarea
                          onChange={on.change}
                          name="notes"
                          maxLength="250"
                          value={form.notes}
                          className="form-control"
                          placeholder="Additional Notes"
                          type="text"
                        />
                      </Field>
                    </div>

                    <div className="card-action">
                      <div className="row">
                        <div className="col-md-12">
                          <input className="btn btn-success mr-1" type="submit" value="Submit" disabled={props.loading} />
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(
  connect(
    state => extractKeys(state.cash, 'loading'),
    { saveCashPayment }
  )(Cash)
)

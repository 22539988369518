import axios from 'axios';

import * as actionTypes from '@action-types'

function headers() {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    "Authorization": user && user.auth_token
  }
}


export function getRequest(){
	return {
		type:  actionTypes.GET_REQUEST_SUCCESS,
		payload: {}
	}
}


export function getDbDataSuccess(response){
  return {
      type: actionTypes.GET_DB_DATA,
      payload: {
          response,
      }
  }
}

export function getDashboardData() {
  return axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/dashboards`,{headers: headers()})
}

export function getRecentData() {
  return axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/transactions/recent`,{headers: headers()})
}

export function getDbData() {
  return function (dispatch) {
    dispatch(getRequest())
    axios.all([getDashboardData(), getRecentData()])
    .then(axios.spread(function (dbData, recentData) {
      dispatch(getDbDataSuccess({dbData: dbData.data, transactions: recentData.data.transactions}))
    })).catch(error=> {
        dispatch(createError(error))
    })
    

  };
}



export function createError(error){
 return function(dispatch) {
    dispatch({
      type: actionTypes.GET_RESPONSE_ERROR,
      payload: error
    });
  }
}
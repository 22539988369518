import React, { createElement } from 'react'
import { FaDollarSign, FaRedo, FaFileContract, FaUsers } from 'react-icons/fa'

import { Link, withRouter } from 'react-router-dom'

class AppLinks extends React.Component {
  state = {
    open: false,
    user: sessionStorage.user && JSON.parse(sessionStorage.user),
  }

  roles = new Set(this.state.user.roles.map(r => r.name))

  links = [
    group('Payments', FaDollarSign, [
      {
        path: '/cash',
        icon: 'money-bill-alt',
        allow: ['revenue', 'agency_cashier'],
      },
      {
        path: '/cheque',
        icon: 'money-check-alt',
        allow: ['revenue', 'agency_cashier'],
      },
      {
        path: '/credit-card',
        icon: 'credit-card',
        allow: ['revenue', 'agency_cashier'],
      },
      {
        path: '/swipe',
        icon: 'cut',
        allow: ['revenue', 'agency_cashier'],
      },
      {
        path: process.env.REACT_APP_SANDDOLLAR_PYMT || '/',
        label: 'Sand Dollar',
        icon: 'hourglass-start',
        allow: ['revenue', 'agency_cashier'],
      },
    ]),
    group('Reporting', FaFileContract, [
      {
        path: '/invoices',
        icon: 'file-invoice-dollar',
        badge: true,
        allow: [
          'treasury',
          'revenue',
          'agency_cashier',
          'agency_accountant',
          'agency_invoice',
          'read',
        ],
      },
      {
        path: '/transactions',
        icon: 'dollar-sign',
        allow: [
          'treasury',
          'revenue',
          'agency_cashier',
          'agency_accountant',
          'read',
        ],
      },
      // {
      //   path: '/report',
      //   icon: 'chart-line',
      //   allow: ['revenue', 'treasury'],
      // },
      // {
      // 	path: '/line-items',
      // 	icon: 'user',
      // 	allow: ['treasury', 'revenue']
      // },
      {
        path: '/revenue-line-items',
        icon: 'list',
        allow: [
          'treasury',
          'revenue',
          'agency_accountant',
          'agency_invoice',
          'technology'
        ],
      },
    ]),
    group('Entities', FaUsers, [
      {
        path: '/companies',
        icon: 'building',
        allow: [
          'treasury',
          'revenue',
          'agency_accountant',
          'agency_invoice',
          'technology'
        ],
      },
      {
        path: '/users',
        icon: 'users',
        // allow: ['technology', 'revenue'],
      },
    ]),
    group('Replay', FaRedo, [
      {
        path: '/replay-email',
        icon: 'envelope',
        allow: [
          'agency_cashier',
          'agency_invoice',
          'technology',
          'revenue'
        ],
      },
      {
        path: '/replay-sms',
        icon: 'comment',
        allow: [
          'agency_cashier',
          'agency_invoice',
          'technology',
          'revenue'
        ],
      },
    ]),
  ]

  render () {
    const { roles } = this

    const commonStyles = { marginRight: 15 }

    return this.links.map(({ label, icon, links }) => {
      const showing = links
        .filter(l => !l.allow || l.allow.some(r => roles.has(r)))

      if (!showing.length) return null
      const classes = [
        'nav-item submenu',
        showing.some(link => matches(link.path)) ? 'active' : ''
      ].join(' ')

      return (
        <li className={classes}>
        <a className="nav-link" href="#">
          {createElement(icon, { size: 18, style: commonStyles })}
          <span className="menu-title">{label}</span>
        </a>
        <div className="navbar-dropdown animated fadeIn">
          <ul>{links.map(link => <NavLink {...link} />)}</ul>
        </div>
      </li>
      )
    })
  }
}

function group (label = '', icon, links) {
  return { label, icon, links }
}

function matches (path) {
  return window.location.pathname.startsWith(path) ? 'active' : ''
}

function initialCaps (str) {
  if (str == 'replay-sms') {
    return 'Replay SMS'
  } else {
    return str.initialCaps()
  }
}

function NavLink ({ path, icon, label, badge }) {
  const linkCls = ['nav-item', matches(path)].join(' ')
  // const iconCls = /^fas? fa-/.test(icon) ? icon : `fas fa-${icon}`
  const name = label || initialCaps(path.slice(path.lastIndexOf('/') + 1))

  if (/^https?/.test(path)) return (
    <li className={linkCls} key={path}>
      <a href={path} target='blank'>{name}</a>
    </li>
  )

  return (
    <li className={linkCls} key={path}>
      <Link to={path}>{name}</Link>
    </li>
  )
}

export default withRouter(AppLinks)

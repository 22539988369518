import React, { Fragment } from 'react'
import { ValidatorComponent } from 'react-form-validator-core'
import DatePicker from 'react-datepicker'

class DateTimeField extends ValidatorComponent {
  errorStyles = {
    color: '#f25961',
    fontSize: '80%',
    fontWeight: 600,
  }

  errorText = () => this.state.isValid ? null : (
    <div style={this.errorStyles}>
      {this.getErrorMessage()}
    </div>
  )

  renderValidatorComponent () {
    const { errorMessages, validators, requiredError, validatorListener, withRequiredValidator, icon, ...rest } = this.props
    if (!this.state.isValid) rest.className += ' input_error'

    return (
      <Fragment>
        <DatePicker {...rest} />
        {this.errorText()}
      </Fragment>
    )
  }
}

export default DateTimeField

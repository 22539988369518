import axios from 'axios'

import * as T from '@action-types'

const getAuthHeader = () => {
  const user = JSON.parse(sessionStorage.getItem("user") || '{}')

  return {
    'Content-Type'  : 'application/json',
    'Authorization' : user.auth_token
  }
}

const createError = (dispatch, payload) => dispatch({
  type: T.GET_RESPONSE_ERROR,
  payload
})

const getRequest = {
  type: T.GET_REQUEST_SUCCESS,
  payload: {}
}

export const getCompanies = () => async dispatch => {
  const headers = getAuthHeader()
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/companies`

  try {
    const { data } = await axios.get(url, { headers })

    dispatch({
      type: T.GET_COMPANIES,
      payload: data.companies
    })
  } catch (err) {
    createError(dispatch, err)
  }
}

export const getCompany = id => async dispatch => {
  const headers = getAuthHeader()
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/companies/${id}`

  try {
    const { data } = await axios.get(url, { headers })

    dispatch({
      type: T.GET_PARTICULAR_COMPANY,
      payload: data
    })
  } catch (err) {
    createError(dispatch, err)
  }
}

export const saveCompany = (history, data) => async dispatch => {
  dispatch(getRequest)

  const headers = getAuthHeader()
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/companies`

  try {
    const { data: company } = await axios.post(url, data, { headers })
    history.push('/ministries')

    dispatch({
      type: T.CREATE_COMPANY,
      payload: company
    })
  } catch (err) {
    createError(dispatch, err)
  }
}

export const updateCompany = (component, id, data) => async dispatch => {
  dispatch(getRequest)

  const headers = getAuthHeader()
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/companies/${id}`

  try {
    const { data: company } = await axios.patch(url, data, { headers })
    component.props.history.push('/ministries')

    dispatch({
      type: T.UPDATE_COMPANY,
      payload: company
    })
  } catch (err) {
    createError(dispatch, err)
  }
}

export const removeCompany = (component, id) => async dispatch => {
  dispatch(getRequest)

  const headers = getAuthHeader()
  const url = `${process.env.REACT_APP_API_HOST}/api/v1/companies/${id}`

  try {
    const { data: company } = await axios.delete(url, { headers })
    component.props.history.push('/ministries')

    dispatch({
      type: T.REMOVE_COMPANY,
      payload: {
        component,
        company
      }
    })
  } catch (err) {
    createError(dispatch, err)
  }
}

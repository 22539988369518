
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import LandingPage from '@wrappers/landing-page'
import { loginUser } from '@actions/users'

export const ROUTE_OPEN = true

class LoginScreen extends React.Component {
  state = {
    open: false,
    form: {
      email: '',
      password: '',
    },
  }

  componentDidCatch () {
    sessionStorage.clear()
  }

  on = {
    change: ({ target }) => this.setState(state => {
      state.form[target.name] = target.value
      return state
    }),

    check: ({ target }) => this.setState(state => {
      state.form.remember_me = target.checked
      return state
    }),

    submit: ev => {
      ev.preventDefault()
      this.props.loginUser(this.state.form)
    }
  }

  togglePasswordVisibility = () => {
    const input = document.getElementById('password')

    if (input) {
      const attr = input.getAttribute('type') == 'password' ? 'text' : 'password'
      input.setAttribute('type', attr)
    }
  }

  render () {
    const { state, props, on, togglePasswordVisibility } = this

    return (
      <LandingPage
        title='Ministry of Health & Wellness'
        subtitle="Financial Services for MOH"
        success_message={props.success_message}
        error={props.error}
      >
        <form onSubmit={on.submit}>
          <div className="form-group">
            <label htmlFor="username" className="placeholder"><b>Email</b></label>
            <input id="email"
              name="email"
              value={state.form.email}
              onChange={on.change}
              type="text"
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="placeholder"><b>Password</b></label>
            <Link to={"/forget-password"} className="link float-right">Forget Password ?</Link>
            <div className="position-relative">
              <input
                id="password"
                name="password"
                type="password"
                value={state.form.password}
                onChange={on.change}
                className="form-control"
                required
              />
              <div className="show-password">
                <i className="fas fa-eye" onClick={togglePasswordVisibility}></i>
              </div>
            </div>
          </div>
          <div className="form-group form-action-d-flex mb-3">
            <div className="custom-control custom-checkbox">
              <input type="checkbox" checked={state.form.remember_me} onChange={on.check} className="custom-control-input" name="remember_me" id="rememberme" />
              <label className="custom-control-label m-0" htmlFor="rememberme">Remember Me</label>
            </div>
            <input type="submit" className="btn btn-secondary col-md-5 float-right mt-3 mt-sm-0 fw-bold" value="Sign In" />
          </div>
        </form>
      </LandingPage>
    )
  }
}

export default connect(({ users }) => ({
  farmerUser: users.login,
  error: users.error,
  success_message: users.success_message,
  currentUser: users.currentUser,
}), { loginUser })(LoginScreen)

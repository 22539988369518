import React, { useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ValidatorForm } from 'react-form-validator-core'

import Field from '@shared/form-fields'
import islands from '@constants/islands'
import { saveCompany } from '@actions/companies'

export const ROUTE_PATHS = [
  '/companies/new',
  '/companies/edit'
]

const Form = ({ history }) => {
  const dispatch = useDispatch()

  const [form, setForm] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    {
      name_of_company: "",
      address: "",
      po_box: "",
      street: "",
      country: "",
      city: "",
      island: "",
      phone_number: "",
      email_address: "",
      first_name: "",
      last_name: "",
      merchant_token_id: "",
      website: "",
      logo: ""
    }
  )

  const onSubmit = async ev => {
    ev.preventDefault()
    dispatch(saveCompany(history, form))
  }

  const on = {
    text: ({ target }) => setForm({ [target.name]: target.value }),
  }

  return (
    <div className='content'>
      <div className='page-inner'>
        <div className='page-header'>
          <h4 className='page-title'>
            Company Form
          </h4>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-header'>
                <div className='card-title'>
                  Company
                </div>
              </div>
              <ValidatorForm onSubmit={onSubmit}>
                <div className='card-body'>
                  <Field
                    type='text'
                    name='name_of_company'
                    value={form.name_of_company}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='address'
                    value={form.address}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='po_box'
                    label='P. O. Box'
                    value={form.po_box}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='street'
                    value={form.street}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='country'
                    value={form.country}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='city'
                    value={form.city}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='island'
                    value={form.island}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='phone_number'
                    value={form.phone_number}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='email_address'
                    value={form.email_address}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='first_name'
                    value={form.first_name}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='last_name'
                    value={form.last_name}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='merchant_token_id'
                    value={form.merchant_token_id}
                    onChange={on.text}
                  />
                  <Field
                    type='text'
                    name='website'
                    value={form.website}
                    onChange={on.text}
                  />
                  {/* <Field
                    type='text'
                    name='logo'
                    value={form.logo}
                    onChange={on.text}
                  /> */}
                </div>
                <div className='card-action'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <button
                        type='submit'
                        className='btn btn-success mr-1'
                        children='Submit'
                      />
                      <button
                        type='button'
                        className='btn btn-danger'
                        onClick={() => history.push('/companies')}
                        children='Cancel'
                      />
                    </div>
                  </div>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Form)

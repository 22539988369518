import * as actionTypes from "@action-types";
import axios from "axios";
import { history } from "@router";
import cookie from "react-cookies";

function headers() {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    Authorization: user?.auth_token,
  };
}

export function getRequest() {
  return {
    type: actionTypes.GET_REQUEST_SUCCESS,
    payload: {},
  };
}

export function getRMRequest() {
  return {
    type: actionTypes.GET_RM_REQUEST,
    payload: {},
  };
}

export function loginSuccess(response) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: {
      response,
    },
  };
}
export function loginUser(data) {
  let _history = history;
  return function (dispatch) {
    dispatch(getRequest());
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/v1/users/signin`, data)
      .then((response) => {
        const roles = response.data.user.roles.map((r) => r.name);
        sessionStorage.setItem("user", JSON.stringify(response.data.user));
        cookie.save(
          "rememberFinanceObj",
          JSON.stringify({ remember_token: response.data.user.remember_token }),
          { path: "/" }
        );

        if (roles.includes("read") || roles.includes("agency_invoice")) {
          _history.push("/invoices");
        } else if (
          response.data.user.roles.length == 1 &&
          response.data.user.roles[0].name == "agency_cashier"
        ) {
          _history.push("/invoices");
        } else if (
          response.data.user.roles.length == 1 &&
          response.data.user.roles[0].name == "technology"
        ) {
          _history.push("/revenue-line-items");
        } else {
          _history.push("/dashboard");
        }
        dispatch(loginSuccess(response.data));
        setTimeout(function () {
          dispatch(clearError());
        }, 2000);
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function rememberTokenSuccess(response) {
  return {
    type: actionTypes.REMEMBER_TOKEN_SUCCESS,
    payload: {
      response,
    },
  };
}
export function rememberToken(token) {
  let _history = history;
  return async function (dispatch) {
    dispatch(getRMRequest());
    await axios
      .get(
        `${process.env.REACT_APP_API_HOST}/api/v1/users/get_user_remember_token?remember_token=${token}`
      )
      .then((response) => {
        sessionStorage.setItem("user", JSON.stringify(response.data.user));
        dispatch(rememberTokenSuccess(response.data));
        _history.push("/dashboard");
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.STOP_LOADER,
        });

        cookie.remove("rememberFinanceObj", { path: "/" });

        setTimeout(function () {
          dispatch(createError(error));
          return false;
        }, 800);

        _history.push("/login");
      });
  };
}

export function logoutUser(data) {
  return function (dispatch) {
    dispatch(getRequest());

    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/v1/users/signout`, {
        headers: headers(),
      })
      .then((response) => {
        sessionStorage.clear();
        cookie.remove("rememberFinanceObj", { path: "/" });
        window.location.href = "/login";
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function forgetPasswordSuccess(response) {
  return {
    type: actionTypes.FORGET_PASSWORD_SUCCESS,
    payload: {
      response,
    },
  };
}
export function forgetPassword(email) {
  let _history = history;
  return function (dispatch) {
    dispatch(getRequest());
    axios
      .post(`${process.env.REACT_APP_API_HOST}/api/v1/passwords`, {
        user: email,
      })
      .then((response) => {
        _history.push("/login");
        dispatch(forgetPasswordSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function resetPasswordSuccess(response) {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    payload: {
      response,
    },
  };
}
export function resetPassword(data) {
  let _history = history;
  return function (dispatch) {
    dispatch(getRequest());
    axios
      .post(
        `${process.env.REACT_APP_API_HOST}/api/v1/passwords/update_by_token`,
        { user: data }
      )
      .then((response) => {
        _history.push("/login");
        dispatch(resetPasswordSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function getUserSuccess(response) {
  return {
    type: actionTypes.GET_USER_DATA,
    payload: {
      response,
    },
  };
}

export function getUsersSuccess(response) {
  return {
    type: actionTypes.GET_USERS_DATA,
    payload: {
      response,
    },
  };
}

export function getUser(id) {
  return function (dispatch) {
    dispatch(getRequest());
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/v1/users/${id}`, {
        headers: headers(),
      })
      .then((response) => {
        dispatch(getUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function getRolesSuccess(response) {
  return {
    type: actionTypes.GET_ROLES_DATA,
    payload: {
      response,
    },
  };
}

export function getRoles(id) {
  return function (dispatch) {
    dispatch(getRequest());
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/v1/users/roles`, {
        headers: headers(),
      })
      .then((response) => {
        console.log("res", response);
        dispatch(getRolesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function saveUserSuccess(response) {
  return {
    type: actionTypes.CREATE_USER,
    payload: {
      response,
    },
  };
}

export function saveUser(_this, data) {
  return function (dispatch) {
    const _that = _this;
    dispatch(getRequest());
    axios
      .post(
        `${process.env.REACT_APP_API_HOST}/api/v1/users`,
        { user: data },
        { headers: headers() }
      )
      .then((response) => {
        console.log("resp", response.data);
        _that.props.history.push("/users");
        dispatch(saveUserSuccess(response.data));
      })
      .catch((error) => {
        console.log("err", error);
        dispatch(createError(error));
      });
  };
}

export function updateUserSuccess(response) {
  return {
    type: actionTypes.UPDATE_USER,
    payload: {
      response,
    },
  };
}

export function updateUser(_this, id, data) {
  return function (dispatch) {
    const _that = _this;
    dispatch(getRequest());
    axios
      .patch(
        `${process.env.REACT_APP_API_HOST}/api/v1/users/${id}`,
        { user: data },
        { headers: headers() }
      )
      .then((response) => {
        _that.props.history.push("/users");
        dispatch(updateUserSuccess(response.data));
      })
      .catch((error) => {
        console.log("err", error);
        dispatch(createError(error));
      });
  };
}

export function updateProfileSuccess(response) {
  return {
    type: actionTypes.UPDATE_USER_PROFILE,
    payload: {
      response,
    },
  };
}
export function updateProfile(id, data) {
  return function (dispatch) {
    dispatch(getRequest());
    axios
      .patch(
        `${process.env.REACT_APP_API_HOST}/api/v1/users/${id}`,
        { user: data },
        { headers: headers() }
      )
      .then((response) => {
        dispatch(updateProfileSuccess(response.data));
        setTimeout(function () {
          dispatch(clearError());
        }, 2000);
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function getUsers() {
  return function (dispatch) {
    dispatch(getRequest());
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/v1/users`, {
        headers: headers(),
      })
      .then((response) => {
        dispatch(getUsersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function createError(error) {
  if (error.response.status == 401) {
    clearSession();
  }

  return function (dispatch) {
    setTimeout(function () {
      dispatch(clearError());
    }, 2000);
    dispatch({
      type: actionTypes.GET_RESPONSE_ERROR,
      payload: error,
    });
  };
}

export function clearError() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CLEAR_ERROR,
    });
  };
}

function clearSession() {
  sessionStorage.clear();
  history.push("/login");
  // window.location.href = "/login"
}


import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import EmptyTable from '@shared/empty-table'
import { getCompanies, removeCompany } from '@actions/companies'
import appConstants from '@constants/constants'
import _ from 'lodash'
import CompanyTable from './_table'

class Company extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      current_list: {},
      data: []
    }
    this.handleAction = this.handleAction.bind(this)
    this.reqcloseModal = this.reqcloseModal.bind(this)
    this.removeData = this.removeData.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.success) {
      this.setState({ isOpen: false })
    }
    if (nextProps.data !== undefined) {
      this.setState({ data: nextProps.data })
    }
  }




  handleAction(id, action) {
    if (action == 'edit') {
      let current_list = this.state.lists.filter((i) => i.id === id)[0]
      this.setState({ isOpen: true, current_list: current_list })
    } else {

    }

  }



  componentDidMount() {
    this.props.getCompanies()
  }
  reqcloseModal() {
    this.setState({ isOpen: !this.state.isOpen, current_list: {} })
  }

  removeData(id) {
    this.props.removeCompany(this, id)
  }

  componentDidCatch(error, info) {
    window.location.reload()
  }

  render = () => (
    <React.Fragment>
      <div className="content">
        <div className="page-inner">
          <div className="page-header">
            {/*<h4 className="page-title">Company table</h4>*/}
            {/*<ul className="breadcrumbs">
                <li className="nav-home">
                  <a href="#">
                    <i className="flaticon-home"></i>
                  </a>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow"></i>
                </li>
                <li className="nav-item">
                  <a href="#">Tables</a>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow"></i>
                </li>
                <li className="nav-item">
                  <a href="#">Datatables</a>
                </li>
              </ul> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">Ministries</h4>
                    <a className="btn btn-primary btn-round ml-auto" href="/ministries/new">
                      <i className="fa fa-plus"></i>
                      Add Row
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {
                      (_.isEmpty(this.state.data)) ? <EmptyTable columns={appConstants.COMPANIES_COLUMNS} /> :
                        <div><CompanyTable {...this.state} removeCompany={(id) => this.removeData(id)} /></div>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}


function mapStateToProps(state) {
  return {
    data: state.companies.companies,
    error: state.companies.error,
    success_message: state.companies.success_message,
    success: state.companies.success
  }
}


export default withRouter(connect(mapStateToProps, { getCompanies, removeCompany })(Company))









import axios from "axios";

import * as actionTypes from "@action-types";

function headers() {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    Authorization: user && user.auth_token,
  };
}

export function getRequest() {
  return {
    type: actionTypes.GET_REQUEST_SUCCESS,
    payload: {},
  };
}

export function getData(input) {
  const url = [process.env.REACT_APP_API_HOST, "api/v1"];
  if (input.search_target === "transaction") {
    url.push("transactions");
  } else {
    url.push("invoices");
  }

  if (input.query_type === "last_name") {
    url.push("by_last_name");
  } else {
    url.push("by_invoice_number_scoped");
  }
  url.push(encodeURIComponent(input.query));

  return function (dispatch) {
    dispatch(getRequest());
    axios
      .get(url.join("/"), { headers: headers() })
      .then((response) => {
        // console.log(response);
        dispatch(getDataSuccess(input, response));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

function mapTransactions(d) {
  // const data = [];
  // for (let i = 0; i < d.length; i++) {
  //   data.push(d[i].transaction);
  // }
  // return data;
  return d;
}

function getDataSuccess(input, response) {
  let data = [];
  if (input.search_target == "transaction") {
    if (input.query_type == "last_name") {
      data = mapTransactions(response.data.transactions);
    } else {
      data = response.data.transactions;
    }
  } else {
    data = response.data.invoices;
  }

  return {
    type: actionTypes.GET_SEARCH_DATA,
    payload: {
      search_target: input.search_target,
      data,
    },
  };
}

export function createError(error) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.GET_RESPONSE_ERROR,
      payload: error,
    });
  };
}

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { saveReport } from '@actions/reports'
import { getRevenueLineItems } from '@actions/lineitems'
import { preValue } from '@helpers/tools'
import SelectField from '@shared/form-fields/select'
import { ValidatorForm } from 'react-form-validator-core';
import _ from 'lodash'
import DateTimeField from '@shared/form-fields/date'

export const ROUTE_PATHS = ['/report/generate']

let invobj = {}
class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    const today = new Date()
    this.state = {
      formData: {},
      invoice: {},
      date_start: today,
      date_end: today,
      user: JSON.parse(sessionStorage.user),
      error: undefined,
      list_id: this.props.location.search.replace('?id=', "")
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.selectHandle = this.selectHandle.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    this.props.getRevenueLineItems()
  }
  handleChange(event) {
    invobj[event.target.name] = event.target.value;
    this.setState({ formData: invobj });
  }

  on = {
    date: name => d => {
      this.setState({ [name]: this.formatDate(d) })
    },
  }
  formatDate = date => typeof date == 'string'
    ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, "$1-$2-$3"))
    : date

  selectHandle(name, data) {
    if (!_.isEmpty(data)) {
      invobj[name] = data[0].value;
      this.setState({ formData: invobj });
    }

  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.invoice)) {
      this.setState({ invoice: nextProps.invoice })
    }

  }

  handleFileChange(event) {
    invobj[event.target.name] = event.target.files[0];
    this.setState({ formData: invobj });
  }

  handleSubmit(event) {
    event.preventDefault()
    this.props.saveReport(this, this.state.formData, this.state.date_start, this.state.date_end)
  }

  validateForm () {}

  render () {
    const { location } = this.state.invoice
    const { state, on } = this
    const { date_start, date_end } = state

    let countryData = [
      { value: 'agency', label: 'Agency' },
      { value: 'credit', label: 'Credit' },
      { value: 'omni', label: 'Omni' },
      { value: 'invoices', label: 'Invoices' }
    ];

    return (
      <React.Fragment>
        <div className="content">
          <div className="page-inner">
            <div className="page-header">
              <h4 className="page-title">Form Report</h4>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Report</div>
                  </div>
                  <ValidatorForm
                    ref="form"
                    method="post"
                    onSubmit={(e) => this.handleSubmit(e)}
                  >

                    <div className="card-body">

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Type <span className="required-label">*</span></label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <SelectField
                            onChange={(values) => this.selectHandle('location', values)}
                            name="location"
                            options={countryData}
                            value={preValue(this.state.formData.location, location)}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            className="form-control"

                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">From <span className="required-label">*</span></label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <DateTimeField
                            name='date_start'
                            onChange={on.date('date_start')}
                            selected={date_start}
                            value={preValue(this.state.formData.date_start, date_start)}
                            validators={['required']}
                            errorMessages={['Required']}
                            dateFormat='do MMMM, yyyy'
                            dropdownMode='select'
                            maxDate={date_end}
                          />
                        </div>
                      </div>


                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">To <span className="required-label">*</span></label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <DateTimeField
                            name='date_end'
                            onChange={on.date('date_end')}
                            selected={date_end}
                            value={date_end}
                            validators={['required']}
                            errorMessages={['Required']}
                            dateFormat='do MMMM, yyyy'
                            dropdownMode='select'
                            minDate={date_start}
                            maxDate={new Date()}
                          />
                        </div>
                      </div>

                    </div>

                    <div className="card-action">
                      <div className="row">
                        <div className="col-md-12">
                          <input className="btn btn-success mr-1" type="submit" value="Submit" />
                          <button className="btn btn-danger" onClick={() => this.props.history.push('/report')}>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export default withRouter(
  connect(state => ({
    error: state.transactions.error,
    invoice: state.invoices.invoice,
    lineitems: state.lineitems.revenue_line_items || []
  }), {
    saveReport,
    getRevenueLineItems
  }
  )(InvoiceForm));

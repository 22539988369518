import * as actionTypes from '@action-types'

const initial = {
  authenticated   : false,
  loading         : false,
  success         : false,
  error           : null,
  success_message : null,
  transactions    : [],
  transaction     : {}
}

export default (state = initial , { type, payload }) => {
  switch (type) {
    case actionTypes.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.GET_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error || payload.response.data.response,
        success_message: null
      }
    case actionTypes.GET_TRANSACTIONS:
      return {
        ...state,
        loading: false,
        error: null,
        transactions: payload.response
      }
    case actionTypes.CREATE_TRANSATION:
      return {
        ...state,
        loading: false,
        error: null,
        success_message: "Payment Successful",
        success: true,
        transaction: payload.response
      }
    case actionTypes.VOID_PARTICULAR_TRANSACTION:
      return {
        ...state,
        loading: false,
        success_message: "Transaction successfully voided",
      }
    default:
      return {
        ...state,
        loading: false
      }
  }
}

import React from 'react'
import { Provider } from 'react-redux'
import { routerMiddleware } from 'react-router-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import Main from '@router'
import reducer from '@reducers'
import history from '@router/routes'

import '@styles/atlantis.min.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@styles/demo.css'
import '@styles/responsive.css'
import '@styles/sweetalert.css'
import '@styles/form.css'

const historyMiddleware = routerMiddleware(history)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(historyMiddleware, thunk))
)

export default () => (
  <Provider store={store}>
  	<Main />
  </Provider>
)

import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { saveUser, updateUser, getUser, getRoles } from '@actions/users'
import { getCompanies } from '@actions/companies'
import { preValue, } from '@helpers/tools'
import InputField from '@shared/form-fields/input'
import SelectField from '@shared/form-fields/select'
import { ValidatorForm } from 'react-form-validator-core'
import _ from 'lodash'

export const ROUTE_PATHS = ['/users/new', '/users/edit']

let invobj = {}
var arr = []
var flag = false

// const UserForm = () => {
//   const user = useSelector(store => store.users.userData)
//   const roles = useSelector(store => store.users.rolesData)
//   const error = useSelector(store => store.transactions.error)
//   const companies = useSelector(store => store.companies.companies)
//   const dispatch = useDispatch()

//   useEffect(() => {

//   }, [])

// }

class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null,
      formData: {
        roles: []
      },
      userObj: {},
      roles: [],
      passwordError: false,
      user: JSON.parse(sessionStorage.user),
      error: undefined,
      list_id: this.props.location.search.replace('?id=', "")
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.selectHandle = this.selectHandle.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.handleMultiChange = this.handleMultiChange.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  componentDidMount() {
    this.props.getCompanies()
    this.props.getRoles()
    if (this.state.list_id)
      this.props.getUser(this.state.list_id)
  }
  handleChange(event) {

    invobj[event.target.name] = event.target.value
    this.setState({ formData: invobj })
  }
  handleCheckbox(e) {
    console.log("ev", e.target.checked, e.target.name, this.state)
    if (e.target.name == 'agency_cashier') {
      if (e.target.checked == false) {
        const index = arr.indexOf(3)
        if (index > -1) {
          arr.splice(index, 1)
        }
      } else {
        arr.push(3)
      }
    }
    else if (e.target.name == 'agency_accountant') {
      if (e.target.checked == false) {
        const index = arr.indexOf(4)
        if (index > -1) {
          arr.splice(index, 1)
        }
      } else {
        arr.push(4)
      }
    }
    else if (e.target.name == 'technology') {
      if (e.target.checked == false) {
        const index = arr.indexOf(9)
        if (index > -1) {
          arr.splice(index, 1)
        }
      } else {
        arr.push(9)
      }
    }
    else if (e.target.name == 'inactive') {
      if (e.target.checked == false) {
        const index = arr.indexOf(10)
        if (index > -1) {
          arr.splice(index, 1)
        }
      } else {
        arr.push(10)
      }
    }
    // invobj[event.target.name] = event.target.value
    // invobj[e.target.name] = e.target.checked
    console.log("role arr == ", arr)
    if (e.target.checked == false) {
      const index = arr.indexOf()
      if (index > -1) {
        arr.splice(index, 1)
      }
    }

    invobj['role_ids'] = arr

    this.setState({ formData: invobj })

    // this.setState({formData :})
  }

  selectHandle(name, data) {
    if (!_.isEmpty(data)) {
      invobj[name] = data[0].value
      this.setState({ formData: invobj })
    }

  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption })
    console.log(`Option selected:`, selectedOption)
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.userObj)) {
      this.setState({ userObj: nextProps.userObj })
    }

  }

  handleFileChange(event) {
    invobj[event.target.name] = event.target.files[0]
    this.setState({ formData: invobj })
  }



  handleSubmit(event) {

    event.preventDefault()

    if (this.state.formData.password == this.state.formData.password_confirmation) {
      this.setState(state => {
        return {
          passwordError: false
        }
      })

      if (_.isEmpty(this.state.userObj)) {
        // this.state.roles.forEach(u =>{
        // console.log('event',event.target.value,this.state.formData)
        // this.state.formData.role_ids.push(u)
        // })
        this.props.saveUser(this, this.state.formData)
      }
      else {
        // this.state.roles.forEach(u =>{
        // 	this.state.formData.role_ids.push(u)
        // })
        invobj['role_ids'] = arr
        this.setState({ formData: invobj })
        console.log("this.", this.state.formData)
        this.props.updateUser(this, this.state.list_id, this.state.formData)
      }
    } else {
      this.setState(state => {
        return {
          passwordError: true
        }
      })
    }

  }

  validateForm () {}

  handleMultiChange(option) {
    option = option.map((op) => { return op.value })
    this.setState(state => {
      return {
        roles: option
      }
    })
  }

  render() {
    let { companies } = this.props
    let { userObj: { email, password_confirmation, password, company_id, first_name, last_name } } = this.state
    let comp = company_id && company_id.id ? { value: company_id.id, label: company_id.name_of_company } : ''
    let technologyCheck = false
    let agencyCashierCheck = false
    let inactiveCheck = false
    let agencyRevenueCheck = false

    let countryData = companies.map((item) => ({ value: item.id, label: item.name_of_company }))
    if (this.state.userObj && this.state.userObj.roles && this.state.userObj.roles.length > 0) {
      for (let item of this.state.usersObj.roles) {
        if (item.name == "technology") {
          technologyCheck = true
        } else if (item.name == "inactive") {
          inactiveCheck = true
        } else if (item.name == "agency_cashier") {
          agencyCashierCheck = true
        } else if (item.name == "agency_accountant") {
          agencyRevenueCheck = true
        }
      }
    }
    if (this.state.userObj && this.state.userObj.roles && flag == false) {
      for (let role of this.state.userObj.roles) {
        arr.push(role.id)
      }
      flag = true
    }
    return (
      <React.Fragment>
        {((this.state.userObj && this.state.userObj.roles) || !this.state.list_id) ? (
          <div className="content">
            <div className="page-inner">
              <div className="page-header">
                <h4 className="page-title">Form User</h4>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">User</div>
                    </div>
                    <ValidatorForm
                      ref="form"
                      method="post"
                      onSubmit={(e) => this.handleSubmit(e)}
                    >

                      <div className="card-body">
                        {(!this.state.list_id) && (
                          <div className="form-group form-show-validation row">
                            <label htmlFor="email" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Email <span className="required-label">*</span></label>
                            <div className="col-lg-4 col-md-9 col-sm-8">
                              <InputField
                                onChange={this.handleChange}
                                name="email"
                                value={preValue(this.state.formData.email, email)}
                                validators={['required', 'isEmail']}
                                errorMessages={['This field is required']}
                                className="form-control"
                                placeholder="Email"
                                type="email"
                              />
                            </div>
                          </div>
                        )}
                        <div className="form-group form-show-validation row">
                          <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">First Name <span className="required-label">*</span></label>
                          <div className="col-lg-4 col-md-9 col-sm-8">
                            <InputField
                              onChange={this.handleChange}
                              name="first_name"
                              value={preValue(this.state.formData.first_name, first_name)}
                              validators={['required']}
                              errorMessages={['This field is required']}
                              className="form-control"
                              placeholder="First Name"
                            />
                          </div>
                        </div>

                        <div className="form-group form-show-validation row">
                          <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Last Name <span className="required-label">*</span></label>
                          <div className="col-lg-4 col-md-9 col-sm-8">
                            <InputField
                              onChange={this.handleChange}
                              name="last_name"
                              value={preValue(this.state.formData.last_name, last_name)}
                              validators={['required']}
                              errorMessages={['This field is required']}
                              className="form-control"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>

                        <div className="form-group form-show-validation row">
                          <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Company <span className="required-label">*</span></label>
                          <div className="col-lg-4 col-md-9 col-sm-8">
                            <SelectField
                              onChange={(values) => this.selectHandle('company_id', values)}
                              name="company_id"
                              options={countryData}
                              value={preValue(this.state.formData.company_id, comp)}
                              validators={['required']}
                              errorMessages={['This field is required']}
                              className="form-control"

                            />
                          </div>
                        </div>


                        <div className="form-group form-show-validation row">
                          <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Cashier </label>
                          <div className="col-lg-4 col-md-9 col-sm-8">

                            <InputField
                              name="agency_cashier"
                              className="form-control"
                              defaultChecked={agencyCashierCheck}
                              placeholder="Email"
                              onClick={this.handleCheckbox}
                              type="checkbox"
                            />
                          </div>
                        </div>
                        <div className="form-group form-show-validation row">
                          <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Accountant </label>
                          <div className="col-lg-4 col-md-9 col-sm-8">

                            <InputField
                              name="agency_accountant"
                              defaultChecked={agencyRevenueCheck}
                              className="form-control"
                              placeholder="Email"
                              onClick={this.handleCheckbox}
                              type="checkbox"
                            />
                          </div>
                        </div>
                        <div className="form-group form-show-validation row">
                          <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Technology </label>
                          <div className="col-lg-4 col-md-9 col-sm-8">

                            <InputField
                              name="technology"
                              defaultChecked={technologyCheck}
                              className="form-control"
                              placeholder="Email"
                              onClick={this.handleCheckbox}
                              type="checkbox"
                            />
                          </div>
                        </div>
                        <div className="form-group form-show-validation row">
                          <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Inactive </label>
                          <div className="col-lg-4 col-md-9 col-sm-8">

                            <InputField
                              name="inactive"
                              defaultChecked={inactiveCheck}
                              className="form-control"
                              onClick={this.handleCheckbox}
                              placeholder="Email"
                              type="checkbox"
                            />
                          </div>
                        </div>

                        <div className="form-group form-show-validation row">
                          <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Password {!this.state.list_id ? <span className="required-label">*</span> : ''}</label>
                          <div className="col-lg-4 col-md-9 col-sm-8">
                            <InputField
                              onChange={this.handleChange}
                              name="password"
                              value={preValue(this.state.formData.password, password)}
                              validators={this.state.list_id ? [] : ['required']}
                              errorMessages={['This field is required']}
                              className="form-control"
                              placeholder="Password"
                              type="password"
                            />
                          </div>
                        </div>

                        <div className="form-group form-show-validation row">
                          <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Confirm Password {!this.state.list_id ? <span className="required-label">*</span> : ''}</label>
                          <div className="col-lg-4 col-md-9 col-sm-8">
                            <InputField
                              onChange={this.handleChange}
                              name="password_confirmation"
                              value={preValue(this.state.formData.password_confirmation, password_confirmation)}
                              validators={this.state.list_id ? [] : ['required']}
                              errorMessages={['This field is required']}
                              className="form-control"
                              placeholder="Confirm Password"
                              type="password"
                            />
                          </div>
                        </div>
                        {this.state.passwordError ? <span className="required-label">Password and Confirm password should be same</span> : ''}
                      </div>
                      <div className="card-action">
                        <div className="row">
                          <div className="col-md-12">
                            <input className="btn btn-success mr-1" type="submit" value="Submit" />
                            <button className="btn btn-danger" onClick={() => this.props.history.push('/users')}>Cancel</button>
                          </div>
                        </div>
                      </div>
                    </ValidatorForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : '<div></div>'}
      </React.Fragment>

    )
  }
}


function mapStateToProps(state) {
  return {
    error: state.transactions.error,
    companies: state.companies.companies || [],
    roles: state.users.rolesData || [],
    userObj: state.users.userData
  }
}


export default withRouter(connect(mapStateToProps, {
  saveUser, updateUser, getUser, getRoles, getCompanies
})(UserForm))
import React from 'react'
import '@styles/loader.css'

const Loader = props => props.loading ? (
  <div className="loader-container">
    <div className='animate-spin'>
      <i className='fas fa-circle-notch'/>
    </div>
  </div>
) : null

export default Loader
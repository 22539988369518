import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import EmptyTable from "@shared/empty-table";
import Loader from "@shared/loader";
import { downloadInvoices } from "@actions/invoices";
import { downloadTransactions } from "@actions/transactions";

import { getData } from "@actions/search";
import appConstants from "@constants/constants";
import InvoiceTable from "./_invoice.table";

class Search extends React.Component {
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const query = {
      query_type: params.get("query_type"),
      query: params.get("query"),
      search_target: "invoice",
    };

    if (!query.query_type || !query.query) {
      this.props.history.push("/search");
    }

    this.props.getData(query);
  }

  render() {
    const { loading, data } = this.props;

    const table =
      !loading && data && data.length ? (
        <InvoiceTable
          {...this.state}
          data={data}
          downloadInvoices={this.props.downloadInvoices}
        />
      ) : (
        <EmptyTable columns={appConstants.INVOICES_COLUMNS} />
      );

    return (
      <React.Fragment>
        <Loader loading={loading} />

        <div className="content">
          <div className="page-inner">
            <div className="page-header"></div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4 className="card-title">Results</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">{table}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ search }) => ({
  data: search.data,
  error: search.error,
  success_message: search.success_message,
  success: search.success,
  search_target: search.search_target,
  loading: search.loading,
});

export default withRouter(
  connect(mapStateToProps, {
    getData,
    downloadTransactions,
    downloadInvoices,
  })(Search)
);

import * as actionTypes from '@action-types'
import axios from 'axios'

function headers () {
  let user = sessionStorage.getItem("user")
  user = JSON.parse(user)

  return {
    'Content-Type'  : 'application/json',
    'Authorization' : user.auth_token
  }
}

const successResponse = (action) => (response) => ({
  type: actionTypes[action],
  payload: response ? { response } : {}
})

export const getRequest = successResponse('GET_REQUEST_SUCCESS')
export const getAllRevenueSuccess   = successResponse('GET_ALL_REVENUE')
export const saveCashPaymentSuccess = successResponse('CREATE_REPLAY_CASH_PAYMENT')
export const savePayeeSuccess       = successResponse('GET_REQUEST_SUCCESS')
export const updateLineItemSuccess  = successResponse('UPDATE_TRANSATION')

export const createError = (error) => ({
  type: actionTypes.GET_RESPONSE_ERROR,
  payload: error
})

export function saveReplayEmail (data, cb) {
  return function (dispatch) {
    dispatch(getRequest())

    delete data.invoice_number

    axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/replay/email`, data, { headers: headers() })
      .then(response => {
        if (response.data.status === 400) {
          dispatch({
            type: actionTypes.CUSTOM_ERROR,
            payload: {
              response: response.data.error,
            }
          })
          typeof cb == 'function' && cb(false)
        } else {
          dispatch(saveCashPaymentSuccess(response.data))
          typeof cb == 'function' && cb(true)
        }
      }).catch(error => {
        dispatch(createError(error))
      })
  }
}
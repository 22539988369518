
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'

import CompaniesTable from './_table'

import EmptyTable from '@shared/empty-table'
import { getCompanies } from '@actions/companies'
import ENV from '@constants/constants'

export const ROUTE_OPEN = true

class Companies extends React.Component {
  state = {
    data: [],
  }

  componentDidMount () {
    this.props.getCompanies()
    this.roles = JSON.parse(sessionStorage.getItem('user'))
      .roles.map(r => r.name)
  }

  render = () => (
    <div className="content">
      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">Companies</h4>
                  {this.roles?.includes('technology') || true ? (
                    <Link
                      to="/companies/new"
                      className="btn btn-primary btn-round ml-auto"
                    >
                      <i className="fa fa-plus"></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;Add Company
                    </Link>
                  ) : null}
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {_.isEmpty(this.props.data)
                    ? <EmptyTable columns={ENV.REVENUE_LINE_ITEMS_COLUMNS} />
                    : (
                      <CompaniesTable
                        data={this.props.data}
                        removeFarmerList={this.removeData}
                      />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(
  connect(
    state => ({
      user: state.users,
      data: state.companies.companies,
      error: state.lineitems.error,
    }),
    { getCompanies }
  )(Companies)
)

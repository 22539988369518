import * as actionTypes from '@action-types'

const initialState = {
  authenticated: false,
  loading: false,
  data: [], 
  success_message: undefined,
  success: false,
  error: undefined ,
  rm_loading: false
}

const users = (state=initialState,action) => {

  switch(action.type){

    case actionTypes.GET_ALL_USERS:
      return{...state,loading: false, usersData:  action.payload.response }
    case actionTypes.GET_RESPONSE_ERROR:
      return{...state,rm_loading:false, loading: false, error: action.payload.response && action.payload.response.data.error} 
    case actionTypes.GET_USER_DATA:
      return{...state, loading: false, userData: action.payload.response, success_message: undefined, error: undefined }
    case actionTypes.GET_ROLES_DATA:
      return{...state, loading: false, rolesData: action.payload.response.roles, success_message: undefined, error: undefined }
    case actionTypes.GET_USERS_DATA:
    	return{...state, loading: false, userData: action.payload.response.companies, success_message: undefined, error: undefined }
    case actionTypes.CREATE_USER:
      return {...state,loading: false, error: undefined,success_message: "User successfully created", success: true, company: action.payload.response};
    case actionTypes.UPDATE_USER:
      return {...state,loading: false, error: undefined,success_message: "User successfully updated", success: true, company: action.payload.response};
    case actionTypes.UPDATE_USER_PROFILE:
      return{...state, loading: false,success: true,success_message: "Successfully updated", userData: action.payload.response.user }  
    case actionTypes.LOGIN_SUCCESS:
      return {...state, loading: false,success: true,success_message: "Successfully logged In", currentUser: action.payload.response}
    case actionTypes.CLEAR_ERROR:
      return {...state, loading: false,success: false,success_message: undefined, error: undefined}
    case actionTypes.FORGET_PASSWORD_SUCCESS:
       return {...state, loading: false,success: true,success_message: "Password reset link has been sent please check your mail"}
    case actionTypes.RESET_PASSWORD_SUCCESS:
       return {...state, loading: false,success: true,success_message: "Password has been updated"}
    case actionTypes.GET_RM_REQUEST:
      return {...state, rm_loading: true}
    case actionTypes.STOP_LOADER:
      return {...state, rm_loading: false, loading: false}
    case actionTypes.REMEMBER_TOKEN_SUCCESS:
      return {...state,rm_loading:false, loading: false,success: true,success_message: "Welcome back", currentUser: action.payload.response.user}
    default: 
      return {...state, loading: false} 
  }  
}

export default users


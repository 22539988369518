import React from 'react';
import { Link } from 'react-router-dom'
import appConstants from '@constants/constants'
import $ from 'jquery'
import '../../assets/css/pagination.css'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

$.DataTable = require('datatables.net')

class CompanyTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: []
		};
		this.addActionData = this.addActionData.bind(this);
		this.viewData = this.viewData.bind(this)
		this.removeData = this.removeData.bind(this)
	}
	componentDidMount() {
		this.$el = $(this.el)
		this.$el.DataTable({
			language: {
				paginate: {
					next: '<span className="p-next">next</span>',
					previous: '<span className="p-previous">previous</span>'
				}
			}
		});
	}

	removeData(id) {

		confirmAlert({
			title: '',
			message: 'Please reach out to support@velapay.com.',
			buttons: [
				{
					label: 'Ok',
					onClick: () => { }
				}

			]
		})

		//this.props.removeCompany(id)
	}

	addActionData = item => (
			<div>
				<Link to={`ministries/edit?id=${item.id}`} className="btn btn-link btn-primary btn-lg" >
					<i className="fa fa-edit"></i>
				</Link>
				<button type="button" onClick={(e) => { this.removeData(e.target.dataset.id) }} data-toggle="tooltip" title="" className="btn btn-link btn-danger" data-original-title="Remove">
					<i className="fa fa-times" data-id={item.id}></i>
				</button>
			</div>
		)

	viewData(data) {

		let list = data.map((company, index) =>
			<tr key={index}>
				<td>{company.id}</td>
				<td>{company.name_of_company}</td>
				<td>{company.island}</td>
				<td>{company.merchant_token_id}</td>
				<td>{this.addActionData(company)}</td>
			</tr>)
		return list
	}

	render() {

		let { data } = this.props
		return (<table id="basic-datatables" className="display table table-striped table-hover" ref={el => this.el = el} >
			<thead>
				<tr>
					{appConstants.COMPANIES_COLUMNS.map((item) => <th>{item.title}</th>)}
				</tr>
			</thead>
			<tfoot>
				<tr>
					{appConstants.COMPANIES_COLUMNS.map((item) => <th>{item.title}</th>)}
				</tr>
			</tfoot>
			<tbody>
				{this.viewData(data)}

			</tbody>
		</table>)
	}

}
export default (CompanyTable)



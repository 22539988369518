import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";

import EmptyTable from "@shared/empty-table";
import Loader from "@shared/loader";
import InputField from "@shared/form-fields/input";
import SelectField from "@shared/form-fields/select";
import { downloadInvoices } from "@actions/invoices";
import { downloadTransactions } from "@actions/transactions";

import { getData } from "@actions/search";
import appConstants from "@constants/constants";
import InvoiceTable from "./_invoice.table";
import TransactionTable from "./_transaction.table";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: {
        query_type: "",
        query: "",
        search_target: "",
      },
    };
  }

  componentDidMount() {
    // this.props.getInvoices(this.state.date_start, this.state.date_end);
    // this.props.startRequest();
  }

  componentWillReceiveProps({ success }) {
    // success && this.setState({ isOpen: false });
  }

  // componentDidCatch (error, info) {
  // 	window.location.reload()
  // }

  handleFormState = (event) => {
    this.setState({
      formState: {
        ...this.state.formState,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSelectFields = (field, value) => {
    let val = "";
    if (value) {
      val = value.value;
    }
    this.setState({
      formState: {
        ...this.state.formState,
        [field]: val,
      },
    });
  };

  submitHandler = () => {
    this.props.getData({ ...this.state.formState });
  };

  reqcloseModal = () => {
    this.setState({ isOpen: !this.state.isOpen, current_list: {} });
  };

  removeData = (id) => {
    this.props.removeInvoice(this, id);
  };

  render() {
    const { loading, data, search_target } = this.props;
    const styles = {
      form: {
        display: "block",
        alignItems: "center",

        width: "100%",
        marginRight: "1.5rem",
      },
      label: {
        margin: 0,
      },
      select: {
        height: "calc(1.5em + .75rem + 2px)",
      },
    };

    const search_types = [
      {
        value: "invoice_reference",
        label: "Invoice Reference",
      },
      {
        value: "last_name",
        label: "Last Name",
      },
    ];

    const target_types = [
      {
        value: "invoice",
        label: "Invoice",
      },
      {
        value: "transaction",
        label: "Receipt",
      },
    ];

    const filters = (
      <ValidatorForm style={styles.form} onSubmit={this.submitHandler}>
        <div className="row">
          <div className="col-md-3 form-group">
            <label htmlFor="query_type" style={styles.label}>
              &nbsp;&nbsp;&nbsp;&nbsp;Search Type&nbsp;&nbsp;
            </label>

            <SelectField
              onChange={(values) => this.handleSelectFields("type", values[0])}
              name="type"
              options={search_types}
              value={this.state.formState.type}
              validators={["required"]}
              errorMessages={["This field is required"]}
              className="form-control"
            />
          </div>
          <div className={"col-md-3 form-group"}>
            <label htmlFor="search" style={styles.label}>
              Search&nbsp;&nbsp;
            </label>
            <InputField
              name="query"
              onChange={(event) => this.handleFormState(event)}
              style={{ height: `calc(1.5em + .75rem + 2px)` }}
              value={this.state.formState.query}
              className={"form-control"}
              placeholder={"Invoice Reference or Last Name"}
              validators={["required"]}
              errorMessages={["Required"]}
            />
          </div>
          <div className={"col-md-3 form-group"}>
            <label htmlFor="search_target" style={styles.label}>
              &nbsp;&nbsp;&nbsp;&nbsp;Type&nbsp;&nbsp;
            </label>

            <SelectField
              onChange={(values) =>
                this.handleSelectFields("search_target", values[0])
              }
              name="search_taget"
              options={target_types}
              value={this.state.formState.search_target}
              validators={["required"]}
              errorMessages={["This field is required"]}
              className="form-control"
            />
          </div>

          <div
            className={"col-md-3 form-group "}
            style={{ paddingTop: "2.2em" }}
          >
            <InputField
              name="submit"
              type="submit"
              className={"btn btn-primary  "}
              value="Submit"
            />
          </div>
        </div>
      </ValidatorForm>
    );

    const table =
      !loading && data && data.length ? (
        search_target == "transaction" ? (
          <TransactionTable
            {...this.state}
            data={data}
            downloadTransactions={this.props.downloadTransactions}
          />
        ) : (
          <InvoiceTable
            {...this.state}
            data={data}
            downloadInvoices={this.props.downloadInvoices}
          />
        )
      ) : (
        <EmptyTable
          columns={
            search_target == "transaction"
              ? appConstants.TRANSACTIONS_COLUMNS
              : appConstants.INVOICES_COLUMNS
          }
        />
      );

    return (
      <React.Fragment>
        <Loader loading={loading} />

        <div className="content">
          <div className="page-inner">
            <div className="page-header">
              {/*<h4 className="page-title">Invoice table</h4>*/}
              {/*<ul className="breadcrumbs">
									<li className="nav-home">
										<a href="#">
											<i className="flaticon-home"></i>
										</a>
									</li>
									<li className="separator">
										<i className="flaticon-right-arrow"></i>
									</li>
									<li className="nav-item">
										<a href="#">Tables</a>
									</li>
									<li className="separator">
										<i className="flaticon-right-arrow"></i>
									</li>
									<li className="nav-item">
										<a href="#">Datatables</a>
									</li>
								</ul> */}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4 className="card-title">Search</h4>
                    </div>
                  </div>
                  <div className="card-body">{filters}</div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4 className="card-title">Results</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">{table}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ search }) => ({
  data: search.data,
  error: search.error,
  success_message: search.success_message,
  success: search.success,
  search_target: search.search_target,
  loading: search.loading,
});

export default withRouter(
  connect(mapStateToProps, {
    getData,
    downloadTransactions,
    downloadInvoices,
  })(Search)
);

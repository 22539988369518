import * as actionTypes from '@action-types'

const initial = {
  authenticated: false,
  loading: false,
  success: false,
  success_message: null,
  error: null,
  data: [],
}

export default (state = initial, { type, payload}) => {
  switch (type) {
    case actionTypes.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.GET_INVOICES:
      return {
        ...state,
        loading: false,
        error: null,
        invoices: payload.response
      }
    case actionTypes.CREATE_INVOICE:
      return {
        ...state,
        loading: false,
        error: null,
        success_message: "Successfully created invoice",
        success: true,
        invoice: payload.response
      }
    case actionTypes.GET_PARTICULAR_INVOICE:
      return {
        ...state,
        loading: false,
        error: null,
        invoice: payload.response.invoice
      }
      case actionTypes.GET_PARTICULAR_LOCATION:
      return {
        ...state,
        loading: false,
        error: null,
        places: payload.response
      }
    case actionTypes.UPDATE_INVOICE:
      return {
        ...state,
        loading: false,
        error: null,
        success_message: "successfully update invoice",
        success: true,
        invoice: payload.response
      }
    case actionTypes.REMOVE_INVOICE:
      return {
        ...state,
        loading: false,
        success: true,
        success_message: "Successfully removed",
        invoices: payload.obj.state.data.filter((item) => item.id != payload.response.id)
      }
    default:
      return {
        ...state,
        loading: false,
        success_message: null,
        success: false
      }
  }
}

import React from "react";
import $ from "jquery";
import useCSVDownloader from "use-csv-downloader";
import { confirmAlert } from "react-confirm-alert";

import appConstants from "@constants/constants";
import { dateTimeOnly } from "@helpers/tools";

import "react-confirm-alert/src/react-confirm-alert.css";
import "../../assets/css/pagination.css";

$.DataTable = require("datatables.net");
const downloadCSV = useCSVDownloader({});

export default class Table extends React.Component {
  constructor(props) {
    super(props);
    this.table = React.createRef();
    this.search = null;
  }

  componentDidMount() {
    this.datatable.init();
  }

  componentWillUnmount() {
    this.datatable.destroy();
  }

  datatable = {
    init: () => {
      try {
        this.__datatable = $(this.table.current).DataTable({
          order: [[3, "desc"]],
          language: {
            paginate: {
              next: '<span className="p-next">next</span>',
              previous: '<span className="p-previous">previous</span>',
            },
          },
        });

        setTimeout(() => {
          this.search = document.querySelector(
            'input[aria-controls="basic-datatables"]'
          );
        }, 500);
      } catch (err) {
        console.error(err);
      }
    },

    destroy: () => {
      this.__datatable && this.__datatable.destroy();
    },

    reset: () => {
      this.datatable.destroy();
      this.datatable.init();
    },
  };

  downloadData(url) {
    this.props.downloadInvoices(url);
  }

  removeData = (id) => {
    confirmAlert({
      title: "",
      message: "Please reach out to support@velapay.com.",
      buttons: [
        {
          label: "Ok",
          onClick: () => {},
        },
      ],
    });

    // this.props.removeCompany(id)
  };

  convertDate = date => new Date(date).toLocaleString()

  generateCSV = () => {
    const { data } = this.props;
    const { search } = this;
    const { INVOICES_HEADERS } = appConstants;

    let rows = null;
    if (search.value) {
      rows = this.__datatable.context[0].aiDisplay.map((i) => data[i]);
      // const master = this.__datatable.data().map(d => data.find(r => r.reference_id == d[0]))
      // rows = this.__datatable.context[0].aiDisplay.map(i => master[i])
    }

    const csv = (rows || data.reverse())
      .map((row) => ({
        ...row,
        created_at: dateTimeOnly(row.created_at),
        long_desc: row.revenue_line_item && row.revenue_line_item.long_desc,
      }))
      .map((row) =>
        INVOICES_HEADERS.reduce(
          (o, { label, key }) => ({
            ...o,
            [label]: key == "void" ? (row[key] ? "YES" : "NO") : row[key],
          }),
          {}
        )
      );

    downloadCSV(csv, `invoices-${Date.now()}.csv`);
  };

  checkmark = val => {
    const classes = !!val
      ? 'fas fa-check text-green-600'
      : 'fas fa-times text-red-600'

    return <div className='text-center'><i className={classes} /></div>
  }

  tableRows = data => data.map((invoice, index) => (
    <tr key={index}>
      <td>{invoice.invoice_reference}</td>
      <td>{invoice.reference_code}</td>
      <td>{invoice.location}</td>
      <td>{this.convertDate(invoice.created_at)}</td>
      <td>
        {invoice.revenue_line_item && invoice.revenue_line_item.long_desc}
      </td>
      <td>{invoice.email}</td>
      <td>{invoice.first_name}</td>
      <td>{invoice.last_name}</td>
      <td>${invoice.amount && parseFloat(invoice.amount).toFixed(2)}</td>
      <td>{this.checkmark(invoice.status == 'paid')}</td>
      <td>{invoice.void && this.checkmark(invoice.void)}</td>
      <td>{invoice.mobile_number}</td>
      <td>{invoice.notes}</td>
      <td>
        <button
          type="button"
          onClick={(e) => {
            this.downloadData(invoice.invoice);
          }}
          className="btn btn-link"
          data-original-title="Remove"
          data-action
        >
          <i className="fa fa-download"></i>
        </button>
      </td>
    </tr>
  ))

  render() {
    const headings = appConstants.INVOICES_COLUMNS.map((h, i) => (
      <th key={i}>{h.title}</th>
    ));

    return (
      <React.Fragment>
        <button
          className="btn btn-sm btn-primary"
          onClick={this.generateCSV}
          style={{ marginBottom: "1.5rem" }}
        >
          <i className='fas fa-download'/>
          &nbsp;&nbsp;&nbsp;&nbsp;Download CSV
        </button>

        <table
          id="basic-datatables"
          className="display table table-striped table-hover"
          ref={this.table}
        >
          <thead>
            <tr>{headings}</tr>
          </thead>
          <tfoot>
            <tr>{headings}</tr>
          </tfoot>
          <tbody>{this.tableRows(this.props.data)}</tbody>
        </table>
      </React.Fragment>
    );
  }
}

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import EmptyTable from '@shared/empty-table'
import Loader from '@shared/loader'
import { getReports, voidTransaction, downloadReports } from '@actions/reports'
import appConstants from '@constants/constants'
import ReportTable from './_table'

class Report extends React.Component {
  constructor (props) {
    super(props)

    const today = new Date()

    this.state = {
      isOpen: false,
      current_list: {},
      current_transaction: {},
      date_start: today,
      date_end: today,
      nonce: 0,
    }
  }

  componentDidMount () {
    this.props.getReports()
  }

  componentWillReceiveProps ({ success }) {
    success && this.setState({ isOpen: false })
  }

  on = {
    date: name => d => {
      this.setState({ [name]: this.formatDate(d) }, () => {
        this.props.getReports()
      })
    },
  }

  formatDate = date => typeof date == 'string'
    ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/,"$1-$2-$3"))
    : date

  handleAction = (id, action) => {
    if (action == 'edit') {
      const current_list = this.state.lists.find(l => l.id === id)
      this.setState({ isOpen: true, current_list: current_list })
    }
  }

  reqcloseModal = () => {
    this.setState({ isOpen: !this.state.isOpen, current_list: {} })
  }

  showTransaction = current_transaction => {
    this.setState({ current_transaction })
  }

  render () {
    const { state, props, showTransaction } = this
    const { loading, data } = props

    const table = !loading && data && data.length ? (
      <ReportTable
        {...state}
        data={data}
        showTransaction={showTransaction}
        voidTransaction={props.voidTransaction}
        downloadReports={props.downloadReports}
      />
    ) : <EmptyTable columns={appConstants.REPORT_COLUMNS} />

    return (
      <React.Fragment>
        <Loader loading={this.props.loading}/>

        <div className="content">
          <div className="page-inner">
            <div className="page-header"></div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="card-title">Reports</h4>
                      
                        <a className="btn btn-primary btn-round ml-auto" href="/report/generate" >
                          <i className="fa fa-plus"></i>
                          Generate Report
                        </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      {table}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ transactions, reports }) => ({
  data: transactions.transactions,
  error: transactions.error,
  success: transactions.success,
  loading: transactions.loading,
  success_message: transactions.success_message || reports.success_message,
})

export default withRouter(
  connect(
    mapStateToProps,
    { getReports, downloadReports, voidTransaction }
  )(Report)
)

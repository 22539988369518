import React from 'react'
import { Router } from 'react-router-dom'
import { connect } from 'react-redux'
import cookie from 'react-cookies'
import { createBrowserHistory } from 'history'

import AppRouter from './routes'
import MyAlert from '@shared/alert'
import Loader from '@shared/loader'
import Header from '@screens/_partials/header'
import Footer from '@screens/_partials/footer'
import { listKeys } from '@helpers/extract-keys'

import { rememberToken } from '@actions/users'

export const history = createBrowserHistory()

class Main extends React.Component {
  state = {
    toggled: false,
    topToggle: false
  }
  page = document.documentElement
  
  componentWillMount () {
    const rememberObj = cookie.load('rememberFinanceObj') || {}

    if (!sessionStorage.user && rememberObj?.remember_token) {
      this.props.rememberToken(rememberObj.remember_token)
    }
  }


  togglePanel = () => {
    const next = !this.state.toggled
    this.setState({ toggled: next })
    this.page.classList.toggle('nav_open', next)
  }

  openTopBar = () => {
    const next = !this.state.topToggle
    this.setState({ topToggle: next })
    this.page.classList.toggle('topbar_open', next)
  }

  render () {
    const { state, props, togglePanel, openTopBar } = this

    const alert_key = listKeys(props, 'error', 'success_message').join(':')

    global.RG = {
      currentUser: sessionStorage.user && JSON.parse(sessionStorage.user)
    }

    if (props.rm_loading) return <Loader loading={true} />

    const component = global.RG.currentUser ? (
      <div className="wrapper horizontal-layout-2">
        <Header />
        <div className="main-panel">
          <AppRouter />
        </div>
        <Footer />
      </div>
    ) : <AppRouter />

    return (
      <React.Fragment>
        <MyAlert key={alert_key} {...props} />
        <Router history={history}>
          {component}
        </Router>
      </React.Fragment>
    )
  }
}

const any = (state, prop) => Object.keys(state)
  .map(sub => state[sub][prop])
  .find(str => !!str)

export default connect(state => ({
  rm_loading      : state.users.rm_loading,
  error           : any(state, 'error'),
  success_message : any(state, 'success_message'),
}), { rememberToken }
)(Main)

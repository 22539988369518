import * as actionTypes from '@action-types'

const initialState = {
  authenticated: false,
  loading: false,
  success: false,
  success_message: undefined,
  error: undefined,
  data: [],  
}

const heads = (state=initialState,action) => {

  switch(action.type){
    case actionTypes.GET_REQUEST_SUCCESS: 
      return {...state,loading: true, error: undefined};
    case actionTypes.GET_RESPONSE_ERROR:
      return{...state, loading: false, error: action.payload.response.data.response} 
    case actionTypes.GET_HEADS: 
      return {...state,loading: false, error: undefined,heads: action.payload.response};
    case actionTypes.CREATE_HEAD:
      return {...state,loading: false, error: undefined,success_message: "successfully payment", success: true, head: action.payload.response};
    default: 
      return {...state, loading: false} 
  }  
}

export default heads


import React, { Fragment } from 'react'

const Heading = ({ children }) => (
  <Fragment>
    <div style={{ margin: '1.5rem 0 -0.75rem'}}>{children}</div>
    <hr />
  </Fragment>
)

export default Heading

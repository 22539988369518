import * as actionTypes from '@action-types'
import axios from 'axios'
import SimpleEncrypt from '@helpers/encryption'

function headers () {
  let user = sessionStorage.getItem("user")
  user = JSON.parse(user)

  return {
    'Content-Type'  : 'application/json',
    'Authorization' : user.auth_token
  }
}

const successResponse = (action) => (response) => ({
  type: actionTypes[action],
  payload: response ? { response } : {}
})

export const getRequest = successResponse('GET_REQUEST_SUCCESS')
export const getAllRevenueSuccess   = successResponse('GET_ALL_REVENUE')
export const saveCashPaymentSuccess = successResponse('CREATE_CASH_PAYMENT')
export const savePayeeSuccess       = successResponse('GET_REQUEST_SUCCESS')
export const updateLineItemSuccess  = successResponse('UPDATE_TRANSATION')

export const createError = (error) => ({
  type: actionTypes.GET_RESPONSE_ERROR,
  payload: error
})

export function getAllRevenue () {
  return function (dispatch) {
    dispatch(getRequest())

    axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/revenue_line_items`, { headers: headers() })
      .then(response => {
        dispatch(getAllRevenueSuccess(response.data.revenue_line_items))
      }).catch(error => {
        dispatch(createError(error))
      })

  }
}

export function saveCashPayment (component, data) {
  return dispatch => {
    dispatch(getRequest())

    const invoice_number = data.invoice_number
    delete data.invoice_number

    // Trim First & Last Name
    data.first_name = data.first_name?.trim()
    data.last_name = data.last_name?.trim()

    axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/transactions/by_reference/${invoice_number}`, data, { headers: headers() })
      .then(response => {
        if (response.data.status === 400) {
          dispatch({
            type: actionTypes.CUSTOM_ERROR,
            payload: {
              response: response.data.error,
            }
          })
        } else {
          component.props.history.push('/transactions')
          dispatch(saveCashPaymentSuccess(response.data))
        }
      }).catch(error => {
        dispatch(createError(error))
      })
  }
}

export function savePayee(_that, data) {
  data["card-number"] = SimpleEncrypt.encrypt(data["card-number"])
  data["card-cvv"] = SimpleEncrypt.encrypt(data["card-cvv"])
  data["card-name"] = SimpleEncrypt.encrypt(data["card-name"])
  data["card-exp"] = SimpleEncrypt.encrypt(data["card-exp"])
  data["card-amount"] = SimpleEncrypt.encrypt(data["card-amount"])
  return function (dispatch) {
    dispatch(getRequest())
    axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/transactions`, data, { headers: headers() })
      .then(response => {
        if (response.data.status === 400) {
          dispatch({
            type: actionTypes.CUSTOM_ERROR,
            payload: {
              response: response.data.error,
            }
          }
          )
        } else {
          _that.props.history.push('/transactions')
          dispatch(saveCashPaymentSuccess(response.data))
        }
      }).catch(error => {
        dispatch(createError(error))
      })

  }
}

export function updateLineItem(_this, id, data) {
  return function (dispatch) {
    const _that = _this
    dispatch(getRequest())
    axios.patch(`${process.env.REACT_APP_API_HOST}/api/v1/line_items/${id}`, { 'head': data }, { headers: headers() })
      .then(response => {
        _that.props.history.push('/line_items')
        dispatch(updateLineItemSuccess(response.data))
      }).catch(error => {
        dispatch(createError(error))
      })

  }
}
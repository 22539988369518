import * as actionTypes from '@action-types'
import axios from 'axios';



function headers() {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  
  return {
    "Content-Type": "application/json",
    "Authorization": user.auth_token
  }
}



export function getRequest(){
	return {
		type:  actionTypes.GET_REQUEST_SUCCESS,
		payload: {}
	}
}


export function getHeadsSuccess(response){
  return {
      type: actionTypes.GET_HEADS,
      payload: {
          response,
      }
  }
}

export function getHeads() {
  return function (dispatch) {
    dispatch(getRequest())
    axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/heads`,{headers: headers()})
    .then(response => {
        dispatch(getHeadsSuccess(response.data.heads))
      }).catch(error=> {
        dispatch(createError(error))
      });

  };
}



export function getHeadSuccess(response){
  return {
      type: actionTypes.GET_PARTICULAR_HEAD,
      payload: {
          response,
      }
  }
}

export function getHead(id) {
  return function (dispatch) {
    dispatch(getRequest())
    axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/heads/${id}`,{headers: headers()})
    .then(response => {
        dispatch(getHeadSuccess(response.data))
      }).catch(error=> {
        dispatch(createError(error))
      });

  };
}



export function saveHeadSuccess(response){
  return {
      type: actionTypes.CREATE_TRANSATION,
      payload: {
          response,
      }
  }
}

export function saveHead(_this, data) {
  
  return function (dispatch) {
    const _that = _this
    dispatch(getRequest())
    axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/heads`,data)
    .then(response => {
        _that.props.history.push('/payments')
        dispatch(saveHeadSuccess(response.data))
      }).catch(error=> {
        dispatch(createError(error))
      });

  };
}

export function updateHeadSuccess(response){
  return {
      type: actionTypes.UPDATE_TRANSATION,
      payload: {
          response,
      }
  }
}

export function updateHead(_this,id,data) {
  return function (dispatch) {
    const _that = _this
    dispatch(getRequest())
    axios.patch(`${process.env.REACT_APP_API_HOST}/api/v1/heads/${id}`,{'head': data},{headers: headers()})
    .then(response => {
        _that.props.history.push('/heads')
        dispatch(updateHeadSuccess(response.data))
      }).catch(error=> {
        dispatch(createError(error))
      });

  };
}

export  function createError(error){
 return function(dispatch) {  
    dispatch({
      type: actionTypes.GET_RESPONSE_ERROR,
      payload: error
    });
  }
}
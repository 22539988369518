import React, { useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ValidatorForm } from 'react-form-validator-core'

import Field from '@shared/form-fields'
import islands from '@constants/islands'
import { saveRevenueLineItem } from '@actions/lineitems'

export const ROUTE_PATHS = [
  '/revenue-line-items/new',
  '/revenue-line-items/edit'
]

const Form = ({ history }) => {
  const dispatch = useDispatch()

  const [form, setForm] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    {
      company_id: '',
      account_id: "",
      head: "",
      prog: "",
      spend: "",
      loc: "",
      item: "",
      fund: "",
      long_desc: "",
      fee: '',
      batch_name: "",
      skip_tfms: false,
      ar_cash: false
    }
  )

  const onSubmit = async ev => {
    ev.preventDefault()
    dispatch(saveRevenueLineItem(history, form))
  }

  const on = {
    text: ({ target }) => setForm({ [target.name]: target.value }),
  }

  return (
    <div className='content'>
      <div className='page-inner'>
        <div className='page-header'>
          <h4 className='page-title'>
            Revenue Line Item Form
          </h4>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-header'>
                <div className='card-title'>
                  Revenue Line Item
                </div>
              </div>
              <ValidatorForm onSubmit={onSubmit}>
                <div className='card-body'>
                  <Field
                    type='text'
                    name='company_id'
                    onChange={on.text}
                    value={form.company_id}
                  />
                  <Field
                    type='text'
                    name='account_id'
                    onChange={on.text}
                    value={form.account_id}
                  />
                  <Field
                    type='text'
                    name='head'
                    onChange={on.text}
                    value={form.head}
                  />
                  <Field
                    type='text'
                    name='prog'
                    onChange={on.text}
                    value={form.prog}
                  />
                  <Field
                    type='text'
                    name='spend'
                    onChange={on.text}
                    value={form.spend}
                  />
                  <Field
                    type='text'
                    name='loc'
                    onChange={on.text}
                    value={form.loc}
                  />
                  <Field
                    type='text'
                    name='item'
                    onChange={on.text}
                    value={form.item}
                  />
                  <Field
                    type='text'
                    name='fund'
                    onChange={on.text}
                    value={form.fund}
                  />
                  <Field
                    type='text'
                    name='long_desc'
                    onChange={on.text}
                    value={form.long_desc}
                  />
                  <Field
                    type='text'
                    name='fee'
                    onChange={on.text}
                    value={form.fee}
                  />
                  <Field
                    type='text'
                    name='batch_name'
                    onChange={on.text}
                    value={form.batch_name}
                  />
                  <Field
                    type='select'
                    name='skip_tfms'
                    onChange={on.select}
                    value={form.skip_tfms}
                    options={[
                      { label: 'Yes, Skip', value: true },
                      { label: 'No, Don\'t Skip', value: false },
                    ]}
                  />,
                  <Field
                    type='text'
                    name='ar_cash'
                    onChange={on.text}
                    value={form.ar_cash}
                  />
                </div>
                <div className='card-action'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <button
                        type='submit'
                        className='btn btn-success mr-1'
                        children='Submit'
                      />
                      <button
                        type='button'
                        className='btn btn-danger'
                        onClick={() => history.push('/revenue-line-items')}
                        children='Cancel'
                      />
                    </div>
                  </div>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Form)

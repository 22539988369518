import { useEffect } from 'react'

import toastr from '@shared/toastr'

export default function Alert ({ success_message, error }) {
  useEffect(() => {
    if (error) {
      toastr.error('Error', error)
    }

    if (success_message) {
      toastr.success('Success', success_message)
    }
  })

  return null
}

import React, { Fragment } from 'react'
import { ValidatorComponent } from 'react-form-validator-core'
import Select from 'react-dropdown-select';

class SelectField extends ValidatorComponent {
  errorStyles = {
    color: '#f25961',
    fontSize: '80%',
    fontWeight: 600,
  }

  errorText = () => this.state.isValid ? null : (
    <div style={this.errorStyles}>
      {this.getErrorMessage()}
    </div>
  )

  render() {
    const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props
    rest['className'] = this.state.isValid ? rest['className'] : rest['className'] + ' input_error'

    return (
      <Fragment>
        <Select {...rest} ref={(r) => { this.select = r; }} />
        {this.errorText()}
      </Fragment>
    );
  }
}

export default SelectField

import React from 'react'
import { connect } from 'react-redux'

import LandingPage from '@wrappers/landing-page'
import { forgetPassword } from '@actions/users'

export const ROUTE_OPEN = true

class ForgotPassword extends React.Component {
  state = {
    open: false,
    form: {
      email: ''
    },
  }

  on = {
    change: ({ target }) => this.setState(state => {
      state.form[target.name] = target.value
      return state
    }),

    submit: ev => {
      ev.preventDefault()
      this.props.forgetPassword(this.state.form)
    }
  }

  render () {
    const { state, props, on } = this

    return (
      <LandingPage
        title='Ministry of Health & Wellness'
        subtitle="Financial Services for MOH"
        success_message={props.success_message}
        error={props.error}
      >
        <form onSubmit={on.submit}>
          <div className="form-group">
            <label htmlFor="username" className="placeholder"><b>Email</b></label>
            <input id="email" name="email" type="email" onChange={on.change} value={state.form.email} className="form-control" required />
          </div>
          <div className="form-group">
            <input type="submit" className="btn btn-primary" value="Send" required />
          </div>
        </form>
      </LandingPage>
    )
  }
}

export default connect(({ users }) => ({
  error: users.error,
  success_message: users.success_message,
}), { forgetPassword })(ForgotPassword)

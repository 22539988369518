import React from 'react'

const DefaultTemplate = ({ background, children }) => {
  const style = background ? {
    backgroundImage: `url('${background}')`,
    backgroundSize: 'cover'
  } : undefined

  return (
    <section className='flex justify-center items-center md:p-10' style={style}>
      <article className='md:rounded-lg md:shadow-lg bg-white p-8'>
        {children}
      </article>
    </section>
  )
}

export default DefaultTemplate

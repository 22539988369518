import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ValidatorForm } from 'react-form-validator-core'

import { saveReplaySms } from '@actions/replay-sms'
import Loader from '@shared/loader'
import extractKeys from '@helpers/extract-keys'
import formatPhoneNumber from '@helpers/normalize-phone'
import Field from '@shared/form-fields'

const freshForm = () => ({
  invoice_reference: '',
  mobile_number: '',
  type: '',
})

class ReplaySms extends React.Component {
  state = {
    form: {},
    user_id: sessionStorage.user && JSON.parse(sessionStorage.user).id,
  }

  on = {
    change: ({ target }) => this.setState(state => {
      state.form[target.name] = target.value
      return state
    }),

    select: name => ([selected]) => selected && this.setState(state => {
      state.form[name] = selected.value
      return state
    }),

    submit: ev => {
      ev.preventDefault()
      const { state, props } = this

      props.saveReplaySms({
        ...state.form,
        mobile_number: formatPhoneNumber(state.form.mobile_number)
      }, success => success && this.setState({ form: freshForm() }))
    }
  }

  render () {
    const { state, props, on } = this

    const options = ['Invoice', 'Receipt'].map(label => ({
      label,
      value: label.toLowerCase()
    }))

    return (
      <React.Fragment>
        <Loader loading={props.loading} />
        <div className='content'>
          <div className='page-inner'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <div className='card-title'>Replay SMS</div>
                  </div>
                  <ValidatorForm onSubmit={on.submit}>
                    <div className='card-body'>
                      <Field
                        type='text'
                        onChange={on.change}
                        name='invoice_reference'
                        label='Invoice Number'
                        value={state.form.invoice_reference}
                      />

                      <Field
                        type='text'
                        onChange={on.change}
                        name='mobile_number'
                        value={state.form.mobile_number}
                      >
                        <span class='leading-loose'>All phone numbers must start with 1242</span>
                      </Field>

                      <Field
                        onChange={on.select('type')}
                        name='type'
                        options={options}
                        value={state.form.type}
                      />
                    </div>

                    <div className='card-action'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <input className='btn btn-success mr-1' type='submit' value='Submit' />
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(
  connect(
    state => extractKeys(state.cash, 'loading', 'error', 'success_message'),
    { saveReplaySms }
  )(ReplaySms)
)

import axios from "axios";
import moment from "moment";

import * as actionTypes from "@action-types";
import { encryptRsakey } from "@helpers/rsa-encryption";

function headers() {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    Authorization: user.auth_token,
  };
}

export function getRequest() {
  return {
    type: actionTypes.GET_REQUEST_SUCCESS,
    payload: {},
  };
}

export function getTransactionsSuccess(response) {
  return {
    type: actionTypes.GET_TRANSACTIONS,
    payload: { response },
  };
}

function format(d) {
  return moment(d).format("YYYY-MM-DD");
}

export function downloadTransactions(fileurl) {
  return function () {
    fetch(process.env.REACT_APP_API_HOST + fileurl, {
      method: "GET",
      headers: headers(),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileurl.split("?").slice(-1)[0]}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
}

export function getTransactions(start, end) {
  const url = [process.env.REACT_APP_API_HOST + "/api/v1/transactions"];
  start && url.push("start_date=" + format(start));
  end && url.push("end_date=" + format(end));

  return function (dispatch) {
    dispatch(getRequest());
    axios
      .get(url.join("&").replace("&", "?"), { headers: headers() })
      .then((response) => {
        dispatch(getTransactionsSuccess(response.data.transactions));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function getTransactionSuccess(response) {
  return {
    type: actionTypes.GET_PARTICULAR_TRANSACTION,
    payload: { response },
  };
}

export function getTransaction(id) {
  return function (dispatch) {
    dispatch(getRequest());
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/v1/transactions/${id}`, {
        headers: headers(),
      })
      .then((response) => {
        dispatch(getTransactionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function voidTransactionSuccess(response) {
  return {
    type: actionTypes.VOID_PARTICULAR_TRANSACTION,
    payload: { response },
  };
}

export const voidTransaction = (id, cb) => async (dispatch) => {
  dispatch(getRequest());

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/v1/transactions/void/${id}`,
      {
        headers: headers(),
      }
    );

    dispatch(voidTransactionSuccess(data));
    typeof cb == "function" && cb();
  } catch (err) {
    dispatch(createError(err));
  }
};

export function saveTransactionSuccess(payload) {
  return {
    payload,
    type: actionTypes.CREATE_TRANSATION,
  };
}

export function saveTransaction(component, data, redirect = true) {
  delete data["publisher-name"];

  return async function (dispatch) {
    dispatch(getRequest());
    let res;

    try {
      res = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/v1/fetch_rsa_key`
      );
      if (!res.data.rsa_key) throw new Error({ response: res });
    } catch (err) {
      return dispatch(createError(err));
    }

    const { public_key, rsa_token } = res.data.rsa_key;

    data["card-number"] = await encryptRsakey(public_key, data["card-number"]);
    data["card-cvv"] = await encryptRsakey(public_key, data["card-cvv"]);
    data["card-name"] = await encryptRsakey(public_key, data["card-name"]);
    data["card-exp"] = await encryptRsakey(public_key, data["card-exp"]);
    data["card-amount"] = await encryptRsakey(public_key, data["card-amount"]);
    data["rsa_token"] = rsa_token;

    // Trim First & Last Name
    data.first_name = data.first_name?.trim()
    data.last_name = data.last_name?.trim()

    try {
      res = await axios.post(
        `${process.env.REACT_APP_API_HOST}/api/v1/transactions`,
        data,
        { headers: headers() }
      );

      if (res.data && !res.data.error) {
        if (res.data.status === 400) {
          dispatch(createError({ response: res }));
        } else {
          redirect && component.props.history.push("/transactions");
          dispatch(saveTransactionSuccess(res.data));
        }
      } else {
        dispatch(createError({ response: res }));
      }
    } catch (err) {
      dispatch(createError(err));
    }
  };
}

export function updateTransactionSuccess(response) {
  return {
    type: actionTypes.UPDATE_TRANSATION,
    payload: {
      response,
    },
  };
}

export function updateTransaction(_this, id, data) {
  return function (dispatch) {
    const _that = _this;
    dispatch(getRequest());
    axios
      .patch(
        `${process.env.REACT_APP_API_HOST}/api/v1/transactions/${id}`,
        { transaction: data },
        { headers: headers() }
      )
      .then((response) => {
        _that.props.history.push("/transactions");
        dispatch(updateTransactionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(createError(error));
      });
  };
}

export function createError(error) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.GET_RESPONSE_ERROR,
      payload: error,
    });
  };
}

import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import dashboard from "./dashboard";
import transactions from "./transactions";
import heads from "./heads";
import users from "./users";
import lineItems from "./lineitems";
import invoices from "./invoices";
import companies from "./companies";
import cash from "./cash";
import reports from "./reports";
import search from "./search";

export default combineReducers({
  dashboard: dashboard,
  transactions: transactions,
  reports: reports,
  users: users,
  heads: heads,
  invoices: invoices,
  companies: companies,
  lineitems: lineItems,
  cash: cash,
  search: search,
  router: routerReducer,
});

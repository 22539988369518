export const GET_RESPONSE_ERROR = 'GET_RESPONSE_ERROR'
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS'
export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_ROLES_DATA = 'GET_ROLES_DATA'
export const GET_USERS_DATA = 'GET_USERS_DATA'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const CUSTOM_ERROR = 'CUSTOM_ERROR'

// TRANSACTIONS
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const GET_PARTICULAR_TRANSACTION = 'GET_PARTICULAR_TRANSACTION'
export const VOID_PARTICULAR_TRANSACTION = 'VOID_PARTICULAR_TRANSACTION'
export const CREATE_TRANSATION = 'CREATE_TRANSATION'
export const UPDATE_TRANSATION = 'UPDATE_TRANSATION'

// HEADS
export const GET_HEADS = 'GET_HEADS'
export const GET_PARTICULAR_HEAD = 'GET_PARTICULAR_HEAD'
export const CREATE_HEAD = 'CREATE_HEAD'
export const UPDATE_HEAD = 'UPDATE_HEAD'

//
export const GET_LINE_ITEMS = 'GET_LINE_ITEMS'
export const GET_PARTICULAR_LINE_ITEM = 'GET_PARTICULAR_LINE_ITEM'
export const CREATE_LINE_ITEM = 'CREATE_LINE_ITEM'
export const UPDATE_LINE_ITEM = 'UPDATE_LINE_ITEM'

export const GET_REVENUE_LINE_ITEMS = 'GET_REVENUE_LINE_ITEMS'
export const CREATE_REVENUE_LINE_ITEM = 'CREATE_REVENUE_LINE_ITEM'
export const UPDATE_REVENUE_LINE_ITEM = 'UPDATE_REVENUE_LINE_ITEM'

export const CLEAR_ERROR = 'CLEAR_ERROR'
export const CREATE_CASH_PAYMENT = 'CREATE_CASH_PAYMENT'
export const CREATE_REPLAY_CASH_PAYMENT = 'CREATE_REPLAY_CASH_PAYMENT'

export const GET_COMPANIES = 'GET_COMPANIES'
export const GET_PARTICULAR_COMPANY = 'GET_PARTICULAR_COMPANY'
export const CREATE_COMPANY = 'CREATE_COMPANY'
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const REMOVE_COMPANY = 'REMOVE_COMPANY'

export const GET_ALL_REVENUE = 'GET_ALL_REVENUE'

export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const CREATE_PAYEE = 'CREATE_PAYEE'

export const GET_DB_DATA = 'GET_DB_DATA'

export const REMEMBER_TOKEN_SUCCESS = 'REMEMBER_TOKEN_SUCCESS'
export const GET_RM_REQUEST = 'GET_RM_REQUEST'

export const STOP_LOADER = 'STOP_LOADER'

export const GET_INVOICES = 'GET_INVOICES'
export const GET_PARTICULAR_INVOICE = 'GET_PARTICULAR_INVOICE'
export const GET_PARTICULAR_LOCATION = 'GET_PARTICULAR_LOCATION'
export const CREATE_INVOICE = 'CREATE_INVOICE'
export const GENERATE_REPORT = 'GENERATE_REPORT'
export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const REMOVE_INVOICE = 'REMOVE_INVOICE'

export const GET_SEARCH_DATA = 'GET_SEARCH_DATA'

import * as actionTypes from '@action-types'

const initial = {
  authenticated: false,
  loading: false,
  success: false,
  success_message: null,
  error: null,
  data: [],
}

export default (state = initial, { type, payload}) => {
  switch (type) {
    case actionTypes.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.GENERATE_REPORT:
      return {
        ...state,
        loading: false,
        error: null,
        success_message: "The report is building. Please check your email for updates.",
        success: true,
        reports: payload.response
      }
    
      case actionTypes.GET_RESPONSE_ERROR:
        return {
          ...state,
          loading: false,
          error: payload.error || payload.response.data.response,
          success_message: null
        }
      case actionTypes.GET_TRANSACTIONS:
        return {
          ...state,
          loading: false,
          error: null,
          transactions: payload.response
        }
      case actionTypes.VOID_PARTICULAR_TRANSACTION:
        return {
          ...state,
          loading: false,
          success_message: "Transaction successfully voided",
        }
      default:
        return {
          ...state,
          loading: false,
          success_message: null,
          success: false
        }
  }
}



import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { saveHead, updateHead, getHead } from '@actions/heads'
import { preValue } from '@helpers/tools'
import InputField from '@shared/form-fields/input'
import SelectField from '@shared/form-fields/select'
import { ValidatorForm } from 'react-form-validator-core';
import _ from 'lodash'
let invobj = {}

export const ROUTE_OPEN = true
export const ROUTE_PATHS = ['/heads/new', 'heads/edit']

class HeadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},

      farmer_list: {},
      error: undefined,
      list_id: this.props.location.search.replace('?id=', "")
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectHandle = this.selectHandle.bind(this);
  }

  componentDidMount() {



    if (this.state.list_id)
      this.props.getHead(this.state.list_id)
    //
  }
  handleChange(event) {

    invobj[event.target.name] = event.target.value;
    this.setState({ formData: invobj });
  }

  selectHandle(name, data) {
    if (!_.isEmpty(data)) {
      invobj[name] = data[0].value;
      this.setState({ formData: invobj });
    }

  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEmpty(nextProps.farmer_list)) {
      this.setState({ farmer_list: nextProps.farmer_list })
    }
    if (!_.isEmpty(nextProps.descriptions)) {
      this.setState({ descriptions: nextProps.descriptions })
    }

    if (!_.isEmpty(nextProps.lookups)) {

      this.setState({ lookups: nextProps.lookups })
    }
  }



  handleSubmit(event) {
    event.preventDefault()
    if (_.isEmpty(this.state.farmer_list))
      this.props.saveHead(this, {
        ...this.state.formData,
        farmer_user_id: JSON.parse(sessionStorage.fuser).id
      })
    else
      this.props.updateHead(this, this.state.list_id, {
        ...this.state.formData,
        farmer_user_id: JSON.parse(sessionStorage.fuser).id
      })
  }

  render () {
    let { farmer_list: { price_unit, price, farmer_description_id, farming_lookup_list_id } } = this.state
    const options = this.state.descriptions.map((item) => ({ value: item.id, label: item.island }))
    const options2 = this.state.lookups.map((item) => ({ value: item.id, label: item.id }))

    return (
      <React.Fragment>
        <div className="content">
          <div className="page-inner">
            <div className="page-header">
              <h4 className="page-title">Form Inventory</h4>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Inventory</div>
                  </div>
                  <ValidatorForm
                    ref="form"
                    method="post"
                    onSubmit={(e) => this.handleSubmit(e)}
                  >

                    <div className="card-body">
                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Farmer Description <span className="required-label">*</span></label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <SelectField
                            onChange={(values) => this.selectHandle('farmer_description_id', values)}
                            name="farming_lookup_list_id"
                            options={options}
                            value={preValue(this.state.formData.farmer_description_id, farmer_description_id)}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            className="form-control"

                          />
                        </div>
                      </div>


                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Farming Lookup <span className="required-label">*</span></label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <SelectField
                            onChange={(values) => this.selectHandle('farming_lookup_list_id', values)}
                            name="farming_lookup_list_id"
                            options={options2}
                            value={preValue(this.state.formData.farming_lookup_list_id, farming_lookup_list_id)}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            className="form-control"

                          />
                        </div>
                      </div>


                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Price <span className="required-label">*</span></label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="price"
                            value={preValue(this.state.formData.price, price)}
                            validators={['required', "isNumber"]}
                            errorMessages={['This field is required', "This field should be numeric"]}
                            className="form-control"
                            placeholder="price"
                          />
                        </div>
                      </div>

                      <div className="form-group form-show-validation row">
                        <label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Price Unit <span className="required-label">*</span></label>
                        <div className="col-lg-4 col-md-9 col-sm-8">
                          <InputField
                            onChange={this.handleChange}
                            name="price_unit"
                            value={preValue(this.state.formData.price_unit, price_unit)}
                            validators={['required', "isNumber"]}
                            errorMessages={['This field is required', "This field should be numeric"]}
                            className="form-control"
                            placeholder="price unit"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-action">
                      <div className="row">
                        <div className="col-md-12">
                          <input className="btn btn-success mr-1" type="submit" value="Submit" />
                          <button className="btn btn-danger" onClick={() => this.props.history.push('/inventory')}>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>

    );
  };
}


function mapStateToProps(state) {
  return {
    error: state.heads.error,
    head: state.heads.head
  }
}


export default withRouter(connect(mapStateToProps, {
  saveHead, updateHead, getHead
})(HeadForm));

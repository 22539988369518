import axios from 'axios'
import moment from 'moment'

import * as actionTypes from '@action-types'

function headers () {
  let user = sessionStorage.getItem("user");
  user = JSON.parse(user);
  return {
    "Content-Type": "application/json",
    "Authorization": user.auth_token
  }
}

export function getRequest () {
	return {
		type:  actionTypes.GET_REQUEST_SUCCESS,
		payload: {}
	}
}

export function getTransactionsSuccess (response){
  return {
    type: actionTypes.GET_TRANSACTIONS,
    payload: { response }
  }
}

function format (d) {
  return moment(d).format('DD/MM/YYYY')
}

export function getReports () {
  const url = [process.env.REACT_APP_API_HOST + '/api/v1/reports']
  
  return function (dispatch) {
    dispatch(getRequest())
    axios.get(url,{headers: headers()})
    .then(response => {
      console.log("printingn infor"+response.data)
        dispatch(getTransactionsSuccess(response.data.reports))
      }).catch(error=> {
        dispatch(createError(error))
      });
  };
}

export function downloadReports (fileurl) {
  return function () {
      fetch(process.env.REACT_APP_API_HOST + fileurl, {
      method: 'GET',
      headers: headers(),
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${fileurl.split('?').slice(-1)[0]}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };
}


export function saveReportSuccess (response) {
  return {
    type: actionTypes.GENERATE_REPORT,
    payload: {
      response,
    }
  }
}

export function saveReport (_this, data,start,end) {
  console.log("this is data"+data.location);
  let url = [process.env.REACT_APP_API_HOST + '/api/v1/reports/'+data.location]
  start && url.push('start_date=' + format(start))
  end && url.push('end_date=' + format(end))
  const _that = _this
  return function (dispatch) {
    dispatch(getRequest())
    axios.get(url.join('&').replace('&', '?'),{headers: headers()})
    .then(response => {
      _that.props.history.push('/report')
        dispatch(saveReportSuccess(response.data))
      }).catch(error=> {
        dispatch(createError(error))
      });
  };
}

export function voidTransactionSuccess (response) {
  return {
    type: actionTypes.VOID_PARTICULAR_TRANSACTION,
    payload: { response }
  }
}

export const voidTransaction = (id, cb) => async (dispatch) => {
  dispatch(getRequest())

  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/transactions/void/${id}`, {
      headers: headers()
    })

    dispatch(voidTransactionSuccess(data))
    typeof cb == 'function' && cb()
  } catch (err) {
    dispatch(createError(err))
  }
}

export function createError (error) {
 return function (dispatch) {
    dispatch({
      type    : actionTypes.GET_RESPONSE_ERROR,
      payload : error
    })
  }
}
import React from 'react'

import '@styles/pagination.css'

export default ({ columns }) => {
	const headings = columns.map((h, i) => (
		<th key={i}>{h.title}</th>
	))

	return (
		<table id="empty-basic-datatables" className="display table table-striped table-hover" >
			<thead>
				<tr>{headings}</tr>
			</thead>
			<tfoot>
				<tr>{headings}</tr>
			</tfoot>
			<tbody>
				<tr className="odd">
					<td valign="top" colSpan={columns.length} style={{ textAlign: 'center' }} className="dataTables_empty">No data available in table</td>
				</tr>
			</tbody>
		</table>
	)
}
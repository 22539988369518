import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ValidatorForm } from 'react-form-validator-core'

import EmptyTable from '@shared/empty-table'
import Loader from '@shared/loader'
import DateTimeField from '@shared/form-fields/date'
import { getInvoices, removeInvoice, downloadInvoices } from '@actions/invoices'
import appConstants from '@constants/constants'
import InvoiceTable from './_table'

class Invoice extends React.Component {
  constructor (props) {
    super(props)

    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    this.roles = []
    this.state = {
      isOpen: false,
      current_list: {},
      date_start: today,
      date_end: tomorrow,
      dates_clean: true,
      user: sessionStorage.user && JSON.parse(sessionStorage.user),
    }

    Object.assign(this, { today, tomorrow })
  }

  componentDidMount () {
    this.props.getInvoices(this.state.date_start, this.state.date_end)

    if (this.state.user) {
      this.roles = this.state.user.roles.map((role) => role.name)
    }
  }

  componentWillReceiveProps ({ success }) {
    success && this.setState({ isOpen: false })
  }

  on = {
    date: (name) => (d) => {
      this.setState({ [name]: this.formatDate(d), dates_clean: false })
    },
  }

  searchDateRange = () => {
    this.props.getInvoices(this.state.date_start, this.state.date_end)
    this.setState({ dates_clean: true })
  }

  formatDate = (date) => typeof date == 'string'
    ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, '$1-$2-$3'))
    : date

  handleAction = (id, action) => {
    if (action == 'edit') {
      const current_list = this.state.lists.find(the => the.id === id)
      this.setState({ isOpen: true, current_list: current_list })
    }
  }

  reqcloseModal = () => {
    this.setState({ isOpen: !this.state.isOpen, current_list: {} })
  }

  removeData = id => {
    this.props.removeInvoice(this, id)
  }

  render () {
    const { state, props, on, tomorrow } = this
    const { date_start, date_end } = state
    const { loading, data } = props

    const styles = {
      form: {
        display: 'inline-flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: '1.5rem',
      },
      label: {
        margin: 0,
      },
    }

    const filters = (
      <ValidatorForm className='inline-flex items-center ml-auto mr-6' onSubmit={this.searchDateRange}>
        <label htmlFor='date_start' style={styles.label}>
          From&nbsp;&nbsp;
        </label>
        <DateTimeField
          name='date_start'
          className='form-control'
          onChange={on.date('date_start')}
          selected={date_start}
          value={date_start}
          validators={['required']}
          errorMessages={['Required']}
          dateFormat='do MMMM, yyyy'
          dropdownMode='select'
          maxDate={date_end}
        />
        <label htmlFor='date_end' style={styles.label}>
          &nbsp;&nbsp;&nbsp;&nbsp;To&nbsp;&nbsp;
        </label>
        <DateTimeField
          name='date_end'
          className='form-control'
          onChange={on.date('date_end')}
          selected={date_end}
          value={date_end}
          validators={['required']}
          errorMessages={['Required']}
          dateFormat='do MMMM, yyyy'
          dropdownMode='select'
          minDate={date_start}
          maxDate={tomorrow}
        />
        <button type='submit' className='refresh-btn' disabled={state.dates_clean}>
          <i className='fas fa-sync' />
        </button>
      </ValidatorForm>
    )

    const table =
      !loading && data && data.length ? (
        <InvoiceTable
          {...state}
          data={data}
          removeInvoice={this.removeData}
          downloadInvoices={props.downloadInvoices}
        />
      ) : (
        <EmptyTable columns={appConstants.INVOICES_COLUMNS} />
      )

    return (
      <React.Fragment>
        <Loader loading={props.loading} />

        <div className='content'>
          <div className='page-inner'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <div className='d-flex align-items-center'>
                      <h4 className='card-title'>Invoices</h4>
                      {filters}
                      {this.roles.indexOf('read') != -1 ? null : (
                        <a
                          className='btn btn-primary btn-round'
                          href='/invoices/new'
                        >
                          <i className='fa fa-plus'></i>
                          &nbsp;&nbsp;&nbsp;&nbsp;Create Invoice
                        </a>
                      )}
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='table-responsive'>{table}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ invoices }) => ({
  data: invoices.invoices,
  error: invoices.error,
  success_message: invoices.success_message,
  success: invoices.success,
  loading: invoices.loading,
})

export default withRouter(
  connect(mapStateToProps, { getInvoices, downloadInvoices, removeInvoice })(
    Invoice
  )
)

import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer () {
  return (
    <footer className='footer'>
      <div className='container-fluid'>
        <nav className='pull-left'>
          <ul className='nav'>

          </ul>
        </nav>
        <div className='copyright ml-auto'>
          {new Date().getFullYear()}&nbsp;
          Ministry of Health &amp; Wellness
          &mdash;
          <Link to='/'>PatientLink Pay</Link>
        </div>
      </div>
    </footer>
  )
}

import React, { Fragment, useState, useMemo, useReducer } from 'react'
import { usePaymentInputs } from 'react-payment-inputs'
import { ValidatorForm } from 'react-form-validator-core'
import { extractKeys, withoutKeys } from '@utils'

import Loader from '@components/loader'
import Field from '@components/fields/all'
import Card from '@components/fields/card'
import Heading from '@components/heading'

const defaultColor = '#3458a4'

const formatDate = date => {
  if (!date) return null
  return typeof date == 'string'
    ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, '$1-$2-$3'))
    : date
}

const findSelected = (options, target) => [
  options.find(o => o.value === target) || {},
]

const pretty = str => str
  .split(/[ _-]/)
  .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
  .join(' ')

const getInitialValues = (fields = []) => fields.reduce((defs, f) => {
  if (/^::/.test(f)) return defs
  if (Array.isArray(f)) {
    return { ...defs, ...getInitialValues(f) }
  }

  if (f.heading) return defs
  if (f == 'CARD_INFO') {
    return {
      ...defs,
      'card-name': '',
      'card-amount': '',
      'card-number': '',
      'card-exp': '',
      'card-cvv': '',
    }
  }

  return {
    ...defs,
    [f.name ?? f]: '',
  }
}, {})

const normalize = (values, update) => f => {
  if (Array.isArray(f)) {
    return f.map(normalize(values, update))
  }

  if (f?.startsWith?.('::')) return {
    heading: f.slice(2)
  }

  if (typeof f == 'string') return {
    name: f,
    type: 'text',
    label: pretty(f),
    value: values[f],
    onChange: ev => update([f, ev.target.value]),
  }
  
  const base = {
    ...f,
    type: f.type ?? (f.options ? 'select' : 'text'),
    label: f.label ?? pretty(f.name),
    value: f.value ?? values[f.name],
  }

  switch (base.type) {
    case 'text':
    case 'select.native':
      return {
        ...base,
        onChange: ev => update([f.name, ev.target.value]),
      }
    case 'select':
      return {
        ...base,
        values: findSelected(base.options, base.value),
        onChange: values => update([f.name, values?.[0]?.value])
      }
    case 'date':
      return {
        showYearDropdown: true,
        dateFormat: 'do MMMM, yyyy',
        dropdownMode: 'select',
        ...base,
        value: formatDate(base.value),
        selected: formatDate(base.value),
        onChange: d => update([f.name, d])
      }
    default:
  }
}

const ValidatedForm = props => {
  const [submitting, setSubmitting] = useState(false)
  const [values, update] = useReducer(
    (state, [name, value]) => ({ ...state, [name]: value }),
    getInitialValues(props.fields)
  )

  const fields = props.fields.map(normalize(values, update))
  const ccp = usePaymentInputs({
    cardNumberValidator: ({ cardType }) => {
      if (['Visa', 'Mastercard'].includes(cardType.displayName)) return
      return 'Please use either a Visa or Mastercard'
    }
  })

  const inputs = useMemo(() => fields.map(f => {
    if (!f) return null
    if (f.test && !f.test(values)) return null

    if (f.heading) {
      return <Heading key={f.heading}>{f.heading}</Heading>
    }

    if (f.name == 'CARD_INFO') {
      return <Card {...ccp} {...values} update={update} key='__CARD__' />
    }

    if (Array.isArray(f)) {
      const k = []
      const nested = f.map((c, i) => {
        k.push(c.name)
        return (
          <div className='col-md-6' key={c.name}>
            <Field {...c} />
          </div>
        )
      })

      return <div className='row' key={k.join(':')}>{nested}</div>
    }

    return <Field {...withoutKeys(f, 'test', 'save')} key={f.name} />
  }), [fields, ccp, values])

  const submit = async ev => {
    if (ccp.meta.error) return
    if (submitting) return

    const reduceValues = (o, f) => {
      if (f.save === false) return o

      if (f == 'CARD_INFO') {
        return {
          ...extractKeys(
            values,
            'card-name',
            'card-number',
            'card-exp',
            'card-cvv',
          ),
          ...o,
        }
      }

      if (Array.isArray(f)) {
        return f.reduce(reduceValues, o)
      }

      const identifier = typeof f == 'string' ? f : f.name
      const value = f.value ?? values[identifier]

      if (value === undefined) return o
      return { ...o, [identifier]: value }
    }

    const data = props.fields.reduce(reduceValues, {})
    setSubmitting(true)
    await props.onSubmit(data)
    setSubmitting(false)
  }

  return (
    <Fragment>
      <Loader loading={submitting} />
      <ValidatorForm onSubmit={submit}>
        {inputs}
        <div className='form-group w-5/6 mx-auto'>
          <button
            type='submit'
            className='paybtn btn w-full mt-3 mt-sm-0 text-white fw-bold'
            style={{ background: props.color ?? defaultColor }}
          >Pay</button>
        </div>
      </ValidatorForm>
    </Fragment>
  )
}

export default ValidatedForm

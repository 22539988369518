import React from 'react'
import { Link } from 'react-router-dom'
import { FaSearch, FaChartBar } from 'react-icons/fa'

import AppLinks from './links'

const ROLES_WITH_SEARCH_ALLOWED = [
  'treasury',
  'revenue',
  'agency_cashier',
  'agency_accountant',
  'read'
]

export default class Header extends React.Component {
  user = sessionStorage.user && JSON.parse(sessionStorage.user)

  render = () => (
    <div className="main-header" data-background-color="blue2">
      <div className="nav-top">
        <div className="container d-flex flex-row">
          <button className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
              <i className="icon-menu"></i>
            </span>
          </button>
          <button className="topbar-toggler more"><i className="icon-options-vertical"></i></button>
          {/* <!-- Logo Header --> */}
          <a href="index.html" className="logo d-flex align-items-center">
            {/* <img
              src="https://via.placeholder.com/108x35"
              alt="navbar brand"
              className="navbar-brand"
            /> */}
            <strong className='text-xl text-white pr-3'>PatientLink Pay</strong>
          </a>
          {/* <!-- End Logo Header --> */}

          {/* <!-- Navbar Header --> */}
          <nav className="navbar navbar-header navbar-expand-lg p-0">

            <div className="container-fluid p-0">
              <div className="collapse" id="search-nav">
                <form className="navbar-left navbar-form nav-search mr-md-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="submit" className="btn btn-search pr-1">
                        <i className="fa fa-search search-icon"></i>
                      </button>
                    </div>
                    <input type="text" placeholder="Search ..." className="form-control" />
                  </div>
                </form>
              </div>
              <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
                <li className="nav-item toggle-nav-search hidden-caret">
                  <a className="nav-link" data-toggle="collapse" href="#search-nav" role="button" aria-controls="search-nav">
                    <i className="fa fa-search"></i>
                  </a>
                </li>

                <li className="nav-item dropdown hidden-caret">
                  <a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" >
                    <div className="avatar-sm">
                      <img
                        src="https://via.placeholder.com/60x60"
                        alt="..."
                        className="avatar-img rounded-circle"
                      />
                    </div>
                  </a>
                  <ul className="dropdown-menu dropdown-user animated fadeIn">
                    <div className="dropdown-user-scroll scrollbar-outer">
                      <li>
                        <div className="user-box">
                          <div className="avatar-lg">
                            <img
                              src="https://via.placeholder.com/60x60"
                              alt="image profile"
                              className="avatar-img rounded"
                            />
                          </div>
                          <div className="u-text">
                            <h4>{this.user.first_name.charAt(0).toUpperCase()}. {this.user.last_name.initialCaps()}</h4>
                            <p className="text-muted">{this.user.email}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="dropdown-divider"></div>
                        <Link to='/logout' className='dropdown-item'>
                          Logout
                        </Link>
                      </li>
                    </div>
                  </ul>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link quick-sidebar-toggler">
                    <i className="fa fa-th"></i>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/* <!-- End Navbar --> */}
        </div>
      </div>
      <div className="container">
        <div className="nav-bottom bg-white">
          <h3 className="title-menu d-flex d-lg-none">
            Menu
            <div className="close-menu"> <i className="flaticon-cross"></i></div>
          </h3>
          <ul className="nav page-navigation page-navigation-info">
            {this.user.roles.some(role => role.name == 'agency_accountant') ? (
              <li className={withActiveClass('nav-item', '/dashboard')}>
                <Link className="nav-link" to="/dashboard">
                  <FaChartBar size={18} style={{ marginRight: 15 }} />
                  <span className="menu-title">Dashboard</span>
                </Link>
              </li>
            ) : null}

            <AppLinks />

            {this.user.roles.some(role => ROLES_WITH_SEARCH_ALLOWED.includes(role.name)) ? (
              <li className={withActiveClass('nav-item', '/search')}>
                <Link className="nav-link" to="/search">
                  <FaSearch size={18} style={{ marginRight: 15 }} />
                  <span className="menu-title">Search</span>
                </Link>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  )
}

function withActiveClass (classes, path) {
  return window.location.pathname.startsWith(path)
    ? `${classes} submenu active`
    : classes
}

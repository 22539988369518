import React from 'react'
import { connect } from 'react-redux'

import { logoutUser } from '@actions/users'

export const ROUTE_OPEN = true

class Logout extends React.Component {
  componentDidMount () {
    this.props.logoutUser()
  }

  render () {
    return null
  }
}

export default connect(
  () => ({}),
  { logoutUser }
)(Logout)


import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ValidatorForm } from 'react-form-validator-core'
import _ from 'lodash'

import { preValue } from '@helpers/tools'
import InputField from '@shared/form-fields/input'
import { saveCashPayment, getAllRevenue } from '@actions/cash'

let invobj = {}
class PayeeForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			formData: {},
			payee: {},
			line_items: [],
			error: undefined,
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.selectHandle = this.selectHandle.bind(this)
		this.validateForm = this.validateForm.bind(this)
	}

	componentDidMount() {
		//this.props.getAllRevenue()
	}
	handleChange(event) {

		invobj[event.target.name] = event.target.value
		this.setState({ formData: invobj })
	}

	selectHandle(name, data) {
		if (!_.isEmpty(data)) {
			invobj[name] = data[0].value
			this.setState({ formData: invobj })
		}

	}

	componentWillReceiveProps(nextProps) {
		if (!_.isEmpty(nextProps.line_items)) {
			this.setState({ line_items: nextProps.line_items })
		}
	}



	handleSubmit (event) {
		event.preventDefault()
	}

	validateForm () {}


	render() {
		const { invoice_number, first_name, last_name, email, value, status, type } = this.state.payee

		return (
			<React.Fragment>
				<div className="content">
					<div className="page-inner">
						<div className="page-header">
							<h4 className="page-title">Payment</h4>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<div className="card-header">
										<div className="card-title">Payee</div>
									</div>
									<ValidatorForm
										ref="form"
										method="post"
										onSubmit={(e) => this.handleSubmit(e)}
									>

										<div className="card-body">
											<div className="form-group form-show-validation row">
												<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Invoice Number <span className="required-label">*</span></label>
												<div className="col-lg-4 col-md-9 col-sm-8">
													<InputField
														onChange={this.handleChange}
														name="invoice_number"
														value={preValue(this.state.formData.invoice_number, invoice_number)}
														validators={['required']}
														errorMessages={['This field is required', "This field should be numeric"]}
														className="form-control"
														placeholder="Invoice Number"
													/>
												</div>
											</div>





											<div className="form-group form-show-validation row">
												<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">First Name <span className="required-label">*</span></label>
												<div className="col-lg-4 col-md-9 col-sm-8">
													<InputField
														onChange={this.handleChange}
														name="first_name"
														value={preValue(this.state.formData.first_name, first_name)}
														validators={['required']}
														errorMessages={['This field is required']}
														className="form-control"
														placeholder="First Name"
													/>
												</div>
											</div>

											<div className="form-group form-show-validation row">
												<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Last Name <span className="required-label">*</span></label>
												<div className="col-lg-4 col-md-9 col-sm-8">
													<InputField
														onChange={this.handleChange}
														name="last_name"
														value={preValue(this.state.formData.last_name, last_name)}
														validators={['required']}
														errorMessages={['This field is required']}
														className="form-control"
														placeholder="Last Name"
													/>
												</div>
											</div>

											<div className="form-group form-show-validation row">
												<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Email <span className="required-label">*</span></label>
												<div className="col-lg-4 col-md-9 col-sm-8">
													<InputField
														onChange={this.handleChange}
														name="email"
														value={preValue(this.state.formData.email, email)}
														validators={['required', "isEmail"]}
														errorMessages={['This field is required', "Invalid email"]}
														className="form-control"
														placeholder="Email"
													/>
												</div>
											</div>

											<div className="form-group form-show-validation row">
												<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Value <span className="required-label">*</span></label>
												<div className="col-lg-4 col-md-9 col-sm-8">
													<InputField
														onChange={this.handleChange}
														name="value"
														value={preValue(this.state.formData.value, value)}
														validators={['required', 'isFloat']}
														errorMessages={['This field is required', "This field should be decimal"]}
														className="form-control"
														placeholder="Value"
													/>
												</div>
											</div>

											<div className="form-group form-show-validation row">
												<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Status <span className="required-label">*</span></label>
												<div className="col-lg-4 col-md-9 col-sm-8">
													<InputField
														onChange={this.handleChange}
														name="status"
														value={preValue(this.state.formData.status, status)}
														validators={['required']}
														errorMessages={['This field is required']}
														className="form-control"
														placeholder="Status"
													/>
												</div>
											</div>

											<div className="form-group form-show-validation row">
												<label htmlFor="name" className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">Type <span className="required-label">*</span></label>
												<div className="col-lg-4 col-md-9 col-sm-8">
													<InputField
														onChange={this.handleChange}
														name="type"
														value={preValue(this.state.formData.type, type)}
														validators={['required']}
														errorMessages={['This field is required']}
														className="form-control"
														placeholder="Type"
													/>
												</div>
											</div>
										</div>
										<div className="card-action">
											<div className="row">
												<div className="col-md-12">
													<input className="btn btn-success mr-1" type="submit" value="Submit" />
													{/*<a className="btn btn-danger" onClick={()=> this.props.history.push('/inventory')} href="javascript:void(0)">Cancel</a>*/}
												</div>
											</div>
										</div>
									</ValidatorForm>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>

		)
	}
}


function mapStateToProps(state) {
	return {
		error: state.cash.error,
		success_message: state.cash.success_message,
		line_items: state.cash.line_items

	}
}


export default withRouter(connect(mapStateToProps, {
	saveCashPayment,
	getAllRevenue
})(PayeeForm))

import React from "react";
import { ValidatorComponent } from "react-form-validator-core";
import Select from "react-dropdown-select";

class SelectField extends ValidatorComponent {
  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      ...rest
    } = this.props;
    rest["className"] = this.state.isValid
      ? rest["className"]
      : rest["className"] + " input_error";
    return (
      <div>
        <Select
          {...rest}
          ref={(r) => {
            this.select = r;
          }}
        />
        {this.errorText()}
        {/*labelError(this.state.isValid, (this.select && this.select.select.current)) */}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <div style={{ color: "#f25961", fontSize: "80%", fontWeight: 600 }}>
        {this.getErrorMessage()}
      </div>
    );
  }
}

export default SelectField;

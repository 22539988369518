import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { VectorMap } from 'react-jvectormap'

import { getDbData } from '@actions/dashboard'

import {
  staticalCircle,
  incomeChart,
  userStaticChart,
  providerChart
} from '../assets/js/dashboard.js'

import '@styles/dashboard.css'

const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

class Dashboard extends React.Component {
  state = {
    open: false,
    data: {},
    transactions: []
  }

  componentWillReceiveProps ({ data, transactions }) {
    if (data) {
      staticalCircle(data)
      incomeChart(data)

      this.setState({ data })
    }

    transactions && this.setState({ transactions })
  }

  componentDidMount () {
    userStaticChart()
    providerChart()
    this.props.getDbData()
  }

  handleClick = () => { }

  monthDate (date) {
    const d = new Date(date)
    return `${MONTHS[d.getMonth()]} ${d.getDate()}`
  }

  renderMap () {
    const props = {
      map: 'world_mill',

      backgroundColor: 'transparent',
      zoomOnScroll: false,
      containerStyle: { width: '100%', height: '520px' },

      regionsSelectable: true,
      onRegionClick: this.handleClick,
      regionStyle: {
        initial: {
          'fill': '#e4e4e4',
          'fill-opacity': 0.9,
          'stroke': 'none',
          'stroke-width': 0,
          'stroke-opacity': 0
        },
        hover: {
          'fill-opacity': 0.8,
          'cursor': 'pointer'
        },
        selected: {
          'fill': '#2938bc' //what colour clicked country will be
        },
        selectedHover: {}
      },

      series: {
        regions: [
          {
            values: [], //this is your data
            scale: ["#146804", "#ff0000"], //your color game's here
            normalizeFunction: "polynomial"
          }
        ]
      }
    }

    return <VectorMap {...props} />
  }

  sortByDate (transactions) {
    return transactions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  }

  render () {
    const { transactions, data } = this.state
    const { top_5_line_items, last_7_day_card_trans_sum, last_7_day_cash_trans_sum } = data

    return (
      <div className="content dashboard-section">
        <div className="panel-header bg-primary-gradient rounded-3xl">
          <div className="page-inner py-5">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div>
                <h2 className="text-white pb-2 fw-bold">Dashboard</h2>
                <h5 className="text-white op-7 mb-2">Ministry of Health &amp; Wellness</h5>
              </div>
              <div className="ml-md-auto py-2 py-md-0">
                <a href="/invoices" className="btn btn-white btn-border btn-round mr-2">Create Invoice</a>
                <a href="/transactions" className="btn btn-white custom-btn btn-round">View Transactions</a>
              </div>
            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row mt--2">
            <div className="col-md-6">
              <div className="card full-height">
                <div className="card-body">
                  <div className="card-title">Overall Statistics</div>
                  <div className="card-category">Daily information about statistics in system</div>
                  <div className="d-flex flex-wrap justify-content-around pb-2 pt-4">
                    <div className="px-2 pb-2 pb-md-0 text-center">
                      <div id="circles-1"></div>
                      <h6 className="fw-bold mt-3 mb-0">Total Number Of<br />Transactions</h6>
                    </div>
                    <div className="px-2 pb-2 pb-md-0 text-center">
                      <div id="circles-2"></div>
                      <h6 className="fw-bold mt-3 mb-0">Total 3rd Party<br />Transactions</h6>
                    </div>
                    <div className="px-2 pb-2 pb-md-0 text-center">
                      <div id="circles-3"></div>
                      <h6 className="fw-bold mt-3 mb-0">Total<br />Transactions</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card full-height">
                <div className="card-body">
                  <div className="card-title">Total Transactions</div>
                  <div className="row py-3">
                    <div className="col-md-4 d-flex flex-column justify-content-around">
                      <div>
                        <h6 className="fw-bold text-uppercase text-success op-8">Total Transactions</h6>
                        <h3 className="fw-bold">{last_7_day_card_trans_sum?.toFixed()}</h3>
                      </div>
                      <div>
                        <h6 className="fw-bold text-uppercase text-danger op-8">Total 3rd Party Transactions</h6>
                        <h3 className="fw-bold">{last_7_day_cash_trans_sum?.toFixed()}</h3>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div id="chart-container">
                        <canvas id="totalIncomeChart"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <div className="card-header">
                    <div className="card-head-row">
                      <div className="card-title">User Statistics</div>
                      <div className="card-tools">
                        <a href="#" className="btn btn-info btn-border btn-round btn-sm mr-2">
                          <span className="btn-label">
                            <i className="fa fa-pencil"></i>
                          </span>
                          Export
                        </a>
                        <a href="#" className="btn btn-info btn-border btn-round btn-sm">
                          <span className="btn-label">
                            <i className="fa fa-print"></i>
                          </span>
                          Print
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="chart-container" style={{minHeight: "375px"}}>
                      <canvas id="statisticsChart"></canvas>
                    </div>
                    <div id="myChartLegend"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-primary">
                  <div className="card-header">
                    <div className="card-title">Daily Sales</div>
                    <div className="card-category">March 25 - April 02</div>
                  </div>
                  <div className="card-body pb-0">
                    <div className="mb-4 mt-2">
                      <h1>$4,578.58</h1>
                    </div>
                    <div className="pull-in">
                      <canvas id="dailySalesChart"></canvas>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="h1 fw-bold float-right text-warning">+7%</div>
                    <h2 className="mb-2">213</h2>
                    <p className="text-muted">Transactions</p>
                    <div className="pull-in sparkline-fix">
                      <Sparklines data={[105,103,123,100,95,105,115]} width={100} height={25}  >
                        <SparklinesLine style={{stroke: "#FFAA53" ,fill: "#09f7f7", fillOpacity: "1" }} />
                      </Sparklines>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-card-no-pd">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-head-row card-tools-still-right">
                      <h4 className="card-title">Users Geolocation</h4>
                      <div className="card-tools">
                        <button className="btn btn-icon btn-link btn-primary btn-xs"><span className="fa fa-angle-down"></span></button>
                        <button className="btn btn-icon btn-link btn-primary btn-xs btn-refresh-card"><span className="fa fa-sync-alt"></span></button>
                        <button className="btn btn-icon btn-link btn-primary btn-xs"><span className="fa fa-times"></span></button>
                      </div>
                    </div>
                    <p className="card-category">
                    Map of the distribution of users around the world</p>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="table-responsive table-hover table-sales">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <img src="https://via.placeholder.com/16x11" alt="indonesia"/>
                                  </div>
                                </td>
                                <td>Indonesia</td>
                                <td className="text-right">
                                  2.320
                                </td>
                                <td className="text-right">
                                  42.18%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <img src="https://via.placeholder.com/16x11" alt="united states"/>
                                  </div>
                                </td>
                                <td>USA</td>
                                <td className="text-right">
                                  240
                                </td>
                                <td className="text-right">
                                  4.36%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <img src="https://via.placeholder.com/16x11" alt="australia" />
                                  </div>
                                </td>
                                <td>Australia</td>
                                <td className="text-right">
                                  119
                                </td>
                                <td className="text-right">
                                  2.16%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <img src="https://via.placeholder.com/16x11" alt="russia" />
                                  </div>
                                </td>
                                <td>Russia</td>
                                <td className="text-right">
                                  1.081
                                </td>
                                <td className="text-right">
                                  19.65%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <img src="https://via.placeholder.com/16x11" alt="china" />
                                  </div>
                                </td>
                                <td>China</td>
                                <td className="text-right">
                                  1.100
                                </td>
                                <td className="text-right">
                                  20%
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="flag">
                                    <img src="https://via.placeholder.com/16x11" alt="brazil" />
                                  </div>
                                </td>
                                <td>Brasil</td>
                                <td className="text-right">
                                  640
                                </td>
                                <td className="text-right">
                                  11.63%
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mapcontainer">
                          {this.renderMap()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            */}
          <div className="row">
            <div className="col-md-8">
              <div className="card full-height">
                <div className="card-header">
                  <div className="card-title">Most Recent Activity</div>
                </div>
                <div className="card-body">
                  <ol className="activity-feed">
                    {this.sortByDate(transactions).map((item, i) => {

                      return (
                        <li key={i} className="feed-item feed-item-secondary">
                          <time className="date" dateTime="9-25">{(item.created_at)}</time>
                          <span className="text">Transaction type <a href="/dashboard">{item.description}</a></span>
                        </li>)



                    })}


                  </ol>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="card-title fw-mediumbold">Top 5 Line Items</div>
                  <div className="card-list">
                    {(top_5_line_items || []).map((item, i) => {

                      return (<div key={i} className="item-list">
                        {/*<div className="avatar">
                        <img src="https://via.placeholder.com/50x50" alt="..." className="avatar-img rounded-circle" />
                        </div>*/}
                        <div className="info-user ml-3">
                          <div className="username">{item.id}</div>
                          <div className="status"> No. of Transactions: {item.count}</div>
                        </div>

                      </div>)
                    })}

                  </div>
                </div>
              </div>
            </div>
            {/*<div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title fw-mediumbold">Top 5 Islands</div>
                    <div className="card-list">
                      {(top_5_islands || []).map((item)=>{
                        return(	<div className="item-list">
                        
                        <div className="info-user ml-3">
                          <div className="username"> {item.name}</div>
                          <div className="status"> count: {item.count}</div>
                        </div>
                        <button className="btn btn-icon btn-primary btn-round btn-xs">
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>)
                      }) }
                    
                    </div>
                  </div>
                </div>
              </div>*/}

            {/*
              <div className="col-md-4">
                <div className="card card-primary bg-primary-gradient">
                  <div className="card-body">
                    <h4 className="mt-3 b-b1 pb-2 mb-4 fw-bold">Active user right now</h4>
                    <h1 className="mb-4 fw-bold">17</h1>
                    <h4 className="mt-3 b-b1 pb-2 mb-5 fw-bold">Page view per minutes</h4>
                      <Sparklines data={[112,109,120,107,110,85,87,90,102,109,120,99,110,85,87,94]} width={100} height={25}  >
                          <SparklinesBars style={{ fill: "rgba(255,255,255,.3)" }} />

                      </Sparklines>
                    <h4 className="mt-5 pb-3 mb-0 fw-bold">Top active pages</h4>
                    <ul className="list-unstyled">
                      <li className="d-flex justify-content-between pb-1 pt-1"><small>/product/readypro/index.html</small> <span>7</span></li>
                      <li className="d-flex justify-content-between pb-1 pt-1"><small>/product/atlantis/demo.html</small> <span>10</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              */}
          </div>
          <div className="row">

            {/*
              <div className="col-md-6">
                <div className="card full-height">
                  <div className="card-header">
                    <div className="card-head-row">
                      <div className="card-title">Support Tickets</div>
                      <div className="card-tools">
                        <ul className="nav nav-pills nav-secondary nav-pills-no-bd nav-sm" id="pills-tab" role="tablist">
                          <li className="nav-item">
                            <a className="nav-link" id="pills-today" data-toggle="pill" href="#pills-today" role="tab" aria-selected="true">Today</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link active" id="pills-week" data-toggle="pill" href="#pills-week" role="tab" aria-selected="false">Week</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" id="pills-month" data-toggle="pill" href="#pills-month" role="tab" aria-selected="false">Month</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="avatar avatar-online">
                        <span className="avatar-title rounded-circle border border-white bg-info">J</span>
                      </div>
                      <div className="flex-1 ml-3 pt-1">
                        <h6 className="text-uppercase fw-bold mb-1">Joko Subianto <span className="text-warning pl-3">pending</span></h6>
                        <span className="text-muted">I am facing some trouble with my viewport. When i start my</span>
                      </div>
                      <div className="float-right pt-1">
                        <small className="text-muted">8:40 PM</small>
                      </div>
                    </div>
                    <div className="separator-dashed"></div>
                    <div className="d-flex">
                      <div className="avatar avatar-offline">
                        <span className="avatar-title rounded-circle border border-white bg-secondary">P</span>
                      </div>
                      <div className="flex-1 ml-3 pt-1">
                        <h6 className="text-uppercase fw-bold mb-1">Prabowo Widodo <span className="text-success pl-3">open</span></h6>
                        <span className="text-muted">I have some query regarding the license issue.</span>
                      </div>
                      <div className="float-right pt-1">
                        <small className="text-muted">1 Day Ago</small>
                      </div>
                    </div>
                    <div className="separator-dashed"></div>
                    <div className="d-flex">
                      <div className="avatar avatar-away">
                        <span className="avatar-title rounded-circle border border-white bg-danger">L</span>
                      </div>
                      <div className="flex-1 ml-3 pt-1">
                        <h6 className="text-uppercase fw-bold mb-1">Lee Chong Wei <span className="text-muted pl-3">closed</span></h6>
                        <span className="text-muted">Is there any update plan for RTL version near future?</span>
                      </div>
                      <div className="float-right pt-1">
                        <small className="text-muted">2 Days Ago</small>
                      </div>
                    </div>
                    <div className="separator-dashed"></div>
                    <div className="d-flex">
                      <div className="avatar avatar-offline">
                        <span className="avatar-title rounded-circle border border-white bg-secondary">P</span>
                      </div>
                      <div className="flex-1 ml-3 pt-1">
                        <h6 className="text-uppercase fw-bold mb-1">Peter Parker <span className="text-success pl-3">open</span></h6>
                        <span className="text-muted">I have some query regarding the license issue.</span>
                      </div>
                      <div className="float-right pt-1">
                        <small className="text-muted">2 Day Ago</small>
                      </div>
                    </div>
                    <div className="separator-dashed"></div>
                    <div className="d-flex">
                      <div className="avatar avatar-away">
                        <span className="avatar-title rounded-circle border border-white bg-danger">L</span>
                      </div>
                      <div className="flex-1 ml-3 pt-1">
                        <h6 className="text-uppercase fw-bold mb-1">Logan Paul <span className="text-muted pl-3">closed</span></h6>
                        <span className="text-muted">Is there any update plan for RTL version near future?</span>
                      </div>
                      <div className="float-right pt-1">
                        <small className="text-muted">2 Days Ago</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              */}
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    error: state.users.error,
    success_message: state.users.success_message,
    data: state.dashboard.data,
    transactions: state.dashboard.dTransactions
  }
}

export default withRouter(connect(mapStateToProps, { getDbData })(Dashboard))

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ValidatorForm } from 'react-form-validator-core'

import EmptyTable from '@shared/empty-table'
import Loader from '@shared/loader'
import DateTimeField from '@shared/form-fields/date'
import {
  getTransactions,
  voidTransaction,
  downloadTransactions,
} from '@actions/transactions'
import appConstants from '@constants/constants'
import TransactionTable from './_table'

class Transaction extends React.Component {
  constructor(props) {
    super(props)

    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    this.state = {
      isOpen: false,
      current_list: {},
      current_transaction: {},
      date_start: today,
      date_end: tomorrow,
      dates_clean: true,
      nonce: 0,
    }

    Object.assign(this, { today, tomorrow })
  }

  componentDidMount() {
    this.props.getTransactions(this.state.date_start, this.state.date_end)
  }

  componentWillReceiveProps({ success }) {
    success && this.setState({ isOpen: false })
  }

  on = {
    date: (name) => (d) => {
      this.setState({ [name]: this.formatDate(d), dates_clean: false })
    },
  }

  searchDateRange = () => {
    this.props.getTransactions(this.state.date_start, this.state.date_end)
    this.setState({ dates_clean: true })
  }

  formatDate = (date) =>
    typeof date == 'string'
      ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, '$1-$2-$3'))
      : date

  handleAction = (id, action) => {
    if (action == 'edit') {
      const current_list = this.state.lists.find((l) => l.id === id)
      this.setState({ isOpen: true, current_list: current_list })
    }
  }

  reqcloseModal = () => {
    this.setState({ isOpen: !this.state.isOpen, current_list: {} })
  }

  viewTransaction = () => {
    const { current_transaction } = this.state

    const rows = Object.keys(current_transaction).map((item) => (
      <tr>
        <td>{item.replace('_', ' ').spaceBeforeCap()}</td>
        <td>{current_transaction[item]}</td>
      </tr>
    ))

    return (
      <div
        className='modal fade'
        id='TransactionModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                View Transaction
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <table className='table'>{rows}</table>
            </div>
            <div className='modal-footer'></div>
          </div>
        </div>
      </div>
    )
  }

  showTransaction = (current_transaction) => {
    this.setState({ current_transaction })
  }

  render () {
    const { state, props, on, showTransaction, tomorrow } = this
    const { date_start, date_end } = state
    const { loading, data } = props

    const styles = {
      form: {
        display: 'inline-flex',
        alignItems: 'center',
      },
      label: {
        margin: 0,
      },
    }

    const filters = (
      <ValidatorForm className='inline-flex items-center' onSubmit={this.searchDateRange}>
        <label htmlFor='date_start' style={styles.label}>
          From&nbsp;&nbsp;
        </label>
        <DateTimeField
          name='date_start'
          className='form-control'
          onChange={on.date('date_start')}
          selected={date_start}
          value={date_start}
          validators={['required']}
          errorMessages={['Required']}
          dateFormat='do MMMM, yyyy'
          dropdownMode='select'
          maxDate={date_end}
        />
        <label htmlFor='date_end' style={styles.label}>
          &nbsp;&nbsp;&nbsp;&nbsp;To&nbsp;&nbsp;
        </label>
        <DateTimeField
          name='date_end'
          className='form-control'
          onChange={on.date('date_end')}
          selected={date_end}
          value={date_end}
          validators={['required']}
          errorMessages={['Required']}
          dateFormat='do MMMM, yyyy'
          dropdownMode='select'
          minDate={date_start}
          maxDate={tomorrow}
        />
        <button type='submit' className='refresh-btn' disabled={state.dates_clean}>
          <i className='fas fa-sync' />
        </button>
      </ValidatorForm>
    )

    const table =
      !loading && data && data.length ? (
        <TransactionTable
          {...state}
          data={data}
          showTransaction={showTransaction}
          voidTransaction={props.voidTransaction}
          downloadTransactions={props.downloadTransactions}
        />
      ) : (
        <EmptyTable columns={appConstants.TRANSACTIONS_COLUMNS} />
      )

    return (
      <React.Fragment>
        <Loader loading={this.props.loading} />

        <div className='content'>
          <div className='page-inner'>
            <div className='page-header'></div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4 className='card-title'>Transactions</h4>
                      {filters}
                      {/*<a className='btn btn-primary btn-round ml-auto' href='/Transaction/new'>
													<i className='fa fa-plus'></i>
													Add Row
												</a>*/}
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='table-responsive'>{table}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.viewTransaction()}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ transactions, cash }) => ({
  data: transactions.transactions,
  error: transactions.error,
  success: transactions.success,
  loading: transactions.loading,
  success_message: transactions.success_message || cash.success_message,
})

export default withRouter(
  connect(mapStateToProps, {
    getTransactions,
    downloadTransactions,
    voidTransaction,
  })(Transaction)
)

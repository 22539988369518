import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Template from './template'
import { BigRedCross } from '@components/base64-images'

const ErrorScreen = ({ error }) => (
  <Template image='payment-screen-card.jpg'>
    <div className='text-center'>
      <img src={BigRedCross} alt='Red Cross Sign' style={{ width: '3rem' }} />
      <br />
      <span className='title'>Error</span>
      <div className='response-error'>
        <span className='value'>{error}</span>
      </div>
      <div className='conditions-policy'>
        <Link to='/payments?redirected=true' style={{ fontSize: '1rem' }}>
          Make Payment
        </Link>
      </div>
    </div>
  </Template>
)

export default connect(state => ({
  error: state.payments.error
}), {})(ErrorScreen)

import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

// eslint-disable-next-line
String.prototype.spaceBeforeCap = function () {
  return String(this).toLowerCase().replace(/(^| )(\w)/g, x => x.toUpperCase())
}

// eslint-disable-next-line
String.prototype.initialCaps = function () {
  return String(this)
    .trim()
    .split(/[ _-]/)
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ')
}

// app and admin routes
ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
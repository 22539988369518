import React, { Fragment } from 'react'
import { ValidatorComponent } from 'react-form-validator-core'

class SelectField extends ValidatorComponent {
  errorStyles = {
    color: '#f25961',
    fontSize: '80%',
    fontWeight: 600,
  }

  errorText = () => this.state.isValid ? null : (
    <div style={this.errorStyles}>
      {this.getErrorMessage()}
    </div>
  )

  renderValidatorComponent () {
    const { errorMessages, validators, requiredError, validatorListener, options, required, ...rest } = this.props
    if (!this.state.isValid) rest.className += ' input_error'

    const list = options.map((opt, i) => (
      <option key={i} value={opt.value}>{opt.label}</option>
    ))

    return (
      <Fragment>
        <select {...rest} ref={(r) => { this.select = r; }}>
          <option value=''></option>
          {list}
        </select>
        {this.errorText()}
      </Fragment>
    )
  }
}

export default SelectField

import React from 'react'
import $ from 'jquery'
import Swal from 'sweetalert2'

import appConstants from '@constants/constants'
import { Link } from 'react-router-dom'

import 'react-confirm-alert/src/react-confirm-alert.css'
import '../../assets/css/pagination.css'

$.DataTable = require('datatables.net')

export default class UsersTable extends React.Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.search = null

    this.userRoles = JSON.parse(sessionStorage.getItem('user'))
      .roles.map(r => r.name)
  }

  componentDidMount() {
    this.datatable.init()
  }

  componentWillUnmount() {
    this.datatable.destroy()
  }

  datatable = {
    init: () => {
      try {
        this.__datatable = $(this.table.current).DataTable({
          order: [[3, 'desc']],
          language: {
            paginate: {
              next: '<span className="p-next">next</span>',
              previous: '<span className="p-previous">previous</span>'
            }
          }
        })

        setTimeout(() => {
          this.search = document.querySelector('input[aria-controls="basic-datatables"]')
        }, 500)
      } catch (err) {
        console.error(err)
      }
    },

    destroy: () => {
      this.__datatable && this.__datatable.destroy()
    },

    reset: () => {
      this.datatable.destroy()
      this.datatable.init()
    }
  }

  downloadData(url) {
    this.props.downloadTransactions(url)
  }
  confirmVoid = item => () => Swal.fire({
      title: "Confirm",
      text: "Are you sure you'd like to void this transaction?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Void",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        this.props.voidTransaction(item.id, () => {
          this.props.history.push('/transactions')
        });
      }
    });

  actionItems = item => (
    <div>
      <a onClick={() => this.props.showTransaction(item)} data-toggle="modal" data-target="#TransactionModal" className="" ><i className="fa fa-eye"></i></a>
      &nbsp;&nbsp;&nbsp;&nbsp;
      {
        !item.void && ['revenue', 'agency_accountant'].some(r => this.userRoles.includes(r)) && !this.userRoles.includes("read")
          ? <a onClick={this.confirmVoid(item)}><i className="fa fa-ban"></i></a>
          : null
      }
    </div>
  )


  addActionData = item => (
    <div>
      <Link to={`users/edit?id=${item.id}`} className="btn btn-link btn-primary btn-lg" >
        <i className="fa fa-edit"></i>
      </Link>
      {/* <button type="button" onClick={(e) => { this.removeData(e.target.dataset.id) }} data-toggle="tooltip" title="" className="btn btn-link btn-danger" data-original-title="Remove">
        <i className="fa fa-times" data-id={item.id}></i>
      </button> */}
    </div>
  )

  tableRows = data => data.map((item, index) => (
    item.users.map((t, i) =>
      <tr key={i}>
        <td>{t.first_name}</td>
        <td>{t.last_name}</td>
        <td>{t.email}</td>
        <td>{item.name_of_company ? item.name_of_company : ''}</td>
        <td>{this.addActionData(t)}</td>
      </tr>
    )
  ))

  render() {
    const headings = appConstants.USERS_COLUMNS.map((h, i) => (
      <th key={i}>{h.title}</th>
    ))

    return (
      <React.Fragment>

        <table id="basic-datatables" className="display table table-striped table-hover" ref={this.table}>
          <thead>
            <tr>{headings}</tr>
          </thead>
          <tfoot>
            <tr>{headings}</tr>
          </tfoot>
          <tbody>{this.tableRows(this.props.data)}</tbody>
        </table>
      </React.Fragment>
    )
  }
}

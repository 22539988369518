import React, { Fragment } from 'react'
import { ValidatorComponent } from 'react-form-validator-core'

class InputField extends ValidatorComponent {
  errorStyles = {
    color: '#f25961',
    fontSize: '80%',
    fontWeight: 600,
  }

  errorText = () => this.state.isValid ? null : (
    <div style={this.errorStyles}>
      {this.getErrorMessage()}
    </div>
  )

  render () {
    const { errorMessages, validators, requiredError, validatorListener, withRequiredValidator, ...rest } = this.props
    rest['className'] = this.state.isValid ? rest['className'] : rest['className'] + ' input_error'

    return (
      <Fragment>
        <input {...rest} ref={(r) => { this.input = r; }}/>
        {this.errorText()}
      </Fragment>
    );
  }
}

export default InputField



import axios from 'axios'

export const transformObjectToQuery = (obj = {}) => Object.entries(obj)
  .map(kv => kv.map(encodeURIComponent).join('='))
  .join('&')

export const guestHeaders = async () => {
  try {
    const { data } = await axios.get(process.env.NEXT_PUBLIC_API_HOST + '/api/v1/fetch_guest_token')

    return {
      "Content-Type": "application/json",
      "Authorization": data.token
    }
  } catch (err) {
    console.error(err)
    return null
  }
}

export const extractKeys = (obj, ...keys) => {
  if (!obj || !keys?.length) return null

  const fn = keys.join('').includes(':')
    ? (o, k) => {
      const [key, alias] = k.split(':')
      const val = obj[key]

      return val === undefined ? o : {
        ...o,
        [alias || key]: val
      }
    }
    : (o, k) => {
      const val = obj[k]

      return val === undefined ? o : {
        ...o,
        [k]: val
      }
    }

  return keys.reduce(fn, {})
}
export const withoutKeys = (obj, ...keys) => {
  if (!obj || !keys || !keys.length) return null

  const fn = (o, k) => {
    delete o[k]
    return o
  }

  return keys.reduce(fn, { ...obj })
}

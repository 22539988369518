import React from 'react'

import Input from './input'
import Select from './select'
import DateField from './date'

const fields = {
  text   : Input,
  select : Select,
  date   : DateField,
}

const pretty = str => str
  .split('_')
  .map(w => w.charAt(0).toUpperCase() + w.slice(1))
  .join(' ')
  .replace(' Id', ' ID')

export default function Field ({ name, type, label, required, children, ...rest }) {
  let input = fields[type]
  if (rest.options) input = fields.select

  const props = input && {
    className: 'form-control',
  }

  if (props) {
    if (type != 'select') Object.assign(props, { name, type })

    if (required !== false) {
      Object.assign(props, {
        validators: ['required'],
        errorMessages: ['This field is required']
      })
    }

    if (type == 'text') props.placeholder = label || pretty(name)

    // Automatically set common input modes
    if (name == 'email') props.inputMode = 'email'
    if (name == 'mobile_number') props.inputMode = 'tel'
  
    // Lastly assign rest to override default niceties
    Object.assign(props, rest)
  }

  return (
    <div className='form-group form-show-validation row'>
      <label htmlFor={name} className='col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right'>
        {label || pretty(name)}
        {required !== false ? <span className="required-label">*</span> : null}
      </label>
      <div className='col-lg-4 col-md-9 col-sm-8'>
        {input ? React.createElement(input, props) : null}
        {children || null}
      </div>
    </div>
  )
}
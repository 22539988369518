export default {
  SUB_DOMAIN: "cash",
  API_V: "api/v1",

  TRANSACTIONS_COLUMNS: [
    { title: "Finance Invoice Number" },
    { title: "Invoice Number" },
    { title: "CC Status" },
    // { title: "Invoice Reference" },
    { title: "Amount Charged" },
    { title: "Line Item Description" },
    { title: "User Invoice" },
    { title: "First Name" },
    { title: "Last Name" },
    { title: "Cashier" },
    { title: "Location" },
    { title: "Created At" },
    { title: "Transaction Mode" },
    { title: "Third Party Type" },
    { title: "Third Party Reference" },
    { title: "Void" },
    { title: "SMS" },
    { title: "Notes" },
    { title: "Download" },
    { title: "Actions" },
  ],

  USERS_COLUMNS: [
    { title: "First Name" },
    { title: "Last Name" },
    { title: "Email Address" },
    { title: "Company" },
    { title: "Actions" },
  ],

  INVOICES_COLUMNS: [
    { title: "Finance Invoice Number" },
    { title: "Invoice Number" },
    { title: "Location" },
    { title: "Created At" },
    { title: "Line Item" },
    { title: "Email" },
    { title: "First Name" },
    { title: "Last Name" },
    { title: "Amount" },
    { title: "Paid" },
    { title: "Void" },
    { title: "SMS" },
    { title: "Notes" },
    { title: "Download" },
  ],

  INVOICES_HEADERS: [
    { label: "cashier", key: "cashier" },
    { label: "Finance Invoice Number", key: "invoice_reference" },
    // { label: "Invoice Reference", key: "invoice_reference" },
    { label: "Invoice Number", key: "reference_code" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Location", key: "location" },
    { label: "Created At", key: "created_at" },
    { label: "Line Item", key: "long_desc" },
    { label: "Email", key: "email" },
    { label: "Amount", key: "amount" },
    { label: "Status", key: "status" },
    { label: "Voided?", key: "void" },
    { label: "SMS", key: "mobile_number" },
    // { label: "Download", Key: "invoice" },
  ],

  PAYEE_COLUMNS: [
    { title: "Id" },
    { title: "Invoice Number" },
    { title: "First Name" },
    { title: "Last Name" },
    { title: "Email" },
    { title: "Value" },
    { title: "Status" },
    { title: "Type" },
    { title: "Created at" },
  ],

  REPORT_COLUMNS: [
    { title: "Type" },
    { title: "Date" },
    { title: "Download File" },
  ],

  TRANSACTIONS_HEADERS: [
    { label: "Finance Invoice Number", key: "invoice_reference" },
    { label: "Transaction Mode", key: "transaction_mode" },
    { label: "CC Status", key: "m_status" },
    { label: "Invoice Number", key: "invoice_refernce" },
    { label: "First Name", key: "transaction_first_name" },
    { label: "Last Name", key: "transaction_last_name" },
    { label: "Amount Charged", key: "amount_charged" },
    { label: "Third Party Type", key: "third_party_type" },
    { label: "Third Party Reference", key: "third_party_reference" },
    { label: "Line Item Description", key: "line_item_description" },
    { label: "User Invoice", key: "transaction_email" },
    { label: "Cashier", key: "cashier" },
    { label: "Location", key: "invoice_location" },
    { label: "Created At", key: "created_at" },
    { label: "Tender Mode", key: "tender_type" },
    { label: "SMS", key: "mobile_number" },
    { label: "Voided?", key: "void" },
  ],

  HEADS_COLUMNS: [{ title: "Id" }, { title: "Name" }],

  LINE_ITEMS_COLUMNS: [
    { title: "Id" },
    { title: "Name" },
    { title: "Head Name" },
  ],

  COMPANIES_COLUMNS: [
    { title: "Id" },
    { title: "Name Of Company" },
    { title: "Island" },
    { title: "Marchent Token" },
    { title: "Actions" },
  ],

  REVENUE_LINE_ITEMS_COLUMNS: [
    { title: "Account ID" },
    { title: "Description" },
    { title: "Batch Name" },
  ],
};


import React from 'react'
import { connect } from 'react-redux'

import { resetPassword } from '@actions/users'
import extractKeys from '@helpers/extract-keys'
import LandingPage from '@wrappers/landing-page'

export const ROUTE_OPEN = true
export const ROUTE_PATHS = ['/reset-password/:token']

class ResetPassword extends React.Component {
  state = {
    open: false,
    form: {
      password: '',
      password_confirmation: '',
    },
  }

  componentWillReceiveProps ({ currentUser }) {
    currentUser && this.props.history.push('/dashboard')
  }

  on = {
    change: ({ target }) => this.setState(state => {
      state.form[target.name] = target.value
      return state
    }),

    submit: ev => {
      ev.preventDefault()

      const { state, props } = this
      const { password, password_confirmation } = state.form

      if (password && password_confirmation) {
        props.resetPassword({
          ...this.state.form,
          reset_password_token: props.match.params.token
        })
      } else this.setState({ error: "Password did not match" })
    }
  }

  togglePasswordVisibility = id => () => {
    const input = document.getElementById(id)

    if (input) {
      const attr = input.getAttribute('type') == 'password' ? 'text' : 'password'
      input.setAttribute('type', attr)
    }
  }

  render () {
    const { state, props, on, togglePasswordVisibility } = this

    return (
      <LandingPage
        title='Open for Business 24x7'
        subtitle="The subway system of The Bahamas' financial services."
        success_message={props.success_message}
        error={state.error || props.error}
      >
        <form onSubmit={on.submit}>
          <div className="form-group">
            <label htmlFor="password" className="placeholder"><b>Password</b></label>
            <div className="position-relative">
              <input
                id="password"
                name="password"
                type="password"
                onChange={on.change}
                value={state.form.password}
                className="form-control"
                required
              />
              <div className="show-password">
                <i className="fas fa-eye" onClick={togglePasswordVisibility('password')}></i>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="password" className="placeholder"><b>Confirm Password</b></label>
            <div className="position-relative">
              <input
                id="password_confirmation"
                name="password_confirmation"
                type="password"
                onChange={on.change}
                value={state.form.password_confirmation}
                className="form-control"
                required
              />
              <div className="show-password">
                <i className="fas fa-eye" onClick={togglePasswordVisibility('password_confirmation')}></i>
              </div>
            </div>
          </div>
          <div className="form-group form-action-d-flex mb-3">
            <input type="submit" className="btn btn-secondary col-md-5 float-right mt-3 mt-sm-0 fw-bold" value="Update" />
          </div>
        </form>
      </LandingPage>
    )
  }
}

export default connect(
  state => extractKeys(state.users, 'error', 'success_message'),
  { resetPassword }
)(ResetPassword)
